<template>
  <v-card class="empty-course-card">
    <v-row>
      <v-col cols="12" class="content">
        <p class="message">Oops! Looks like there are no courses available.</p>
        <v-btn @click="requestNewCourse" class="request-btn">
          Add a new course
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    requestNewCourse() {},
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/_sizes.scss";

.empty-course-card {
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid $light_gray;
  text-align: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .message {
    font-size: $size-m;
    font-weight: 400;
  }
  .request-btn {
    color: $track_lane;
    background-color: $racecar;
  }
}
</style>
