import ApiService from "./ApiService";
import { LineManagerModel } from "shared-components/src/services/openApi/api";

export default class LineManagerService {
  public static async getList(): Promise<LineManagerModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get("/client/lineManagers", "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              const retVal = { ...item } as LineManagerModel;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
