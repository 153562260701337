import ApiService from "./ApiService";
import { OfficeLocationModel } from "shared-components/src/services/openApi/api";

export default class CustomerService {
  public static AddOfficeLocation(
    request: OfficeLocationModel
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/client/customer/officeLocation`, request)
        .then((result) => {
          if (!result.data) {
            resolve("");
          } else {
            resolve(result.data);
          }
        })
        .catch((customerError) => reject(customerError));
    });
  }

  public static GetOfficeLocations(): Promise<OfficeLocationModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/client/customer/officeLocation`)
        .then((result) => {
          if (!result.data) {
            resolve([] as OfficeLocationModel[]);
          } else {
            resolve(result.data as OfficeLocationModel[]);
          }
        })
        .catch((customerError) => reject(customerError));
    });
  }
}
