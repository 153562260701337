<template>
  <v-dialog v-model="showPositionMenuDialog" persistent max-width="1000px" :loading="dialogLoading" :disabled="dialogLoading">
    <v-card class="pa-3">
      <v-card-header>
        <v-row>
          <v-col cols="12" class="text-center">
            {{getJobRoleName}}
          </v-col>
          <v-icon class="close-btn" @click="closeDialog">mdi-close</v-icon>
        </v-row>
      </v-card-header>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card variant="tonal">
              <v-row class="pa-2">
                <v-col cols="11">
                  <v-row>
                    <v-col cols="12">
                      Job Title: {{getJobTitleNames}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      Skills: {{getSkillNames}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      Number of Seats: {{getNumberOfSeats}}
                    </v-col>
                    <v-col cols="4">
                      Start Date: {{ getStartDate }}
                    </v-col>
                    <v-col cols="4">
                      Seats Unfilled: {{selectedPosition.NoOfPositions - selectedPosition.Commitments?.length}}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="d-flex justify-center align-center">
                  <v-icon @click="showUpdatePosition = true">mdi-pen</v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card variant="tonal">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      Actions
                    </v-col>
                  </v-row>
                  <div class="menu-action-btns">
                    <v-btn class="menu-btn" prepend-icon="mdi-content-copy">Clone</v-btn>
                    <v-btn class="menu-btn" prepend-icon="mdi-archive">Archive</v-btn>
                    <v-btn class="menu-btn" prepend-icon="mdi-magnify" @click="showSearchTeammember = true">Select Teammember</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card variant="tonal">
              <v-row class="pa-2">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="text-center">
                      Status
                    </v-col>
                  </v-row>
                  <div class="menu-action-btns">
                    <v-btn class="menu-btn">174 Impressions</v-btn>
                    <v-btn class="menu-btn">57 Assessments</v-btn>
                    <v-btn class="menu-btn">23 Interviews</v-btn>
                    <v-btn class="menu-btn">5 Shortlisted</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="showUpdatePosition"
    persistent
    max-width="600px"
    @keydown.esc="showUpdatePosition = false"
  >
    <AddOrEditPositionDetails
      :isNew="false"
      :addLoading="addLoading"
      @cancel="showUpdatePosition = false"
      @success="UpdateProjectPosition($event)"
      :details="details"
      :positionId="positionId"
      :projectModel="selectedProject"
      :skills="skills"
      v-if="showUpdatePosition"
    />
  </v-dialog>
  <ProjectSearchWizard
    @AssignTmToPosition="AssignTmToPosition"
    @UpdatePositionDetail="UpdatePositionDetail"
    :skills="skills"
    :details="details"
    @OnClose="showSearchTeammember = false"
    :project="selectedProject"
    :positionId="positionId"
    :projectLeads="projectLeads"
    :portfolioManagers="portfolioManagers"
    :publicHolidayZones="publicHolidayZones"
    :projects="projects"
    :officeLocations="officeLocations"
    @saveLocation="saveOfficeLocation"
    :dialogLoading="dialogLoading"
    :lineManagers="lineManagers"
    :officeSpaces="officeSpaces"
    v-if="showSearchTeammember"
  />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {PositionModel, ProjectModel} from "shared-components/src/services/openApi/api"
import moment from "moment";
import PositionService from "@/services/PositionService";
import AddOrEditPositionDetails from "../ProjectPosition/AddOrEditPositionDetails.vue"
import ProjectSearchWizard from "../AddTeammember/ProjectSearchWizard.vue"

import Commitment, { CommitmentMin } from "shared-components/src/models/Commitment";

export default defineComponent({
  components: {
    AddOrEditPositionDetails,
    ProjectSearchWizard
  },
  props: [
    "skills",
    "details",
    "projectLeads",
    "publicHolidayZones",
    "projects",
    "officeLocations",
    "projectId",
    "positionId",
    "lineManagers",
    "officeSpaces",
    "portfolioManagers",
  ],
  data() {
    return {
      dialogLoading: false,
      showSearchTeammember: false,
      addLoading: false,
      showUpdatePosition: false,
      showPositionMenuDialog: true,
      selectedPosition: null as PositionModel | null,
      selectedProject: null as ProjectModel | null
    };
  },
  created() {
    if(this.projectId && this.positionId){
      this.selectedProject = this.projects.find((x: any) => x.id == this.projectId);
      this.selectedPosition = this.selectedProject?.Positions?.find((x: any) => x.Id == this.positionId) ?? null;
    }
  },
  methods: {
    async saveOfficeLocation(event: any) {
      this.officeLocations.push(event);
    },
    async AssignTmToPosition(commitment: Commitment) {
      if(this.selectedProject && this.selectedProject.Positions){
        this.dialogLoading = true;

        let positionIndex = this.selectedProject?.Positions?.findIndex(
          (item: any) => item.Id == this.positionId
        );
        if (!this.selectedProject.Positions[positionIndex].Commitments) {
          this.selectedProject.Positions[positionIndex].Commitments = [];
        }
        const commitmentModel = {
          HoursPerWeekMax: commitment.HoursPerWeekMax,
          id: commitment.id,
          Name: commitment.Name,
        } as CommitmentMin;
        this.selectedProject.Positions[positionIndex].Commitments?.push(
          commitmentModel
        );
        this.addLoading = false;
        this.showSearchTeammember = false;
        this.dialogLoading = false;
      }
    },
    async UpdatePositionDetail(jobDescription: any) {
      if (this.selectedProject && this.selectedProject.Positions) {
        this.dialogLoading = true;

        let positionIndex = this.selectedProject.Positions.findIndex(
          (item) => item.Id == this.positionId
        );
        this.selectedProject.Positions[positionIndex].JobDescription = jobDescription;
        this.addLoading = false;
        this.showSearchTeammember = false;
        this.dialogLoading = false;
      }
    },
    async UpdateProjectPosition(event: any) {
      this.addLoading = true;
      if (this.selectedProject && this.selectedProject.Positions) {
        event.ProjectId = this.selectedProject.id;
        event.Id = this.positionId;
        const result = await PositionService.SavePosition(event);
        let positionIndex = this.selectedProject?.Positions?.findIndex(
          (item) => item.Id == this.positionId
        );
        result.Commitments =this.selectedProject.Positions[positionIndex].Commitments;
        this.selectedProject.Positions[positionIndex] = result;
        this.selectedPosition = this.selectedProject?.Positions?.find((x: any) => x.Id == this.positionId) ?? null;
        this.addLoading = false;
        this.showUpdatePosition = false;

      }
    },
    closeDialog() {
      this.$emit("OnClose");
    },
  },
  computed: {
    getJobRoleName(){
      return this.details.find((item: any) => item.id == this.selectedPosition?.JobRoleId)?.Name ?? ""
    },
    getJobTitleNames(){
      const names = this.details.filter((item: any) => this.selectedPosition?.JobTitles?.includes(item.id)).map((item: any) => item.Name)
      return names.length > 0 ? names.join(", ") : "-"
    },
    getSkillNames(){
      const names = this.skills.filter((item: any) => this.selectedPosition?.Skills?.includes(item.id)).map((item: any) => item.Text)
      return names.length > 0 ? names.join(", ") : "-"
    },
    getStartDate(){
      return this.selectedPosition?.StartDate
          ? moment(this.selectedPosition?.StartDate).format("YYYY-MM-DD")
          : "ASAP";
    },
    getNumberOfSeats(){
      return this.selectedPosition?.NoOfPositions;
    },
    getSeatsUnfilled(){
      if(this.selectedPosition?.NoOfPositions && this.selectedPosition?.Commitments){
        return this.selectedPosition?.NoOfPositions - this.selectedPosition?.Commitments?.length;
      }
      return "-";
    }
  }
});
</script>
<style lang="scss" scoped>
.menu-action-btns{
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .menu-btn{
    font-size: 12px;
    flex: 45%;
  }
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999;
  color: white;
}
</style>
