<template>
  <div 
  class="epic-section" 
  v-if="project.epics && project.epics.length > 0">
    <div :class="{'min-height': (!showMore && project.epics && project.epics.length > 0) || (!project.epics || project.epics.length == 0)}">
      <v-row class="justify-center ma-0" >
        <v-col
          cols="10"
          xs="12"
          sm="5"
          md="5"
          v-for="epic in project.epics"
          :key="epic.name"
          class="rounded-lg listItem ma-3"
          :class="epic.netEffort === 0 ? 'notWorking' : ''"
        >
          <v-row>
            <v-col md="8" sm="7" xs="5">
              {{ epic.name }}
            </v-col>
            <v-col class="text-end" md="4" sm="5" xs="2">
              {{ epic.netEffort }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-if="project.epics && project.epics.length > 4" class="text-center mt-1">
      <v-btn variant="text" @click="showMore = !showMore"> {{!showMore ? 'Show More' : "Hide"}}</v-btn>
    </div>
  </div>
  <div v-else class="no-epic">
    <h3>There are no Epic in this Project!</h3>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["project"],
  async mounted() {
    this.project.epics.sort((a: any,b: any) => b.netEffort - a.netEffort);
  },
  components: {},
  data() {
    return {
      showMore: false
    }
  },
  methods: {
    
  },
});
</script>
<style scoped lang="scss">
.min-height{
  height: 110px;
  overflow: hidden;
}

.epic-section {
  margin-top: 5px;
  justify-content: center;
  padding: 5px;
  overflow: hidden;
  min-height: 160px;
  .listItem{
    height: fit-content;
    margin: 5px !important;
  }
}

.no-epic {
  align-items: center;
  display: flex;
  height: 165px;
  justify-content: center;
}
</style>
