<template>
  <v-card class="container" :isLoading="isLoading">
    <v-card-text>
      <div class="top-section">
        <v-icon @click="closeModal"> mdi-arrow-left</v-icon>
        <h3>{{ roadMapModel.Title }}</h3>
      </div>
      <div class="mt-5">
        {{ roadMapModel.Description }}
      </div>
      <div class="mt-5">
        <v-row>
          <v-col cols="8" class="h-100">
            <v-list density="compact">
              <v-list-subheader>Steps</v-list-subheader>

              <v-list-item
                v-for="(item, index) in roadMapModel.Steps"
                :key="index"
                :value="item"
                color="primary"
                @click="goToLink(item)"
              >
                <template v-slot:prepend>
                  <v-icon v-if="item.Type == 'Course'">
                    mdi-human-male-board</v-icon
                  >
                  <v-icon v-else> mdi-ab-testing</v-icon>
                </template>

                <v-list-item-title>
                  <v-row>
                    <v-col cols="5">
                      {{ index + 1 }}- {{ truncate(item.Title) }}
                    </v-col>
                    <v-col cols="2">
                      {{ getStepLevel(item) }}
                    </v-col>
                    <v-col cols="2">
                      {{ getStepDuration(item) }}
                    </v-col>
                    <v-col cols="3">
                      {{ getStepCounts(item) }}
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="4">
            <v-data-table
              :items="roadMapModel.Talents"
              :headers="tableHeaders"
              item-value="name"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RoadMapService from "@/services/RoadMapService";
import RoadMapModel, {
  RoadMapStepModel,
} from "shared-components/src/models/RoadMapModel";

export default defineComponent({
  props: {
    roadMapId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [{ title: "Talents", key: "FullName" }],
      roadMapModel: {} as RoadMapModel,
      isLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.roadMapModel = await RoadMapService.GetById(this.roadMapId);
    if (!this.roadMapModel.Talents) {
      this.roadMapModel.Talents = [];
    }
    if (!this.roadMapModel.Steps) {
      this.roadMapModel.Steps = [];
    }
    this.isLoading = false;
  },
  methods: {
    closeModal() {
      this.$emit("CloseModal");
    },
    goToLink(item: any) {
      let route;
      if (item.Type == "Course") {
        route = this.$router.resolve({
          name: "course-detail",
          params: { courseId: item.Id },
        });
      } else {
        route = this.$router.resolve({
          name: "assessment-detail",
          params: { assessmentId: item.Id },
        });
      }
      window.open(route.href, "_blank");
    },
    truncate(item: any) {
      if (item && item.length > 60) {
        return item.substring(0, 60) + "...";
      } else {
        return item;
      }
    },
    getStepDuration(item: RoadMapStepModel) {
      if (item.AssessmentDetail) {
        let durations = item.AssessmentDetail.Questions.reduce(
          (total: any, question: any) => total + question.Duration,
          0
        );
        return this.formatDuration(durations);
      } else if (item.CourseDetail) {
        return this.formatDuration(item.CourseDetail.Duration);
      } else {
        return "-";
      }
    },
    getStepLevel(item: RoadMapStepModel) {
      if (item.AssessmentDetail) {
        let levels = item.AssessmentDetail.Questions.map(
          (item: any) => item.Level
        );
        return [...new Set(levels)].join(", ");
      } else if (item.CourseDetail) {
        return item.CourseDetail.Level;
      } else {
        return "-";
      }
    },
    getStepCounts(item: RoadMapStepModel) {
      if (item.AssessmentDetail) {
        return `${item.AssessmentDetail.Questions.length} Question`;
      } else if (item.CourseDetail) {
        var lessons = item.CourseDetail.Steps.filter(
          (item: any) => item.Type == "Lesson"
        ).length;
        var assessments = item.CourseDetail.Steps.filter(
          (item: any) => item.Type == "Assessment"
        ).length;
        return `${lessons} Lessons - ${assessments} Assessments`;
      } else {
        return "-";
      }
    },
    formatDuration(duration: number) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return hours > 0 ? `${hours} h, ${minutes} m` : `${minutes} m`;
    },
  },
});
</script>

<style scoped>
.top-section {
  display: flex;
  align-items: center;
  gap: 20px;
}
</style>
