import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded-lg dataBody pa-3" }
const _hoisted_2 = { class: "title-board" }
const _hoisted_3 = { class: "descriptions" }
const _hoisted_4 = { class: "activities" }
const _hoisted_5 = { style: {"margin-bottom":"1px"} }
const _hoisted_6 = ["title", "onClick"]
const _hoisted_7 = ["title", "onClick"]
const _hoisted_8 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_reaction = _resolveComponent("reaction")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_text = _resolveComponent("v-card-text")!

  return (_openBlock(), _createBlock(_component_v_card_text, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              cols: "12",
              md: "6",
              sm: "12",
              xs: "12",
              key: item.IssueNo
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          md: "3",
                          class: "text-left"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.totalLines) + " Line(s) ", 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          md: "6",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Task " + _toDisplayString(item.IssueNo), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          md: "3",
                          class: "text-right"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.totalHours) + " Hour(s) ", 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.descriptions, (description, desIndex) => {
                          return (_openBlock(), _createBlock(_component_v_col, {
                            md: "12",
                            class: _normalizeClass(`${
                  desIndex === item.descriptions.length ? `` : `item`
                }`),
                            key: `d_${desIndex}`
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" - " + _toDisplayString(description), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.activityItems, (activity, actIndex) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  title: `${activity.name} ${activity.totalHours}H`,
                                  key: `a_${actIndex}`,
                                  style: _normalizeStyle(`${_ctx.prepareChartStyle(
                      actIndex,
                      item.totalHours,
                      activity.totalHours
                    )}`),
                                  onClick: ($event: any) => (_ctx.setActivity(activity.id))
                                }, _toDisplayString(activity.name) + " (" + _toDisplayString(activity.totalHours) + "H) ", 13, _hoisted_6))
                              }), 128))
                            ]),
                            _createElementVNode("div", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.technologyItems, (technology, tchIndex) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  title: `${technology.name} ${technology.totalHours}H`,
                                  key: `t_${tchIndex}`,
                                  style: _normalizeStyle(`${_ctx.prepareChartStyle(
                      tchIndex,
                      item.totalHours,
                      technology.totalHours,
                      true
                    )}`),
                                  onClick: ($event: any) => (_ctx.setTechnology(technology.id))
                                }, _toDisplayString(technology.name) + " (" + _toDisplayString(technology.totalHours) + "H) ", 13, _hoisted_7))
                              }), 128))
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          md: "4",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              variant: "outlined",
                              disabled: true
                            }, {
                              default: _withCtx(() => _cache[1] || (_cache[1] = [
                                _createTextVNode(" Code Quality")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          md: "4",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              variant: "outlined",
                              onClick: ($event: any) => (_ctx.OnReactClick(item))
                            }, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode(" + Comment")
                              ])),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          md: "4",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              variant: "outlined",
                              disabled: true,
                              outlined: ""
                            }, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode(" SME Review")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      (_ctx.showReactionDialog)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 0,
            persistent: "",
            modelValue: _ctx.showReactionDialog,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showReactionDialog) = $event)),
            onKeydown: _withKeys(_ctx.closeReactionDialog, ["esc"]),
            "max-width": "440px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_reaction, {
                onCancel: _ctx.closeReactionDialog,
                onSaved: _ctx.closeReactionDialog,
                onErrorRaised: _ctx.errorRaised,
                onSuccessRaised: _ctx.successRaised,
                timesheetId: _ctx.selectedTimesheetId
              }, null, 8, ["onCancel", "onSaved", "onErrorRaised", "onSuccessRaised", "timesheetId"])
            ]),
            _: 1
          }, 8, ["modelValue", "onKeydown"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}