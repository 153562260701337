<template>
  <v-card class="roadmap-form" :disabled="isLoading" :loading="isLoading">
    <v-card-text class="form-inputs">
      <v-form ref="frmMain" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <TextField
              class="text_field"
              label="Title"
              placeholder="Enter Title"
              v-model="roadMapModel.Title"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12">
            <TextAreaField
              class="pt-0"
              v-model="roadMapModel.Description"
              label="Description"
              rows="3"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <RoadMapSelectSteps
              @AddStepToRoadMap="addStepToRoadMap"
              @DeleteStepFromRoadMap="deleteStepsFromRoadMap"
              :steps="roadMapModel.Steps"
            ></RoadMapSelectSteps>
          </v-col>
        </v-row>
        <div class="mt-5">
          <RoadMapSelectTalents
            @AddTalentsToRoadMap="addTalentsToRoadMap"
            @DeleteTalentFromRoadMap="deleteTalentFromRoadMap"
            DeleteTalentFromRoadMap
            :talents="roadMapModel.Talents"
            :teamMembers="teamMembersList"
          ></RoadMapSelectTalents>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="action-btns">
          <v-btn @click="cancel" class="secondary_btn">Cancel</v-btn>
          <v-btn @click="saveRoadMap" class="primary_btn_v2">Save</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RoadMapSelectSteps from "./RoadMapSelectSteps.vue";
import RoadMapSelectTalents from "./RoadMapSelectTalents.vue";
import RoadMapModel from "shared-components/src/models/RoadMapModel";
import { TeammemberWithSummaryDetails } from "shared-components/src/models/Teammember";
import TeammemberService from "@/services/TeammemberService";
import rules from "shared-components/src/utils/validations";
import { REMOVE_SNAKBAR, SET_SNAKBAR_MODEL } from "../../../store/types";
import { mapMutations } from "vuex";
import RoadMapService from "@/services/RoadMapService";
import { Profile } from "shared-components/src/definitions/config";

export default defineComponent({
  props: {
    roadMapId: {
      type: String,
      required: false,
    },
  },
  components: {
    RoadMapSelectSteps,
    RoadMapSelectTalents,
  },
  data() {
    return {
      rules,
      isLoading: false,
      valid: false,
      roadMapModel: {} as RoadMapModel,
      teamMembersList: [] as TeammemberWithSummaryDetails[],
    };
  },
  async mounted() {
    this.isLoading = true;
    if (this.roadMapId) {
      this.roadMapModel = await RoadMapService.GetById(this.roadMapId);
    }
    if (!this.roadMapModel.Talents) {
      this.roadMapModel.Talents = [];
    }
    if (!this.roadMapModel.Steps) {
      this.roadMapModel.Steps = [];
    }
    await this.loadTeammembers();
    this.isLoading = false;
  },
  methods: {
    ...mapMutations([SET_SNAKBAR_MODEL, REMOVE_SNAKBAR]),
    async loadTeammembers() {
      this.teamMembersList =
        await TeammemberService.getListWithSummaryDetails();
    },
    addStepToRoadMap(stepDetails: any) {
      if (!this.roadMapModel.Steps) {
        this.roadMapModel.Steps = [];
      }
      this.roadMapModel.Steps.push({
        Id: stepDetails.Id,
        Order: this.roadMapModel.Steps.length + 1,
        Title: stepDetails.Title,
        Type: stepDetails.Type,
      });
    },
    deleteStepsFromRoadMap(Id: string) {
      const stepIndex = this.roadMapModel.Steps.findIndex(
        (item: any) => item.Id == Id
      );
      if (stepIndex != -1) {
        this.roadMapModel.Steps.splice(stepIndex, 1);
      }
    },
    addTalentsToRoadMap(talentsDetails: any) {
      this.roadMapModel.Talents =
        this.roadMapModel.Talents.concat(talentsDetails);
    },
    deleteTalentFromRoadMap(Id: string) {
      const talentIndex = this.roadMapModel.Talents.findIndex(
        (item: any) => item.Id == Id
      );
      if (talentIndex != -1) {
        this.roadMapModel.Talents.splice(talentIndex, 1);
      }
    },
    async saveRoadMap() {
      let isValid = await (this.$refs.frmMain as any).validate();
      if (isValid.valid) {
        try {
          this.isLoading = true;

          await RoadMapService.CreateOrEdit(this.roadMapModel);
          this.SET_SNAKBAR_MODEL({
            body: this.roadMapId
              ? "Roadmap Updated Successfuly"
              : "Roadmap Created Successfuly",
            status: "success",
            button: "ok",
            timeout: Profile.ErrorSetting.SnackbarTimeout,
            show: true,
            handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
          });
          this.$router.push({
            name: "roadmap",
          });
        } catch (error) {
          this.SET_SNAKBAR_MODEL({
            body: this.roadMapId
              ? "There is an Issue on updating the Roadmap"
              : "There is an Issue on creating the Roadmap",
            status: "error",
            button: "ok",
            timeout: Profile.ErrorSetting.SnackbarTimeout,
            show: true,
            handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    cancel() {
      this.$router.push({
        name: "roadmap",
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
.roadmap-form {
  border-radius: 8px;
}
</style>
