<template>
  <div>
    <v-form ref="mainForm">
      <v-card flat :loading="loading" :disabled="loading">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <TextAreaField :disabled="loading" :rules="[rules.required]" label="Job Description"
                v-model="jobDescription"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <div class="actionBtns mt-3">
      <v-btn class="primary_btn_v2" @click="continueProcess" :loading="loading">Continue</v-btn>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import rules from "shared-components/src/utils/validations";
import { PositionJDAndDetailsRequestModel } from "shared-components/src/services/openApi/api";
import TeammemberService from "@/services/TeammemberService";
export default defineComponent({
  props: ["positionJD"],
  data() {
    return {
      loading: false,
      rules,
      jobDescription: null as String | null
    };
  },
  mounted() {
    if (this.positionJD)
    {
      this.jobDescription = this.positionJD
    }
      
  },
  methods: {
    async continueProcess(){
      const isValid = await (this.$refs.mainForm as any).validate();
      if (isValid.valid) {
        this.$emit("ContinueJD", this.jobDescription);
      }
    }
  },
});
</script>
<style lang="scss" scoped>
.actionBtns {
  display: flex;
  justify-content: right;
}
</style>
