<template>
  <div>
    <v-card :loading="loading || tableLoading" :disabled="loading || tableLoading">
      <v-card-title>
        <DateRange :offset="dayOffset" @fetchData="fetchData" />
      </v-card-title>
    </v-card>
    <v-card
      class="mt-3 transparent position-static"
      :loading="loading || tableLoading"
      :disabled="loading || tableLoading"
    >
      <v-card-text v-if="dashboardProjects && dashboardProjects.length > 0">
        <v-row class="flex-child projItem" v-if="!tableLoading">
          <v-col
            v-for="project in dashboardProjects"
            :key="project.id"
            cols="12"
            md="6"
            sm="8"
            xs="12"
          >
            <v-card class="position-static">
              <v-card-title>
                <v-row class="pl-3 pr-3">
                  <v-col md="10"
                    ><h3 class="ml-3">{{ project.Name }}</h3></v-col
                  >
                  <v-col md="2"
                    ><h3 class="text-right mr-3">
                      {{ project.totalEffort }}
                    </h3></v-col
                  >
                </v-row>
              </v-card-title>
              <v-divider class="mx-4"></v-divider>
              <v-card-text class="pl-0 pr-0">
                <ProfileAvatar
                  :users="project.teamMembers"
                  :projectId="project.id"
                  :project="project"
                  :justify="'center'"
                  :hasAll="true"
                  :hasMore="true"
                  @selectUser="selectUser"
                  :showActionIcon="true"
                  @showPositionMenu="showPositionMenuPopup"
                  @showCommitmentMenu="showCommitmentMenuPopup"
                  :details="details"
                  :skills="skills"
                />
                <v-row class="v-row justify-center mt-2 px-6">
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    class="px-6 inner-head-texts"
                  >
                    <h4>Code Contributions</h4>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <div
                      class="rounded-lg listItem ma-3 pa-3 mr-md-0 text-center"
                    >
                      {{ project.totalLinesAdded }} Lines Added
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <div
                      class="rounded-lg listItem ma-3 pa-3 mr-md-0 ml-md-0 text-center"
                    >
                      {{ project.totalLinesModified }} Lines Modified
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <div
                      class="rounded-lg listItem ma-3 pa-3 ml-md-0 text-center"
                    >
                      {{ project.totalLinesDeleted }} Lines Deleted
                    </div>
                  </v-col>
                </v-row>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  class="px-9 inner-head-texts"
                >
                  <h4>Epics</h4>
                  <v-divider></v-divider>
                </v-col>
                <EpicList :project="project"/>
                <v-row class="justify-center">
                  <v-col cols="10">
                    <v-btn
                      color="accent"
                      dark
                      class="fullSize-button rounded-lg"
                      @click.stop="addNewEpic(project.id)"
                    >
                      <v-icon>mdi-plus</v-icon> New Epic
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card> 
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <div
          v-if="!tableLoading"
          class="emptyResult d-flex justify-center mb-6"
        >
          <h1 class="pa-md-16 ma-16">{{ message }}</h1>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="showNewItem"
      v-model="showNewItem"
      persistent
      max-width="600px"
      @keydown.esc="showNewItem = false"
    >
      <AddEpic
        :projectName="selectedProjectName"
        :projectId="selectedProjectId"
        :epicLoading="epicLoading"
        @cancel="showNewItem = false"
        @success="epicAdded"
      />
    </v-dialog>
  </div>
  <PositionMenu 
    v-if="showPositionMenu"
    :details="details"
    :skills="skills"
    :projectLeads="projectLeads"
    :publicHolidayZones="publicHolidayZones"
    :officeLocations="officeLocations"
    :lineManagers="lineManagers"
    :portfolioManagers="portfolioManagers"
    :officeSpaces="officeSpaces"
    :projects="dashboardProjects"
    :positionId="selectedPositionId"
    :projectId="selectedProjectId"
    @OnClose="showPositionMenu = false"/>
  <CommitmentMenu 
    v-if="showCommitmentMenu"
    :details="details"
    :projects="dashboardProjects"
    :projectId="selectedProjectId"
    :commitmentId="selectedCommitmentId"
    @OnClose="showCommitmentMenu = false"/>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  DashboardEpic,
  DashboardTeammember,
} from "shared-components/src/models/Dahboard";
import { ProjectMessages } from "shared-components/src/definitions/constants";
import DashboardService from "@/services/DashboardService";
import AddEpic from "@/components/AddEpic.vue";
import ProfileAvatar from "@/components/Profile/ProfileAvatar.vue";
import DateRange from "@/components/Range/DateRange.vue";
import EpicList from "./EpicList.vue"
import Details from "shared-components/src/models/Details";
import CoreSkill from "shared-components/src/models/CoreSkill";
import DetailsService from "@/services/DetailsService";
import CoreSkillService from "@/services/CoreSkillService";
import { OfficeLocationModel, OfficeSpaceModel, PositionModel } from "shared-components/src/services/openApi/api";
import ProjectService from "@/services/ProjectService";
import LineManagerService from "@/services/LineManagerService";
import CustomerService from "@/services/CustomerService";
import OfficeSpaceService from "@/services/OfficeSpaceService";
import PositionMenu from "../MyProjects/Menus/PositionMenu.vue"
import CommitmentMenu from "../MyProjects/Menus/CommitmentMenu.vue"
import PortfolioManagerService from "@/services/PortfolioManagerService";

export interface UiTeammember extends DashboardTeammember {
  isClicked: boolean | false;
}

export interface UiDashboardProject {
  id: string;
  Name: string;
  projectName: string;
  epics: DashboardEpic[];
  totalEffort: number;
  teamMembers: UiTeammember[];
  totalLinesAdded: number;
  totalLinesDeleted: number;
  totalLinesModified: number;
  Positions: PositionModel[]
}

export default defineComponent({
  async mounted() {
    this.loading = true;
    await this.loadSkillsList();
    await this.loadDetailsList();
    await this.loadProjectLead();
    await this.loadPublicHolidayZone();
    await this.loadOfficeLocations();
    await this.loadOfficeSpaces();
    await this.loadLineManager();
    await this.loadPortfolioManagers();
    
    this.loading = false;
  },
  components: {
    AddEpic,
    ProfileAvatar,
    DateRange,
    EpicList,
    PositionMenu,
    CommitmentMenu
  },
  data() {
    return {
      showCommitmentMenu: false,
      showPositionMenu: false,
      officeLocations: [
        {
          Address: "Choose Office Space From CodeClan",
          Id: "OfficeSpace",
          Name: "Choose Office Space From CodeClan",
        },
      ] as OfficeLocationModel[],
      lineManagers: [] as Array<{ id: string; Name: string }>,
      officeSpaces: [] as OfficeSpaceModel[],
      projectLeads: [] as Array<{ id: string; Name: string }>,
      portfolioManagers: [] as Array<{ id: string; Name: string }>,
      publicHolidayZones: [] as Array<{ id: string; Name: string }>,
      details: [] as Details[],
      skills: [] as CoreSkill[],
      startDate: {} as Date,
      endDate: {} as Date,
      epicLoading: false,
      showNewItem: false,
      dashboardProjects: [] as UiDashboardProject[],
      clickedTeammembers: [] as Array<{
        projId: string;
        teammemberIds: Array<string>;
      }>,
      selectedProjectName: "",
      selectedProjectId: "",
      selectedTeammemberId: "",
      loading: false,
      tableLoading: false,
      dayOffset: -1,
      message: "",
      selectedPositionId: "",
      selectedCommitmentId: ""
    };
  },
  methods: {
    showCommitmentMenuPopup(event: any){
      this.selectedCommitmentId = event.commitmentId;
      this.selectedProjectId = event.projectId;
      this.showCommitmentMenu = true;
    },
    showPositionMenuPopup(event: any){
      this.selectedPositionId = event.positionId;
      this.selectedProjectId = event.projectId;
      this.showPositionMenu = true;
    },
    async loadProjectLead() {
      this.projectLeads = (await ProjectService.getProjectLeadList())
        .filter((c) => c.FirstName || c.LastName)
        .map((item) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },
    async loadPortfolioManagers() {
      this.portfolioManagers = (await PortfolioManagerService.getPortfolioManagerList())
        .filter((c) => c.FirstName || c.LastName)
        .map((item) => {
          return { id: item.id ?? "", Name: item.FirstName ?? "" + " " + item.LastName ?? "" };
        });
    },
    async loadLineManager(): Promise<void> {
      this.lineManagers = (await LineManagerService.getList())
        .filter((c) => c.FirstName || c.LastName)
        .map((item) => {
          return {
            id: item.id ?? "",
            Name: item.FirstName + " " + item.LastName,
          };
        });
    },
    async loadPublicHolidayZone() {
      this.publicHolidayZones = (
        await ProjectService.getPublicHolidayZoneList()
      ).map((item: any) => {
        return { id: item.Id, Name: item.Title };
      });
    },
    async loadOfficeLocations() {
      const response = await CustomerService.GetOfficeLocations();
      this.officeLocations = this.officeLocations.concat(response);
    },
    async loadOfficeSpaces() {
      this.officeSpaces = await OfficeSpaceService.GetOfficeSpaces();
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails();
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    selectUser(clickedAvatar: any) {
      var membersOfProject = this.clickedTeammembers.find(
        (c) => c.projId === clickedAvatar.projId
      );
      if (membersOfProject) {
        membersOfProject.teammemberIds = clickedAvatar.teammemberIds;
      } else {
        var newSelected = {
          projId: clickedAvatar.projId,
          teammemberIds: [],
        } as { projId: string; teammemberIds: Array<string> };
        newSelected.teammemberIds = clickedAvatar.teammemberIds;
        this.clickedTeammembers.push(newSelected);
      }
      this.setCalculatedValues();
    },
    calculateTotalEffortByEpic(projId: string) {
      this.dashboardProjects
        .filter((c) => c.id === projId)
        .forEach((projItem) => {
          projItem?.epics.forEach((epic) => {
            epic.netEffort = 0;
            projItem?.teamMembers.forEach((teammember) => {
              var teammemberEpic = teammember.epics.find(
                (te) => te.name === epic.name
              );
              if (teammemberEpic) {
                epic.netEffort += teammemberEpic.netEffort;
              }
            });
          });
          var mapedEpics = projItem.epics.map((c) => c.netEffort);
          if (mapedEpics && mapedEpics.length > 0) {
            projItem.totalEffort = mapedEpics.reduce(
              (prev, next) => prev + next
            );
          }
        });
    },
    calculateCodeContributions(
      projId: string = "",
      teammemberIds: string[] = []
    ) {
      this.dashboardProjects
        .filter((c) => !projId || c.id === projId)
        .forEach((projItem) => {
          let totalLinesAdded = 0;
          let totalLinesModified = 0;
          let totalLinesDeleted = 0;
          projItem?.teamMembers.forEach((teammember) => {
            if (
              teammemberIds.length == 0 ||
              teammemberIds.includes(teammember.id)
            ) {
              teammember?.commits?.forEach((commit) => {
                totalLinesAdded += commit.linesAdded;
                totalLinesModified += commit.linesChanged;
                totalLinesDeleted += commit.linesDeleted;
              });
            }
          });
          projItem.totalLinesAdded = totalLinesAdded;
          projItem.totalLinesModified = totalLinesModified;
          projItem.totalLinesDeleted = totalLinesDeleted;
        });
    },
    setCalculatedValues() {
      if (this.clickedTeammembers && this.clickedTeammembers.length > 0) {
        this.clickedTeammembers.forEach((clickedTeammember) => {
          if (
            !clickedTeammember.teammemberIds ||
            clickedTeammember.teammemberIds.length === 0
          ) {
            this.calculateTotalEffortByEpic(clickedTeammember.projId);
            this.calculateCodeContributions(clickedTeammember.projId);
          } else {
            this.calculateCodeContributions(
              clickedTeammember.projId,
              clickedTeammember.teammemberIds
            );
            var projItem = this.dashboardProjects.find(
              (c) => c.id === clickedTeammember.projId
            );
            projItem?.teamMembers
              .filter((projectTeammember) =>
                clickedTeammember.teammemberIds.includes(projectTeammember.id)
              )
              .forEach((teammember) => {
                teammember.isClicked = true;
              });
            var selectedTeammembers = this.clickedTeammembers.find(
              (c) => c.projId === clickedTeammember.projId
            )?.teammemberIds;

            if (projItem) {
              projItem?.epics.forEach((epic) => {
                epic.netEffort = 0;
                if (selectedTeammembers) {
                  selectedTeammembers.forEach((teammember) => {
                    var projectTeammember = projItem?.teamMembers.find(
                      (t) => t.id === teammember
                    );
                    if (projectTeammember) {
                      var teammemberEpic = projectTeammember.epics.find(
                        (te) => te.name === epic.name
                      );
                      if (teammemberEpic) {
                        epic.netEffort += teammemberEpic.netEffort;
                      }
                    }
                  });
                } else {
                  projItem?.epics.forEach((epic) => {
                    epic.netEffort = 0;
                    projItem?.teamMembers.forEach((teammember) => {
                      var teammemberEpic = teammember.epics.find(
                        (te) => te.name === epic.name
                      );
                      if (teammemberEpic) {
                        epic.netEffort += teammemberEpic.netEffort;
                      }
                    });
                  });
                }
              });
              var mapedEpics = projItem.epics.map((c) => c.netEffort);
              if (mapedEpics && mapedEpics.length > 0) {
                projItem.totalEffort = mapedEpics.reduce(
                  (prev, next) => prev + next
                );
              }
            }
          }
        });
      } else {
        this.calculateCodeContributions();
      }
    },
    addNewEpic(projId: any) {
      var proj = this.dashboardProjects.find((c) => c.id === projId);
      if (proj) {
        this.selectedProjectId = projId;
        this.selectedProjectName = proj.Name;
        this.showNewItem = true;
      }
    },
    async epicAdded(model: any) {
      this.epicLoading = true;
      var projItem = this.dashboardProjects.find(
        (c) => c.id === model.projectId
      );
      var exist = projItem?.epics.some((c) => c.name === model.epic);
      if (!exist) {
        projItem?.epics.push({ name: model.epic, netEffort: 0 });
      }
      this.epicLoading = false;
      this.showNewItem = false;
    },
    async fetchProjects(tmId = "", prId = "") {
      this.tableLoading = true;
      var result = await DashboardService.getProjects(
        this.startDate,
        this.endDate,
        tmId,
        prId
      );
      if (result && result.projects && result.projects.length > 0) {
        if (prId) {
          var index = this.dashboardProjects.findIndex((object) => {
            return object.id === prId;
          });
          if (index) {
            this.dashboardProjects[index].epics = result.projects[0].epics;
            this.dashboardProjects[index].totalEffort =
              result.projects[0].totalEffort;
          }
        } else {
          this.dashboardProjects = [...result.projects] as UiDashboardProject[];
          this.setCalculatedValues();
        }
      } else {
        this.message = ProjectMessages.NoProject;
      }
      this.tableLoading = false;
      this.loading = false;
    },
    setDate(startDate: Date, endDate: Date) {
      this.startDate = startDate;
      this.endDate = endDate;
    },
    async fetchData(dateModel: { startDate: Date; endDate: Date }) {
      this.setDate(dateModel.startDate, dateModel.endDate);
      await this.fetchProjects();
    },
  },
});
</script>
<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.notWorking {
  opacity: $deactive;
}
.transparent {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}
.dataBody {
  background-color: $dark_gray;
}
.emptyResult h1 {
  text-align: center;
  line-height: $size-l;
}

.inner-head-texts {
  margin-bottom: -20px;
}

@media (max-width: 960px) {
  .projItem {
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .week-title {
    font-size: $size-s;
  }
}
</style>
