<template>
  <v-card-text>
    <v-row
      class="details"
      v-if="activityReport && activityReport.length > 0"
      justify="center"
    >
      <v-col cols="12" md="5" sm="6" xs="12">
        <h3>Activity</h3>
        <v-col class="mt-4">
          <v-row
            class="itemDetail"
            v-for="activity in activityReport"
            :key="`activity-${activity.id}`"
          >
            <v-col class="elementName">{{ activity.name }}</v-col>
            <v-col class="effortBox">{{
              removeDecimals(activity.netEffort)
            }}</v-col>
          </v-row>
          <v-row class="itemDetail sumSection">
            <v-col>Sum</v-col>
            <v-col class="effortBox">{{ activitySum }}</v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="12" md="7" sm="6" xs="12">
        <div class="chartBody">
          <Pie :data="acChartData" :options="options" />
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="technologyReport && technologyReport.length > 0"
      class="mt-5 details"
      justify="center"
    >
      <v-col cols="12" md="5" sm="6" xs="12">
        <h3>Technology</h3>
        <v-col class="mt-4">
          <v-row
            class="itemDetail"
            v-for="tech in technologyReport"
            :key="`tech-${tech.id}`"
          >
            <v-col class="elementName">{{ tech.name }}</v-col>
            <v-col class="effortBox">{{
              removeDecimals(tech.netEffort)
            }}</v-col>
          </v-row>
          <v-row class="itemDetail sumSection">
            <v-col>Sum</v-col>
            <v-col class="effortBox">{{ technologySum }}</v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="12" md="7" sm="6" xs="12">
        <div class="chartBody">
          <Pie :data="techChartDate" :options="options" />
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="epicReport && epicReport.length > 0"
      class="mt-5 details"
      justify="center"
    >
      <v-col cols="12" md="5" sm="6" xs="12">
        <h3>Epic</h3>
        <v-col class="mt-4">
          <v-row
            class="itemDetail"
            v-for="epic in epicReport"
            :key="`tech-${epic.name}`"
          >
            <v-col class="elementName">{{ epic.name }}</v-col>
            <v-col class="effortBox">{{
              removeDecimals(epic.netEffort)
            }}</v-col>
          </v-row>
          <v-row class="itemDetail sumSection">
            <v-col>Sum</v-col>
            <v-col class="effortBox">{{ epicSum }}</v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="12" md="7" sm="6" xs="12" justify="right">
        <div class="chartBody">
          <Pie :data="epicChartData" :options="options" />
        </div>
      </v-col>
    </v-row>
  </v-card-text>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  ActivityReport,
  TechnologyReport,
  EpicReport,
} from "shared-components/src/models/ProjectLeadReport";
import { PieChart } from "shared-components/src/models/PieChart";
import AppHelper from "shared-components/src/utils/AppHelper";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";
import { DarkThemeColors } from "shared-components/src/definitions/constants";

ChartJS.register(ArcElement, Tooltip, Legend);

export default defineComponent({
  props: ["activities", "technologies", "epics"],
  components: {
    Pie,
  },
  data() {
    return {
      chartMessage: "",
      acChartData: {} as PieChart,
      techChartDate: {} as PieChart,
      epicChartData: {} as PieChart,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            labels: {
              color: DarkThemeColors.Primary,
            },
          },
        },
      },
      activityReport: [] as ActivityReport[],
      technologyReport: [] as TechnologyReport[],
      epicReport: [] as EpicReport[],
    };
  },
  methods: {
    removeDecimals(value: any) {
      return AppHelper.removeDecimals(value);
    },
    getSum(list: any) {
      const sum = list.reduce((accumulator: any, object: any) => {
        return accumulator + object.netEffort;
      }, 0);
      return sum;
    },
    renderChart(labels: string[], datas: string[], backgroundcolors: string[]) {
      var newConfigs = {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundcolors,
            data: datas,
            hoverOffset: 4,
          },
        ],
      };
      return newConfigs as PieChart;
    },
  },
  watch: {
    activities(newVal) {
      var newData = newVal.filter((c: any) => c.name);
      this.activityReport = newData;
      var labels = newData.map((c: any) => c.name);
      var data = newData.map((c: any) => c.netEffort);
      var backgroundColor = newData.map((c: any) => {
        return AppHelper.GetColour(c.name);
      });
      this.acChartData = this.renderChart(labels, data, backgroundColor);
    },
    technologies(newVal) {
      var newData = newVal.filter((c: any) => c.name);
      this.technologyReport = newData;
      var labels = newData.map((c: any) => c.name);
      var data = newData.map((c: any) => c.netEffort);
      var backgroundColor = newData.map((c: any) => {
        return AppHelper.GetColour(c.name);
      });
      this.techChartDate = this.renderChart(labels, data, backgroundColor);
    },
    epics(newVal) {
      var newData = newVal.filter((c: any) => c.name);
      this.epicReport = newData;
      var labels = newData.map((c: any) => c.name);
      var data = newData.map((c: any) => c.netEffort);
      var backgroundColor = newData.map((c: any) => {
        return AppHelper.GetColour(c.name);
      });
      this.epicChartData = this.renderChart(labels, data, backgroundColor);
    },
  },
  computed: {
    activitySum() {
      return this.getSum(this.activityReport);
    },
    technologySum() {
      return this.getSum(this.technologyReport);
    },
    epicSum() {
      return this.getSum(this.epicReport);
    },
  },
});
</script>
<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.sumSection {
  font-weight: bold;
}
.details {
  position: relative;
}
h3 {
  text-align: center;
}
.elementName {
  overflow: hidden;
}
.itemDetail {
  border: 1px solid $primary;
  text-align: center;
}
.chartBody {
  width: 300px;
  float: right;
  right: 0px;
  top: 0px;
}
.chartBody canvas {
  height: 500px;
}
.effortBox {
  border-left: 1px solid $primary;
}
@media (max-width: 1200px) {
  .chartBody {
    width: 100%;
  }
}
</style>
