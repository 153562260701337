import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import "vuetify/styles";
import { createVuetify, ThemeDefinition } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/style/app.scss";
import "shared-components/assets/style/app.scss";
import "shared-components/assets/client-main.scss";
import "@/extentions/prototype";
import "@/Plugins/moment";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import TextAreaField from "shared-components/src/components/Fields/TextAreaField.vue";
import SelectField from "shared-components/src/components/Fields/SelectField.vue";
import ComboBoxField from "shared-components/src/components/Fields/ComboBoxField.vue";
import AutoCompleteField from "shared-components/src/components/Fields/AutoCompleteField.vue";
import PasswordField from "shared-components/src/components/Fields/PasswordField.vue";
import DateInputField from "shared-components/src/components/Fields/DateInputField.vue";
import { VDateInput } from "vuetify/labs/VDateInput";
const dark: ThemeDefinition = {
  dark: false,
  colors: {
    primary: "#fff",
    secondary: "#302c2b",
    accent: "#dc1e28",
    background: "#fff",
  },
};
const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: "dark",
    themes: {
      dark,
    },
  },
  components: { ...components, VDateInput },
  directives,
});

createApp(App)
  .component("PasswordField", PasswordField)
  .component("TextField", TextField)
  .component("TextAreaField", TextAreaField)
  .component("SelectField", SelectField)
  .component("ComboBoxField", ComboBoxField)
  .component("AutoCompleteField", AutoCompleteField)
  .component("DateInputField", DateInputField)
  .use(store)
  .use(router)
  .use(vuetify)
  .mount("#project-portal-app");
