<template>
  <div class="adf-embedded"></div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  export default defineComponent({
    data() {
      return {
      };
    },
    mounted() {
      const adfEmbedded = document.getElementsByClassName("adf-embedded")[0]
      adfEmbedded.setAttribute('key', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzZXNzaW9uSWQiOm51bGwsImZvcm1JZCI6bnVsbCwiY29tcGFueUlkIjoiemFoODRvZEdqSWlJNEluWGUxS0wiLCJsaW5rSWQiOm51bGwsInRoZW1lIjpudWxsLCJjb250YWN0SWRzIjpbXSwiY29udGFjdEdyb3VwSWRzIjpbXSwiY2xpY2tlckVtYWlsQWRkcmVzcyI6bnVsbCwicGFyZW50U2Vzc2lvbklkIjpudWxsfQ.w63rPjo7vAjfIIpe63mlFwXcXtZ_OgkIxHjSbYkCrhM');
      let sfScript = document.createElement('script')
      sfScript.setAttribute('src', 'https://widget-sight-forms-prod.web.app/Embedded.js?v=16806740941233')
      document.head.appendChild(sfScript)
    },
  });
</script>
