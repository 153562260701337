<template>
  <div>
    Hi ProjectLead
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      
    };
  },
  methods: {
  },
});
</script>
<style scoped lang="scss">
</style>
