import ApiService from "./ApiService";
import RoadMapModel from "shared-components/src/models/RoadMapModel";
import FindRoadMapsRequest from "shared-components/src/models/requests/FindRoadMapsRequest"

export default class RoadMapService {
     public static async AddTalents(roadMapId: string, model: string[]): Promise<RoadMapModel> {
        return new Promise((resolve, reject) => {
            ApiService.post(`/roadMap/${roadMapId}/addTalents`, model).then((result) => {
                if (!result.data) {
                    resolve({} as RoadMapModel);
                } else {
                    var response = result.data as RoadMapModel;
                    resolve(response);
                }
            }).catch((error) => reject(error));
        });
    }

    public static async CreateOrEdit( model: RoadMapModel): Promise<RoadMapModel> {
        return new Promise((resolve, reject) => {
            ApiService.post(`/roadMap`, model).then((result) => {
                if (!result.data) {
                    resolve({} as RoadMapModel);
                } else {
                    var response = result.data as RoadMapModel;
                    resolve(response);
                }
            }).catch((error) => reject(error));
        });
    }

    public static async GetList(criteria: FindRoadMapsRequest | null = null): Promise<RoadMapModel[]> {
        return new Promise((resolve, reject) => {
            ApiService.post(`/roadMap/find`, criteria ? criteria : {} as FindRoadMapsRequest).then((result) => {
                if (!result.data) {
                    resolve([] as RoadMapModel[]);
                } else {
                    var response = result.data as RoadMapModel[];
                    resolve(response);
                }
            }).catch((error) => reject(error));
        });
    }

    public static async GetById(roadMapId: string): Promise<RoadMapModel> {
        return new Promise((resolve, reject) => {
            ApiService.get(`/roadMap/${roadMapId}`).then((result) => {
                if (!result.data) {
                    resolve({} as RoadMapModel);
                } else {
                    var response = result.data as RoadMapModel;
                    resolve(response);
                }
            }).catch((error) => reject(error));
        });
    }

    public static async RemoveRoadMap(roadMapId: string): Promise<string> {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/roadMap/${roadMapId}`).then((result) => {
                if (!result.data) {
                    resolve("");
                } else {
                    var response = result.data;
                    resolve(response);
                }
            }).catch((error) => reject(error));
        });
    }

     public static async UpdateRoadMapStatus(roadMapId: string, status: string): Promise<RoadMapModel> {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/roadMap`, { Id: roadMapId, Status: status }).then((result) => {
                if (!result.data) {
                    resolve({} as RoadMapModel);
                } else {
                    var response = result.data as RoadMapModel;
                    resolve(response);
                }
            }).catch((error) => reject(error));
        });
    }
}

