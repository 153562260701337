<template>
  <div>
    <div class="card-title">
      <div class="card-title-left"></div>
      <div class="card-title-right">
        <v-btn class="primary_btn" prepend-icon="mdi-plus" @click="showNewProject">New Project</v-btn>
      </div>
    </div>
    <v-row class="mt-3">
      <v-col cols="12" v-for="project in projects" :key="project.Id">
        <v-card class="project-item">
          <v-card-title>
            <div class="card-title">
              <div class="card-title-left">{{ project.Name }}</div>
              <div class="card-title-right">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" variant="text" size="small" class="button" icon="mdi-dots-vertical"/>
                  </template>
                  <v-list>
                    <v-list-item title="Update Project" @click="showUpdateProject(project)"/>
                    <v-list-item title="Epic List" @click="showEpicPopup(project)"/>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <div class="tm-rows">
              <label>Teammembers</label>
              <div v-if="project.Teammembers?.length == 0" class="no-tm"> There are no any Teammember for this Project</div>
              <v-row v-for="tm in project.Teammembers" class="tm-row">
                <v-col cols="8">
                  <Avatar :Name="tm.Name" :Photo="tm.PhotoUrl" class="tm-avatar"/>
                  <span class="tm-name">{{tm.Name}}</span>
                </v-col>
                <v-col cols="2" class="pa-0 pt-2">
                  <div class="chart-container" v-if="tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0">
                    <Line :data="getLineChartData(tm)" :options="lineOptions" />
                  </div>
                </v-col>
                <v-col cols="1" class="pa-0 pt-2">
                  <div class="chart-container" v-if="tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0">
                    <Bar :data="getBarChartData(tm)" :options="barOptions" />
                  </div>
                </v-col>
                <v-col cols="1" class="text-right">
                  <v-menu scroll-strategy="close">
                    <template v-slot:activator="{ props }">
                      <v-btn color="black" variant="text" size="small" v-bind="props" icon="mdi-dots-vertical"/>
                    </template>
                    <v-card>
                      <v-list>
                        <v-list-item v-if="tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0" title="Activity Watch Report" @click="showActivityWatchPopup(tm)"/>
                        <v-list-item v-if="tm.ActivityWatchDetails && tm.ActivityWatchDetails.length > 0" title="Wazuh Report"
                          @click="showWazuhPopup(tm)"/>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card> 
      </v-col>
    </v-row>
    <v-dialog v-model="showProjectModal" max-width="700">
      <ProjectDetail
        @close="closeProjectPopup"
        @savedProject="savedProject"
        :project="selectedProject"
      />
    </v-dialog>
    <v-dialog v-model="showEpicList" max-width="700">
      <EpicList
        @close="showEpicList = false"
        :project="selectedProject"
        :isClient="true"
      />
    </v-dialog>
    <v-dialog v-model="showActivityWatchModal" v-if="showActivityWatchModal && selectedVmId" max-width="700" persistent @keydown.esc="closeActivityWatchPopup">
      <v-card>
        <v-card-text>
          <ActivityWatchReport
            :isClient="true"
            :vmId="selectedVmId"
            @Close="closeActivityWatchPopup"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showWazuhModal" v-if="showWazuhModal && selectedVmId" max-width="700" persistent @keydown.esc="closeWazuhPopup">
      <v-card>
        <v-card-text>
          <WazuhDashboard
            :vmId="selectedVmId"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Project from "shared-components/src/models/Project";
import ProjectDetail from "shared-components/src/components/Project/ProjectDetails.vue";
import EpicList from "shared-components/src/components/Project/EpicList.vue";
import store from "@/store";
import { ActivityWatchDetail, ClientProjectApi, ClientProjectWithDetailsResponseModel, TeammemberWithVmDetails, VmMonitorDetail } from "shared-components/src/services/openApi/api";
import Avatar from "@/components/Avatar.vue";
import { Bar } from "vue-chartjs";
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import moment from "moment";
import WazuhDashboard from "shared-components/src/components/Reports/Wazuh/Dashboard.vue";
import ActivityWatchReport from "shared-components/src/components/Reports/ActivityWatch/ActivityWatchReport.vue";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)
export default defineComponent({
  components: { ProjectDetail, EpicList, Avatar, Bar, Line, WazuhDashboard, ActivityWatchReport },
  data() {
    return {
      showProjectModal: false,
      showActivityWatchModal: false,
      showWazuhModal: false,
      selectedVmId: null as string | null,
      projects: [] as ClientProjectWithDetailsResponseModel[],
      selectedProject: null as Project | null,
      showEpicList: false,
      lineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point:{
                radius: 0
            },
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            min: 0,
            max: 250,
            ticks:{
              display: false,
              stepSize: 50,
              autoSkip: false
            }
          },
          x:{
            min: 0,
            max: 336,
            ticks:{
              autoSkip: false,
              display: false,
              stepSize: 1,
            }
          },
          x2:{
            labels:["asd","asd"],
            grid: {
                offset: true
            },
            ticks:{
              autoSkip: false,
              font: {
                  size: 10
              },
            }
          },
        }
      },
      barOptions: {
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            max: 10,
            ticks:{
              display: false,
              stepSize: 1,
              autoSkip: false
            }
          },
          x: {
            ticks:{
              font: {
                  size: 10
              },
              autoSkip: false
            }
          }
        }
      },
    };
  },
  async mounted() {
    await this.fetchProjects();
  },
  watch:{
   "$store.state.newProjectPopup": {
      handler(value) {
        if(value == 1){
          this.showNewProject()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    showActivityWatchPopup(tm: TeammemberWithVmDetails){
      if(tm.VmId){
        this.selectedVmId = tm.VmId;
        this.showActivityWatchModal = true;
      }
    },
    closeActivityWatchPopup(){
      this.selectedVmId = null;
      this.showActivityWatchModal = false;
    },
    closeWazuhPopup(){
      this.selectedVmId = null;
      this.showWazuhModal = false;
    },
    showWazuhPopup(tm: TeammemberWithVmDetails){
      if(tm.VmId){
        this.selectedVmId = tm.VmId;
        this.showWazuhModal = true;
      }
    },
    closeProjectPopup(){
      this.showProjectModal = false;
      store.dispatch("setNewProjectPopupStatus", false);
    },
    showNewProject(){
      this.selectedProject = null;
      this.showProjectModal = true;
    },
    showUpdateProject(project: any){
      this.selectedProject = project;
      this.showProjectModal = true;
    },
    showEpicPopup(project: any){
      this.selectedProject = project;
      this.showEpicList = true;
    },
    async fetchProjects(){
      this.projects = (await new ClientProjectApi().clientGetProjectsWithDetails()).data;
    },
    savedProject(project: any){
      if(this.selectedProject){
        this.selectedProject.Name = project.Name;
      }else{
        this.projects.push(project)
      }
      this.showProjectModal = false;
    },
    getLineChartData(tm: TeammemberWithVmDetails){
      if(tm.VmMonitorDetails){
        const labels = tm.VmMonitorDetails.map((c: VmMonitorDetail) => c.TimeDetail?.map(d => d.Time)).flat();
        const data = tm.VmMonitorDetails.map((c: VmMonitorDetail) => c.TimeDetail?.map(d => d.Latency ?? 0)).flat();
        const dataStatus = tm.VmMonitorDetails.map((c: VmMonitorDetail) => c.TimeDetail?.map(d => d.Status ? 250 : 0)).flat();
        this.lineOptions.scales.x2.labels = [];
        this.lineOptions.scales.x2.labels = tm.VmMonitorDetails.map((c: VmMonitorDetail) => moment(c.Date).format("dd"))
        return {
          labels: labels,
          datasets: [
            {
              borderWidth: 0.5,
              borderColor: '#e37272',
              label: 'VMO Latency Last 7 Days',
              backgroundColor: '#e37272',
              data: data
            },
            {
              borderWidth: 0.5,
              borderColor: '#00000',
              label: 'VMO Status Last 7 Days',
              backgroundColor: '#f485465',
              data: dataStatus
            }
          ]
        }
      }
    },
    getBarChartData(tm: TeammemberWithVmDetails){
      if(tm.ActivityWatchDetails){
        const labels = tm.ActivityWatchDetails.map((c: ActivityWatchDetail) => moment(c.Date).format("dd"));
        const data = tm.ActivityWatchDetails.map((c: ActivityWatchDetail) => ((c.TotalSecounds ?? 0) / 3600).toFixed(2));
        return {
          labels: labels,
          datasets: [{
            data: data,
            label: '',
            backgroundColor: "#ffe100",
            stack: 'stack 1',
          }]
        }
      }
    }
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/color.scss";
@import "node_modules/shared-components/assets/style/style.scss";

.project-item{
  min-height: 200px;
  .no-tm{
    height: 64px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: $c_davys_grey;
    @extend .b-r-5;
  }
  .tm-rows{
    display: flex;
    flex-direction: column;
    gap: 5px;
    .tm-row{
      align-items: center;
      margin: 0;
      background-color: $c_white;
      @extend .b-r-5;

      .chart-container{
        padding: 0;
        height: 80px;
        width: 100% !important; 
      }
      .tm-avatar{
        box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
      }
      .tm-name{
        color: $c_black;
        padding-left: 5px;
      }
    }
  }
}


</style>