import ApiService from "./ApiService";
import ClientInfo from "shared-components/src/models/ClientInfo";
export default class UserService {
  public static async sendAuthenticationLink(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/authentication/CreateEmailLoginForClient/${email}`)
        .then((result) => {
          if (!result.data) {
            resolve(null);
          } else {
            resolve(result.data);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static async verifyToken(): Promise<ClientInfo> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/authentication/ValidateTokenAndReturnClientInformation`)
        .then((result) => {
          if (!result.data || !result.data.status) {
            resolve({} as ClientInfo);
          } else {
            var doc = result.data.data;
            var client = {
              email: doc.Email,
              firstName: doc.FirstName,
              lastName: doc.LastName,
              fullName: `${doc.FirstName} ${doc.LastName}`,
              IsActive: doc.IsActive,
              id: doc.id,
              roles: doc.Roles,
              userId: doc.UserId,
            } as ClientInfo;
            resolve(client);
          }
        })
        .catch((error) => reject(error));
    });
  }
}
