<template>
  <v-form ref="frmMain" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title> {{isNew == true ? 'Add a new Position' : 'Update Position Details' }} </v-card-title>
      <v-card-text>
        <v-row class="align-center">
          <v-col cols="12" md="12">
            <AutoCompleteField
              dense
              label="Role"
              item-title="Name"
              item-value="id"
              :rules="[rules.required]"
              :items="getJobRoles"
              v-model="model.JobRoleId"
              outlined
            />
          </v-col>
          <v-col cols="12" md="4">
            <TextField
              dense
              label="Number of Seats"
              :rules="[rules.required, rules.minNumber(1)]"
              v-model="model.NoOfPositions"
              outlined
              type="number"
              :min="1"
            />
          </v-col>
          <v-col cols="12" md="4">
            <DateInputField label="Start date" :rules="[asapCheckBox || rules.required]" first-day-of-week="1" v-model="model.StartDate" hide-actions   placeholder="Start date" prepend-icon="" />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox hide-details label="ASAP" v-model="asapCheckBox"></v-checkbox>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" md="12">
            <AutoCompleteField
              dense
              clearable
              label="Job Titles"
              item-title="Name"
              item-value="id"
              chips
              closable-chips
              multiple
              :items="getJobTitle"
              v-model="model.JobTitles"
              outlined
            />
          </v-col>
          <v-col cols="6" md="6">
            <AutoCompleteField
              dense
              label="Skills"
              item-title="Text"
              item-value="id"
              :items="skills"
              chips
              closable-chips
              multiple
              v-model="model.Skills"
              outlined
            />
          </v-col>
          <v-col cols="6" md="6">
            <AutoCompleteField
              dense
              label="Country"
              item-title="text"
              item-value="text"
              :items="locations"
              multiple
              chips
              closable-chips
              outlined
              v-model="model.Country"
            />
          </v-col>
          
          <v-col cols="12" md="12">
            <TextAreaField
              dense
              label="Job Description"
              v-model="model.JobDescription"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <div class="v-card-actions">
        <v-col cols="12" md="12">
          <v-btn
            class="right"
            color="secondary"
            @click="save"
            :loading="loading"
            :disabled="loading"
            ><v-icon>mdi-check</v-icon>Save</v-btn
          >
          <v-btn
            class="right mr-5"
            color="secondary"
            @click="cancel"
            :disabled="loading"
            >cancel</v-btn
          >
        </v-col>
      </div>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, toRaw } from "vue";
import rules from "shared-components/src/utils/validations";
import Utils from "@/Helpers/Utils";
export default defineComponent({
  props: ["addLoading", "details", "positionId", "projectModel", "skills", "isNew"],
  data() {
    return {
      rules,
      valid: false,
      locations: [
        {text: "Australia"},{text: "Dubai"},{text: "Hong Kong"} ,{text: "India"} ,{text: "Iran"},{text: "Malaysia"},{text: "Philippines"},{text: "Singapore"} ,{text: "Taiwan"}],
      model: {
        JobRoleId: "",
        JobTitles: [],
        JobDescription: "",
        Skills: [],
        NoOfPositions: 1,
        StartDate: null as Date | null,
        Country: []
      },
      asapCheckBox: false,
      startDatePickerMenu: false,
      loading: false,
    };
  },
  mounted() {
    if (this.projectModel) {
      const position = this.projectModel.Positions.find(
        (item: any) => item.Id == this.positionId
      );
      this.model.JobRoleId = position.JobRoleId;
      this.model.StartDate = Utils.vsDateToDatetime(position.StartDate);
      this.model.NoOfPositions = position.NoOfPositions;
      this.asapCheckBox = position.StartDate == null;
      this.model.JobDescription = position.JobDescription;
      this.model.Skills = position.Skills;
      this.model.Country = position.Country;
      this.model.JobTitles = position.JobTitles;
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async save() {
      const isValid = await (this.$refs.frmMain as any).validate();
      if (isValid.valid) {
        this.loading = true;
        if (this.asapCheckBox) {
          this.model.StartDate = null;
        }
        this.model.NoOfPositions = Number(this.model.NoOfPositions);
        this.$emit("success", this.model);
      }
    },
  },
  computed: {
    getJobRoles() {
      return toRaw(this.details).filter((item: any) => item.Type == "JobRole");
    },
    getJobTitle() {
      const fileterItems = toRaw(this.details).filter(
        (item: any) =>
          item.Type == "JobTitle" && item.RoleId == this.model.JobRoleId
      )
      this.model.JobTitles.forEach((item, index) => {
        if(!fileterItems.find((jt : any) => jt.id == item)){
          this.model.JobTitles.splice(index, 1);
        }
      })
      return fileterItems;
    },
    formatedStartDate() {
      return this.model.StartDate
        ? Utils.toVsDateFormat(this.model.StartDate)
        : null;
    },
  },
  watch: {
    addLoading(newVal) {
      this.loading = newVal;
    },
  },
});
</script>
