<template>
  <div class="main-employee-box">
    <img
      v-if="employee.PhotoUrl"
      :src="employee.PhotoUrl"
      class="employee-avatar"
    />
    <img
      v-else
      src="@/assets/images/default-avatar.avif"
      class="employee-avatar"
    />
    <span>
      {{ capitalize(employee.FirstName) }}
      {{ capitalize(employee.LastName) }}
    </span>
    <span>
      {{ employee.SpecificTitle ? getTitle(employee.SpecificTitle) : "-" }}
    </span>
    <v-btn @click="showTMDetails">View Details</v-btn>
    <div v-if="showDetails" class="custom-modal">
      <div class="inner">
        <div class="content" @click="showDetails = false">
          <CVTemplate1
            :employeeId="employee.Id ?? ''"
            :skills="skills"
            :details="details"
            @click.stop.prevent
          ></CVTemplate1>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import CVTemplate1 from "@/components/CVTemplate/CVTemplate1.vue";
import CoreSkill from "shared-components/src/models/CoreSkill";
import Details from "shared-components/src/models/Details";
import { ClientSearchTeammemberResponseModel } from "shared-components/src/services/openApi/api";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    employee: {
      type: Object as () => ClientSearchTeammemberResponseModel,
      required: true,
    },
    skills: {
      type: Array as () => CoreSkill[],
      required: true,
    },
    details: {
      type: Array as () => Details[],
      required: true,
    },
    showInScene: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {},
  components: { CVTemplate1 },
  data() {
    return {
      showDetails: false,
    };
  },
  methods: {
    capitalize(name: string | undefined) {
      if (name) return name.charAt(0).toUpperCase() + name.slice(1);
      return name;
    },
    getTitle(id: string | undefined) {
      if (id) {
        return this.capitalize(
          this.details.find((item) => item.id == id)?.Name ?? undefined
        );
      }
      return "";
    },
    showTMDetails() {
      if (this.showInScene) {
        this.$emit("ViewTmDetails", this.employee);
      } else {
        this.showDetails = true;
      }
    },
  },
  computed: {},
});
</script>
<style scoped lang="scss">
.main-employee-box {
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  max-width: 250px;
  min-height: 279px;
  cursor: pointer;

  .employee-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50px;
  }
}
</style>
