<template>
  <div class="header-title">
    <v-app-bar-nav-icon class="nav-icon">
      <i
        class="icon"
        :class="{
          'v-icon': true,
          notranslate: true,
          mdi: true,
          dark: false,
          [icon]: true,
        }"
      ></i>
    </v-app-bar-nav-icon>
    <div class="page-name">{{ title }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      icon: "",
      title: "",
    };
  },
  created() {
    this.icon = this.$route.meta.icon as string;
    this.title = this.$route.meta.title as string;
  },
  watch: {
    $route() {
      this.icon = this.$route.meta.icon as string;
      this.title = this.$route.meta.title as string;
    },
  },
});
</script>
<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";


.header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .page-name {
    font-family: Teko, sans-serif;
    color: $dark_gray;
    font-size: $size-m;
    font-weight: $bold-xl;
  }
  .icon {
    color: $dark_gray;
    font-size: $size-m;
  }
  
}
@media(max-width: 960px) {
  .nav-icon {
    display: none;
  }
}

</style>
