<template>
  <v-row :class="[isTotal ? 'text-white pb-2' : '']">
    <v-col cols="12" class="text-center">
      <span>Estimated {{ isTotal ? 'total' : '' }} cost: AUD {{ getPerHourPrice }} per work hour</span>
      <br/>
      <span>Approx. AUD {{getPerMonthPrice}} per month</span>
      <v-tooltip :text="getPriceTooltip()" max-width="300" v-if="!isTotal">
        <template v-slot:activator="{ props }">
          <v-btn variant="text" size="x-small" icon="mdi-help" v-bind="props"/>
        </template>
      </v-tooltip>
      
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["commitmentModel", "isTimesheetScreen", "isTotal"],
  data() {
    return {
      vmBasePerHour: 0.20,
      hoursPerMonth: 176,
      timeSheetBasePerHour: 0.10
    };
  },
  async mounted() {
   
  },
  methods: {
    getTotalOfHoursPerMonth(price: number){
      return price * this.hoursPerMonth;
    },
    getPriceTooltip(){
      return !this.isTimesheetScreen ? "Pricing is calculated based on the hours of work not the uptime, even though Virtual Desktops are dedicated to the user 24 hours a day 7 days a week. For example, the cost of a Virtual Desktop instance that is dedicated to a user who works fulltime, is calculated based on 40 hours per week." :
      "The cost of timesheet management is calculated based on the actual work hours logged in the timesheet by the Team Member. For example, if they   log 40 hours per week in the timesheet, the timesheet management cost for that week will be calculated based on 40 hours"
      ;
    }
  },
  computed: {
    getVmBaseWithExtra(){
      const cpuExtra = this.commitmentModel.VirtualDesktopSpec.Cpu  / 100
      const ramExtra = this.commitmentModel.VirtualDesktopSpec.Ram / 100
      const diskExtra = this.commitmentModel.VirtualDesktopSpec.Disk / 100

      return this.vmBasePerHour + cpuExtra + ramExtra + diskExtra
    },
    getPerHourPrice(){
      if(this.isTotal){
        return (this.getVmBaseWithExtra + this.getTimesheetPerHourBase).toFixed(2)
      }else if (this.isTimesheetScreen){
        return this.getTimesheetPerHourBase.toFixed(2)
      }else{
        return this.getVmBaseWithExtra.toFixed(2)
      }
    },
    getPerMonthPrice(){
      if(this.isTotal){
        return this.getTotalOfHoursPerMonth(this.getVmBaseWithExtra + this.getTimesheetPerHourBase).toFixed(2)
      }else if (this.isTimesheetScreen){
        return this.getTotalOfHoursPerMonth(this.getTimesheetPerHourBase).toFixed(2)
      }else{
        return this.getTotalOfHoursPerMonth(this.getVmBaseWithExtra).toFixed(2)
      }
    },
    getTimesheetPerHourBase(){
      return this.commitmentModel.EnableTimesheetProcessing ? this.timeSheetBasePerHour : 0;
    }

  }
});
</script>