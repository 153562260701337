<template>
  <v-dialog v-model="showSearchDialog" persistent max-width="1000px" :loading="dialogLoading" :disabled="dialogLoading">
    <div class="close-btn">
      <v-icon @click="closeDialog">mdi-close</v-icon>
    </div>
    <v-stepper hide-actions alt-labels :items="commitmentSteps" ref="stepper" v-model="stepState">
      <template v-slot:item.1>
        <v-card flat class="search-background">
          <v-tabs v-model="teammemberTabs" bg-color="primary">
            <v-tab value="search">Search Teammember</v-tab>
            <v-tab value="invite">Invite Teammember</v-tab>
            <v-tab value="jobDescription">Search By Job Description</v-tab>
          </v-tabs>

          <v-card-text>
            <v-tabs-window v-model="teammemberTabs">
              <v-tabs-window-item value="search">
                <Search @ViewTmDetails="viewTmDetails" :positionJD="getPostionJD" :showInScene="true" />
              </v-tabs-window-item>

              <v-tabs-window-item value="invite">
                <InviteTeammember @InviteTM="inviteTeammember" />
              </v-tabs-window-item>
              <v-tabs-window-item value="jobDescription">
                <SearchByJD :positionJD="getPostionJD" @ContinueJD="ContinueJD" />
              </v-tabs-window-item>
            </v-tabs-window>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:item.2>
        <Details :selectedTM="selectedTM" :selectedProject="project" :projectLeads="projectLeads"
          :publicHolidayZones="publicHolidayZones" :projects="projects" @BackStep="backStep"
          :portfolioManagers="portfolioManagers"
          @NextStep="updateCommitmentDetails" :isJdSearch="isJdSearch"></Details>
      </template>

      <template v-slot:item.3>
        <Location @BackStep="backStep" @NextStep="updateLocationTypeDetails" @saveLocation="saveOfficeLocation"
          :officeLocations="officeLocations" :isJdSearch="isJdSearch"></Location>
      </template>

      <template v-slot:item.4>
        <AddOns @BackStep="backStep" @NextStep="saveCommitment" :lineManagers="lineManagers" :loading="loading"
          :officeSpaces="officeSpaces" :commitmentModel="model"></AddOns>
      </template>
    </v-stepper>
  </v-dialog>

  <div v-if="showTMDetails && selectedTM" class="custom-modal">
    <div class="inner">
      <div class="content" @click="showTMDetails = false">
        <CVTemplate1 :employeeId="selectedTM.Id ?? ''" :skills="skills" :details="details" @click.stop.prevent
          @AddToMyTeam="assignTmToPosition"></CVTemplate1>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Search from "../../Search/Search.vue";
import CVTemplate1 from "@/components/CVTemplate/CVTemplate1.vue";
import Utils from "../../../utils/Utils";
import Details from "./Details.vue";
import Location from "./Location.vue";
import AddOns from "./AddOns.vue";
import Commitment from "shared-components/src/models/Commitment";
import CommitmentService from "@/services/CommitmentService";
import InviteTeammember from "./InviteTeammember.vue"
import SearchByJD from "./SearchByJD.vue"
import { ClientSearchTeammemberResponseModel, PositionDetailsModel, PositionJDAndDetailsRequestModel } from "shared-components/src/services/openApi/api";
import PositionService from "@/services/PositionService";

export default defineComponent({
  components: {
    Search,
    CVTemplate1,
    Details,
    Location,
    AddOns,
    InviteTeammember,
    SearchByJD
  },
  props: [
    "skills",
    "details",
    "project",
    "projectLeads",
    "portfolioManagers",
    "publicHolidayZones",
    "projects",
    "officeLocations",
    "dialogLoading",
    "positionId",
    "lineManagers",
    "officeSpaces",
  ],
  data() {
    return {
      teammemberTabs: null,
      showSearchDialog: true,
      loading: false,
      showTMDetails: false,
      selectedTM: null as null | ClientSearchTeammemberResponseModel,
      endDatePickerMenu: false,
      startDatePickerMenu: false,
      stepState: 1,
      commitmentSteps: ["Team member", "Details", "Location", "Add-ons"],
      model: {} as Commitment,
      isJdSearch: false,
      JobDescriptionByJdSearch: "",
      rules: {
        required: (value: any) =>
          (!(value instanceof Array) && !!value) ||
          (value instanceof Array && value.length > 0) ||
          "Required.",
        float: (v: any) =>
          !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
      },
    };
  },
  methods: {
    ContinueJD(jobDescription: any){
      this.isJdSearch = true;
      this.JobDescriptionByJdSearch = jobDescription;
      (this.$refs.stepper as any).next();
    },
    inviteTeammember(event: any){
      this.isJdSearch = false;
      this.selectedTM = event;
      (this.$refs.stepper as any).next();
    },
    saveOfficeLocation(event: any) {
      this.$emit("saveLocation", event);
    },
    backStep() {
      (this.$refs.stepper as any).prev();
    },
    nextStep() {
      (this.$refs.stepper as any).next();
    },
    updateCommitmentDetails(event: any) {
      this.model.StartDate = event.StartDate;
      this.model.EndDate = event.EndDate;
      this.model.HoursPerWeekMax = Number(event.HoursPerWeek);
      this.model.HoursPerWeekMin = 0;
      this.model.HoursPerWeekRadio = event.HoursPerWeekRadio;
      this.model.PublicHolidayZoneId = event.PublicHolidayZoneId;
      this.model.ProjectLeadId = event.ProjectLeadId;
      this.model.ProjectIds = [event.ProjectId];
      this.model.TeamMemberIds = [event.TeamMemberId];
      this.model.PortfolioManagerId = event.PortfolioManagerId
      this.nextStep();
    },
    updateLocationTypeDetails(event: any) {
      this.model.LocationType = event.LocationType;
      this.model.TimeZone = event.TimeZone;
      this.model.StartWorkClock = event.From;
      this.model.EndWorkClock = event.To;
      this.model.WokingDays = event.WorkingDays;
      this.model.DaysOfWeekInOffice = event.WorkingDaysInOffice;
      this.model.OfficeDaysPerWeek = event.NoOfficeDaysPerWeek;
      this.model.OfficeLocationId = event.OfficeLocationId;
      this.nextStep();
    },
    async saveCommitment(addonsModel: any) {
      this.loading = true;
      this.model.TimesheetProcessing = addonsModel.timeSheetProcessing.Enabled;
      if (addonsModel.virtualDesktop.Enabled) {
        this.model.VirtualDesktopSpec = addonsModel.virtualDesktop;
        this.model.VirtualDesktopRate = 10;
      } else {
        this.model.VirtualDesktopSpec = null;
        this.model.VirtualDesktopRate = null;
      }
      if (addonsModel.lineManager.Enabled) {
        this.model.LineManagerId = addonsModel.lineManager.LineManagerId;
      } else {
        this.model.LineManagerId = null;
      }
      if (addonsModel.payRoll.Enabled) {
        if (addonsModel.payRoll.Salary == "Hourly") {
          this.model.SeniorityLevel = addonsModel.payRoll.Amount;
          this.model.SalaryMonthly = 0;
          this.model.SalaryCurrency = addonsModel.payRoll.Currency;
        } else if (addonsModel.payRoll.Salary == "Monthly") {
          this.model.SeniorityLevel = 0;
          this.model.SalaryMonthly = addonsModel.payRoll.Amount;
          this.model.SalaryCurrency = addonsModel.payRoll.Currency;
        }
      } else {
        this.model.SeniorityLevel = 0;
        this.model.SalaryMonthly = null;
        this.model.SalaryCurrency = null;
      }
      if (addonsModel.contract.Enabled) {
        this.model.ContactVerification =
          addonsModel.contract.ContactVerification;
        this.model.ContractManagement = addonsModel.contract.ContractManagement;
        this.model.IDVerification = addonsModel.contract.IDVerification;
      } else {
        this.model.ContactVerification = false;
        this.model.ContractManagement = false;
        this.model.IDVerification = false;
      }

      if (addonsModel.officeSpace.Enabled) {
        this.model.OfficeSpaceId = addonsModel.officeSpace.officeSpaceId;
      } else {
        this.model.OfficeSpaceId = null;
      }
      this.model.PositionId = this.positionId;
      if (this.model.OfficeLocationId == "OfficeSpace") {
        this.model.OfficeLocationId = null;
      }
      if(!this.isJdSearch){
        const result = await CommitmentService.createCommitment(this.model);
        this.loading = false;
        if (result.id) {
          this.$emit("AssignTmToPosition", result);
        }
      }else {
        const positionDetailRequest = {
          jobDescription: this.JobDescriptionByJdSearch,
          positionDetails: {
            contactVerification: this.model.ContactVerification ?? undefined,
            contractManagement: this.model.ContractManagement ?? undefined,
            daysOfWeekInOffice: this.model.DaysOfWeekInOffice ?? undefined,
            endDate: this.model.EndDate ?? undefined,
            endWorkClock: this.model.EndWorkClock ?? undefined,
            hoursPerWeek: this.model.HoursPerWeekMax ?? undefined,
            hoursPerWeekCasual: this.model.HoursPerWeekRadio ?? undefined,
            idVerification: this.model.IDVerification ?? undefined,
            lineManagerId: this.model.LineManagerId ?? undefined,
            locationType: this.model.LocationType ?? undefined,
            officeDaysPerWeek: this.model.OfficeDaysPerWeek ?? undefined,
            officeLocationId: this.model.OfficeLocationId ?? undefined,
            projectId: this.model.PositionId ?? undefined,
            projectLeadId: this.model.ProjectLeadId ?? undefined,
            publicHolidayZoneId: this.model.PublicHolidayZoneId ?? undefined,
            salaryCurrency: this.model.SalaryCurrency ?? undefined,
            salaryMonthly: this.model.SalaryMonthly ?? undefined,
            seniorityLevel: this.model.SeniorityLevel ?? undefined,
            startDate: this.model.StartDate ?? undefined,
            startWorkClock: this.model.StartWorkClock ?? undefined,
            timeZone: this.model.TimeZone ?? undefined,
            virtualDesktopSpec: this.model.VirtualDesktopSpec ?? undefined,
            workingDays: this.model.WokingDays ?? undefined
          } as PositionDetailsModel
        } as PositionJDAndDetailsRequestModel
        const response = await PositionService.SavePositionDetails(this.positionId, positionDetailRequest)
        this.loading = false;
        if (response) {
          this.$emit("UpdatePositionDetail", this.JobDescriptionByJdSearch);
        }
      }
      
    },
    viewTmDetails(event: any) {
      this.selectedTM = event;
      this.showTMDetails = true;
    },
    assignTmToPosition(event: any) {
      this.isJdSearch = false;
      this.showTMDetails = false;
      (this.$refs.stepper as any).next();
    },
    closeDialog() {
      this.$emit("OnClose");
    },
  },
  computed: {
    getPostionJD() {
      return this.project.Positions.find((item: any) => item.Id == this.positionId).JobDescription
    },
    selectedStartDate: {
      get(): string | null {
        return this.model.StartDate
          ? Utils.toVsDateFormat(this.model.StartDate)
          : null;
      },
      set(date: string): void {
        this.model.StartDate = Utils.vsDateToDatetime(date || "");
      },
    },
    selectedEndDate: {
      get(): string | null {
        return this.model.EndDate
          ? Utils.toVsDateFormat(this.model.EndDate)
          : null;
      },
      set(date: string): void {
        this.model.EndDate = Utils.vsDateToDatetime(date || "");
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.search-background {
  color: #000;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9999;
  color: white;
}
</style>
