import { AppState } from ".";
import { state } from "./state";

export const getters = {
  userHasAccessToClientPanel: (state: AppState) => {
    return (
      state.clientInfo &&
      state.clientInfo.roles &&
      (state.clientInfo.roles.includes("projectlead") ||
        state.clientInfo.roles.includes("portfoliomanager") ||
        state.clientInfo.roles.includes("tdm") ||
        state.clientInfo.roles.includes("client"))
    );
  },
  userIsProjectLead: (state: AppState) => {
    return (
      state.clientInfo &&
      state.clientInfo.roles &&
      state.clientInfo.roles.includes("projectlead")
    );
  },
  userIsPortfolioManager: (state: AppState) => {
    return (
      state.clientInfo &&
      state.clientInfo.roles &&
      state.clientInfo.roles.includes("portfoliomanager")
    );
  },
  userIsClient: (state: AppState) => {
    return (
      state.clientInfo &&
      state.clientInfo.roles &&
      state.clientInfo.roles.includes("client")
    );
  },
  userIsTDM: (state: AppState) => {
    return (
      state.clientInfo &&
      state.clientInfo.roles &&
      state.clientInfo.roles.includes("tdm")
    );
  },
  clientInfo: (state: AppState) => {
    return state.clientInfo;
  },
  activities: (state: AppState) => {
    return state.activities;
  },
  searchEmployeeCriteria: (state: AppState) => {
    return state.searchEmployeeCriteria;
  },
  showLoading: (state: AppState) => {
    return state.showLoading;
  },
  getSnakbars: (state: AppState) => {
    return state.snakbars;
  },
  getSnakbar: (state: AppState) => (id: any) => {
    return state.snakbars.find((s) => s.id === id);
  },
  profileDropdown: (state: AppState) => {
    return state.isProfileDropdownOpen;
  },
  notificationDropdown: (state: AppState) => {
    return state.isNotificationOpen;
  },
};
