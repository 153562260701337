<template>
  <div class="h-100">
    <v-navigation-drawer
      dark
      fixed
      :temporary="drawerPermanent"
      :permanent="drawerPermanent"
      :mini-variant="drawerMini"
      v-model="drawerIsOpen"
      app
      clipped-top
      class="app-drawer"
      width="220"
    >
      <div class="logo-container" :class="{ 'logo-container--mini': mini }">
        <img
          v-if="!mini"
          class="logo"
          src="@/assets/images/code-clan-logo.svg"
        />
      </div>
      <NavigationMenu
        :isAuthenticated="isAuthenticated"
        :isProjectLead="isProjectLead"
        :isPortfolioManager="isPortfolioManager"
        :isClient="isClient"
        :isTDM="isTDM"
      />
    </v-navigation-drawer>
    <v-app-bar app color="primary" class="appBarComponent">
      <v-app-bar-nav-icon
        class="hamburgur-toggler ml-3"
        @click="toggleDrawerOpen"
      />
      <HeaderTitle />
      <ProfileDropdown />
      
    </v-app-bar>
    <v-main>
      <v-container fluid class="pages-container">
        <transition name="slide-y-transition" mode="out-in">
          <router-view />
        </transition>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavigationMenu from "@/components/NavigationMenu.vue";
import ProfileDropdown from "@/components/User/ProfileDropdown.vue";
import HeaderTitle from "@/components/User/HeaderTitle.vue";
import { mapGetters, useStore } from "vuex";
import store from "@/store";
import ActivityService from "@/services/ActivityService";

export default defineComponent({
  components: {
    NavigationMenu,
    ProfileDropdown,
    HeaderTitle,
  },
  data() {
    return {
      mini: false,
      submitting: false,
    };
  },
  async mounted() {
    var activities = await ActivityService.getActivities();
    store.dispatch("setActivities", { activities, vm: this });
  },
  computed: {
    ...mapGetters({ clientInfo: "clientInfo", activities: "activities" }),
    showLoading(): boolean {
      return store.state.showLoading;
    },
    isAuthenticated(): boolean {
      return store.getters.userHasAccessToClientPanel;
    },
    isProjectLead(): boolean {
      return store.getters.userIsProjectLead;
    },
    isPortfolioManager(): boolean {
      return store.getters.userIsPortfolioManager;
    },
    isTDM(): boolean {
      return store.getters.userIsTDM;
    },
    isClient(): boolean {
      return store.getters.userIsClient;
    },
    drawerIsOpen: {
      get(): boolean {
        return store.state.drawerIsOpen;
      },
      set(newValue: boolean) {
        this.handleDrawerOpen(newValue);
      },
    },
    drawerPermanent(): boolean {
      return (
        this.isAuthenticated &&
        (this.$vuetify.display.name === "lg" ||
          this.$vuetify.display.name === "xl" ||
          this.$vuetify.display.name === "md")
      );
    },
    drawerMini(): boolean {
      return this.drawerPermanent ? this.mini : false;
    },
  },
  methods: {
    closeQuickLoginDialog(): any {
      const storeObject = useStore();
      return store.dispatch("closeQuickLoginDialog");
    },
    toggleDrawerOpen() {
      return store.dispatch("toggleDrawerOpen");
    },
    handleDrawerOpen(value: boolean) {
      return store.dispatch("handleDrawerOpen", value);
    },
    onSubmitting(value: any) {
      this.submitting = value;
    },
  },
});
</script>
<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
.logo-container {
  height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $racecar;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  .toggler-icon {
    width: 24px;
    height: 24px;
  }
  .logo {
    margin: auto;
    height: auto;
    width: 80px;
  }
}
.logo-container--mini {
  width: 56px;
}
.app-drawer {
  background-color: $dark_gray;
  background-repeat: no-repeat;
  background-size: auto;
}
.hamburgur-toggler {
  color: $dark_gray;
}
.pages-container {
  min-height: 340px;
  height: 100%;
}

@media (max-width: 960px) {
  .pages-container {
    padding: 15px 20px;
  }
}
@media only screen and (max-width: 600px) {
  .app-drawer {
    z-index: 3000 !important;
  }

  .notification-drp {
    right: 50px;
  }

  .custom-menu-style {
    width: 368px;
  }
}
</style>
