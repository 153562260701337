<template>
  <v-list nav class="main-menu-items">
    <v-list-item to="/client/dashboard" prepend-icon="mdi-view-dashboard" v-if="!isTDM">
      <v-list-item-content>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      to="/client/projectLeadreport"
      v-if="isProjectLead"
      prepend-icon="mdi-chart-arc"
    >
      <v-list-item-content>
        <v-list-item-title>Project Lead Reports</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      to="/client/portFolioManagerReport"
      v-if="isPortfolioManager"
      prepend-icon="mdi-file-tree"
    >
      <v-list-item-content>
        <v-list-item-title>Portfolio Manager Reports</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item to="/client/myProjects" prepend-icon="mdi-file-multiple" v-if="!isTDM">
      <v-list-item-content>
        <v-list-item-title>My Projects</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group class="dashboard-menu">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" prepend-icon="mdi-book-education"
          >Training</v-list-item
        >
      </template>

      <v-list-item
        to="/client/training/roadMap"
        title="Training RoadMaps"
        prepend-icon="mdi-account-school"
        value="RoadMap"
      >
      </v-list-item>
      <v-list-item
        to="/client/training/courses"
        title="Courses"
        prepend-icon="mdi-school"
        value="Courses"
      >
      </v-list-item>
      <v-list-item
        to="/client/training/assessment"
        title="Assessments"
        prepend-icon="mdi-book-clock"
        value="Assessment"
      >
      </v-list-item>
    </v-list-group>
    <v-list-item
      to="/client/search/wizard"
      v-if="isPortfolioManager && false"
      prepend-icon="mdi-file-tree"
    >
      <v-list-item-content>
        <v-list-item-title>Search</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      to="/client/project"
      v-if="isPortfolioManager || isClient"
      prepend-icon="mdi-file-tree"
    >
      <v-list-item-content>
        <v-list-item-title>Projects</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-list-item
      to="/client/virtualDesktop"
      v-if="isPortfolioManager || isClient"
      prepend-icon="mdi-laptop-account"
    >
      <v-list-item-content>
        <v-list-item-title>Virtual Desktop</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
  </v-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["isProjectLead", "isPortfolioManager", "isTDM", "isClient"],
});
</script>
<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
a {
  color: $track_lane;
}
</style>
