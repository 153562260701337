<template>
  <div class="filter-container">
    <div class="sticky-navbar" :style="{ top: top }" @click.stop>
      <v-row class="default-section align-center">
        <v-col cols="12">
          <div class="part">
            <v-btn
              elevation="24"
              v-if="ShowBackBtn"
              @click="handelBackBtn"
              icon="mdi-arrow-left"
              density="compact"
            ></v-btn>
            <v-btn @click="toggleFilter" class="filter-button"> Filter </v-btn>
            <div class="flex-1-1">
              <TextField
                disableDetails
                v-model="searchCategory"
                @keyup.enter="searchHandler"
                placeholder="Category"
                append-inner-icon="mdi-magnify"
                @click:append-inner="searchHandler"
              />
            </div>
            <v-btn
              @click="toggleNewCourse"
              class="filter-button"
              prepend-icon="mdi-plus"
              stacked
              v-if="showCoursBtn"
              >new Course</v-btn
            >
          </div>
        </v-col>
        <v-col
          cols="12"
          v-if="
            criteria.selectedSkill.length > 0 ||
            criteria.selectedTime ||
            criteria.selectedLevel.length > 0
          "
        >
          <v-chip
            @click:close="closeSkillChip(index)"
            closable
            v-for="(skill, index) in criteria.selectedSkill"
            :key="index"
            class="mr-2"
            :color="backgrounds.skill"
          >
            Skill: {{ getSkillName(skill) }}
          </v-chip>
          <v-chip
            @click:close="closeTimeChip"
            closable
            v-if="criteria.selectedTime"
            class="mr-2"
            :color="backgrounds.duration"
          >
            Duration: Between
            {{ (criteria.selectedTime as any).split(",")[0] }}h -
            {{ (criteria.selectedTime as any).split(",")[1] }}h
          </v-chip>
          <v-chip
            @click:close="closeLevelChip(index)"
            v-for="(skill, index) in criteria.selectedLevel"
            :key="index"
            closable
            class="mr-2"
            :color="backgrounds.level"
          >
            Level: {{ skill }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row v-if="showFilter" class="filter">
        <v-form class="w-100">
          <v-row>
            <v-col cols="12">
              <AutoCompleteField
                clearable
                chips
                label="Select your skill:"
                :items="skills"
                item-title="Text"
                item-value="id"
                multiple
                v-model="criteria.selectedSkill"
                hide-details
                closable-chips
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <SelectField
                label="Select the time:"
                :items="filterData.duration"
                v-model="criteria.selectedTime"
                item-title="Text"
                item-value="Value"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <SelectField
                label="Select your Level:"
                :items="filterData.level"
                v-model="criteria.selectedLevel"
                item-title="Text"
                item-value="Value"
                clearable
                chips
                multiple
                hide-details
                closable-chips
              />
            </v-col>
          </v-row>
        </v-form>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import CoreSkillService from "../../../services/CoreSkillService";
import CoreSkill from "shared-components/src/models/CoreSkill";

export default defineComponent({
  props: {
    ShowBackBtn: {
      type: Boolean,
      default: true,
    },
    showCoursBtn: {
      type: Boolean,
      default: true,
    },
    top: {
      type: String,
      default: "1px",
    },
    height: {
      type: String,
      default: "610px",
    },
    padding: {
      type: String,
      default: "40px",
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    skills: {
      type: Array as () => CoreSkill[],
      default: [],
    },
    clickOnOverlay: Function as PropType<(() => void) | any>,
  },
  data() {
    return {
      searchCategory: "",
      criteria: {
        selectedSkill: [] as string[],
        selectedLevel: [] as string[],
        selectedTime: null,
      },
      filterVisible: false,
      filterData: {
        duration: [
          { Text: "Less than 5h", Value: "0,5" },
          { Text: "Between 5h and 10h", Value: "5,10" },
          { Text: "more than 10h", Value: "10,9999" },
        ],
        level: [
          { Text: "Junior", Value: "Junior" },
          { Text: "MidLevel", Value: "MidLevel" },
          { Text: "Senior", Value: "Senior" },
        ],
      },
      backgrounds: {
        skill: "#0bffeb",
        duration: "#ccdb04",
        level: "#7fe300",
      },
    };
  },
  methods: {
    getSkillName(id: string) {
      return this.skills.filter((item) => item.id == id)[0].Text;
    },
    toggleFilter() {
      this.$emit("showOverlay", !this.showFilter);
    },
    handelBackBtn() {
      this.$emit("backBtnClicked", true);
    },
    searchHandler() {
      this.$emit("doSearch", {
        duration: this.criteria.selectedTime
          ? (this.criteria.selectedTime as any)
              .split(",")
              .map((item: any) => Number(item))
          : null,
        level: this.criteria.selectedLevel,
        skill: this.criteria.selectedSkill,
        text: this.searchCategory,
      });
    },
    closeSkillChip(index: number) {
      const levels = [...this.criteria.selectedSkill];
      levels.splice(index, 1);
      this.criteria.selectedSkill = [];
      setTimeout(() => {
        this.criteria.selectedSkill = levels;
      }, 5);
    },
    closeLevelChip(index: number) {
      const levels = [...this.criteria.selectedLevel];
      levels.splice(index, 1);
      this.criteria.selectedLevel = [];
      setTimeout(() => {
        this.criteria.selectedLevel = levels;
      }, 5);
    },
    closeTimeChip() {
      this.criteria.selectedTime = null;
    },
    toggleNewCourse() {
      this.$router.push("/client/training/courses/new");
    },
  },
  watch: {
    criteria: {
      handler(val) {
        this.searchHandler();
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";

.filter-container {
  position: absolute;
  height: 100%;
  width: 100%;

  .default-section {
    background-color: $tarmac;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .filter-button {
    background-color: $racecar;
    height: 50px !important;
  }

  .sticky-navbar {
    position: sticky;
    z-index: 2001;
  }

  .filter {
    position: absolute;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 20%;
    min-width: 210px;
    background-color: $tarmac;
    color: $track_lane;
    overflow-y: auto;
    z-index: 2100;
  }
  .submit {
    display: flex;
    justify-content: center;
    padding-left: 33px;
    padding-top: 0px;
  }
  .filter-items {
    margin-right: 4px;
  }
  .part {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .filter {
    padding-bottom: 20px;
    height: 440px !important;
  }
}
</style>
