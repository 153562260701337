<template>
  <v-form ref="frmRctMain" lazy-validation>
    <v-card>
      <v-card-title> Send Comment </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" sm="6">
            <h5>Select Comment Type</h5>
            <v-radio-group v-model="reactType" :rules="[rules.required]">
              <v-radio
                label="Feedback"
                :value="notificationTypes.FeedBack"
              ></v-radio>
              <v-radio label="Kudos" :value="notificationTypes.Kudos"></v-radio>
              <v-radio label="Tip" :value="notificationTypes.Tip"></v-radio>
              <v-radio
                label="Message"
                :value="notificationTypes.Info"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="12" sm="6">
            <h5>Select Comment Target</h5>
            <v-radio-group
              v-model="reactTarget"
              :rules="[rules.required]"
              inline
            >
              <v-radio label="TM" value="TM"></v-radio>
              <v-radio label="LM" value="LM"></v-radio>
              <v-radio label="SME" value="SME" :disabled="true"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="12" sm="6">
            <TextAreaField
              :rules="[rules.required]"
              label="Message"
              type="text"
              v-model="message"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-0">
          <v-col cols="12" md="12">
            <v-btn
              class="primary right"
              :loading="loading"
              :disabled="loading"
              @click="confirm"
              ><v-icon>mdi-check</v-icon>Send Reaction</v-btn
            >
            <v-btn
              class="secondary right mr-5"
              :disabled="loading"
              @click="cancel"
              ><v-icon>mdi-cancel</v-icon>Cancel</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import NotificationService from "../../services/NotificationService";
import { ClientTimesheetReactionRequest } from "shared-components/src/models/Notification";
import {
  NotificationType,
  NotificationSection,
} from "shared-components/src/definitions/constants";

export default defineComponent({
  props: {
    timesheetId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      notificationTypes: NotificationType,
      reactType: "",
      reactTarget: "",
      message: "",
      loading: false,
      rules: {
        required: (value: any) =>
          (!(value instanceof Array) && !!value) ||
          (value instanceof Array && value.length > 0) ||
          "Required.",
      },
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      const isValid = await (this.$refs.frmRctMain as any).validate();
      if (isValid.valid) {
        this.loading = true;
        NotificationService.ClientTimesheetReaction({
          type: this.reactType,
          message: this.message,
          timesheetId: this.timesheetId,
          creatorUserId: "",
          target: this.reactTarget,
        } as ClientTimesheetReactionRequest)
          .then((result) => {
            if (!result.status) {
              this.$emit("errorRaised", result.message);
            } else {
              this.$emit("successRaised", result.message);
            }
            this.$emit("saved");
          })
          .catch((error) => {
            this.$emit("errorRaised", error.response.data);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  async mounted() {},
});
</script>
<style lang="scss" scoped>
.primary {
  color: #000 !important;
}
</style>
