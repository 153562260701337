import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "step-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PriceCalculator = _resolveComponent("PriceCalculator")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_VirtualDesktopAddon = _resolveComponent("VirtualDesktopAddon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_DateInputField = _resolveComponent("DateInputField")!
  const _component_ComboBoxField = _resolveComponent("ComboBoxField")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_SelectField = _resolveComponent("SelectField")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_stepper = _resolveComponent("v-stepper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PriceCalculator, {
      commitmentModel: _ctx.model,
      isTotal: true
    }, null, 8, ["commitmentModel"]),
    _createVNode(_component_v_stepper, {
      items: ['Virtual Desktop', 'Timesheet Management'],
      modelValue: _ctx.stepState,
      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.stepState) = $event))
    }, {
      "item.1": _withCtx(() => [
        _createVNode(_component_v_form, {
          ref: "frmVD",
          modelValue: _ctx.vdValid,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.vdValid) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              flat: "",
              class: "mt-2",
              loading: _ctx.loading,
              disabled: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode("Virtual Desktop Configuration")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_VirtualDesktopAddon, {
                      virtualDesktopSpec: _ctx.model.VirtualDesktopSpec,
                      diskValues: _ctx.diskValues,
                      cpuValues: _ctx.cpuValues,
                      ramValues: _ctx.ramValues,
                      showEnableCheckBox: false,
                      fullScreen: true
                    }, null, 8, ["virtualDesktopSpec", "diskValues", "cpuValues", "ramValues"]),
                    _createVNode(_component_PriceCalculator, { commitmentModel: _ctx.model }, null, 8, ["commitmentModel"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["loading", "disabled"]),
            _createVNode(_component_v_card, {
              flat: "",
              class: "mt-2",
              loading: _ctx.loading,
              disabled: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("Team Member details")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              label: "Job Role",
                              "item-title": "Name",
                              "item-value": "id",
                              rules: [_ctx.rules.required],
                              items: _ctx.getJobRoles,
                              modelValue: _ctx.model.JobRoleId,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.JobRoleId) = $event)),
                              outlined: ""
                            }, null, 8, ["rules", "items", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextField, {
                              rules: [_ctx.rules.EmailCheck],
                              label: "Email",
                              modelValue: _ctx.model.TmEmail,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.TmEmail) = $event)),
                              onBlur: _ctx.validateTeammember
                            }, null, 8, ["rules", "modelValue", "onBlur"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              rules: [_ctx.tmDetailsDisabled || _ctx.rules.required],
                              label: "Country of Residence",
                              "item-title": "text",
                              "item-value": "text",
                              items: _ctx.locations,
                              modelValue: _ctx.model.TmCountry,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.TmCountry) = $event)),
                              disabled: _ctx.tmDetailsDisabled
                            }, null, 8, ["rules", "items", "modelValue", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextField, {
                              rules: [_ctx.tmDetailsDisabled || _ctx.rules.required],
                              label: "First Name",
                              modelValue: _ctx.model.TmFirstName,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.TmFirstName) = $event)),
                              disabled: _ctx.tmDetailsDisabled
                            }, null, 8, ["rules", "modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextField, {
                              rules: [_ctx.tmDetailsDisabled || _ctx.rules.required],
                              label: "Last Name",
                              modelValue: _ctx.model.TmLastName,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.TmLastName) = $event)),
                              disabled: _ctx.tmDetailsDisabled
                            }, null, 8, ["rules", "modelValue", "disabled"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextField, {
                              disabled: _ctx.tmDetailsDisabled,
                              rules: [_ctx.tmDetailsDisabled || _ctx.rules.phoneNumber],
                              label: "Mobile Number",
                              modelValue: _ctx.model.TmMobile,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.TmMobile) = $event))
                            }, null, 8, ["disabled", "rules", "modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["loading", "disabled"]),
            _createVNode(_component_v_card, {
              flat: "",
              class: "mt-2",
              loading: _ctx.loading,
              disabled: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode("Engagement Details")
                  ])),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DateInputField, {
                              label: "Start date",
                              rules: [_ctx.rules.required],
                              "first-day-of-week": "1",
                              modelValue: _ctx.model.CommitmentStartDate,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.CommitmentStartDate) = $event)),
                              "hide-actions": "",
                              placeholder: "Start date",
                              "prepend-icon": "",
                              min: _ctx.getTodayDate
                            }, null, 8, ["rules", "modelValue", "min"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DateInputField, {
                              label: "End date",
                              rules: [_ctx.rules.required],
                              "first-day-of-week": "1",
                              modelValue: _ctx.model.CommitmentEndDate,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.CommitmentEndDate) = $event)),
                              "hide-actions": "",
                              placeholder: "End date",
                              "prepend-icon": "",
                              min: _ctx.getMinDate
                            }, null, 8, ["rules", "modelValue", "min"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ComboBoxField, {
                              rules: [_ctx.rules.required],
                              label: "Project/Team Name",
                              items: _ctx.projects,
                              "item-title": "Name",
                              "item-value": "Name",
                              modelValue: _ctx.model.ProjectName,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.ProjectName) = $event)),
                              "return-object": false
                            }, null, 8, ["rules", "items", "modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      "item.2": _withCtx(() => [
        _createVNode(_component_v_card, {
          flat: "",
          loading: _ctx.loading,
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  ref: "frmTimesheet",
                  modelValue: _ctx.timesheetValid,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.timesheetValid) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "align-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "12" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_checkbox, {
                              label: "Enable Timesheet Management",
                              modelValue: _ctx.model.EnableTimesheetProcessing,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.EnableTimesheetProcessing) = $event)),
                              onChange: _ctx.EnableTimesheetChange
                            }, null, 8, ["modelValue", "onChange"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              disabled: !_ctx.model.EnableTimesheetProcessing,
                              rules: [_ctx.rules.required],
                              label: "Project Lead",
                              items: _ctx.projectLeads,
                              "item-title": "Name",
                              "item-value": "id",
                              modelValue: _ctx.model.CommitmentProjectLeadId,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.CommitmentProjectLeadId) = $event)),
                              "append-icon": "mdi-plus"
                            }, null, 8, ["disabled", "rules", "items", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              disabled: !_ctx.model.EnableTimesheetProcessing || !_ctx.isClient,
                              rules: [!_ctx.isClient || _ctx.rules.required],
                              label: "Portfolio Manager",
                              items: _ctx.portfolioManagers,
                              "item-title": "Name",
                              "item-value": "id",
                              modelValue: _ctx.model.CommitmentPortfolioManagerId,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.CommitmentPortfolioManagerId) = $event)),
                              "append-icon": "mdi-plus"
                            }, null, 8, ["disabled", "rules", "items", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_SelectField, {
                              disabled: !_ctx.model.EnableTimesheetProcessing,
                              rules: [_ctx.rules.required],
                              label: "Public Holiday Zone",
                              items: _ctx.publicHolidayZones,
                              "item-title": "Name",
                              "item-value": "id",
                              modelValue: _ctx.model.CommitmentPublicHolidayZoneId,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.CommitmentPublicHolidayZoneId) = $event))
                            }, null, 8, ["disabled", "rules", "items", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              disabled: !_ctx.model.EnableTimesheetProcessing,
                              rules: [_ctx.rules.required],
                              label: "Timezone",
                              modelValue: _ctx.model.CommitmentTimeZone,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.CommitmentTimeZone) = $event)),
                              items: _ctx.timeZones,
                              outlined: "",
                              "return-object": false
                            }, null, 8, ["disabled", "rules", "modelValue", "items"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              disabled: !_ctx.model.EnableTimesheetProcessing,
                              rules: [_ctx.rules.required],
                              label: "Days of Week",
                              modelValue: _ctx.model.CommitmentWorkingDays,
                              "onUpdate:modelValue": [
                                _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.CommitmentWorkingDays) = $event)),
                                _ctx.DayOfWeekChanged
                              ],
                              items: _ctx.workingDays,
                              multiple: "",
                              outlined: "",
                              "return-object": false
                            }, null, 8, ["disabled", "rules", "modelValue", "items", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              disabled: !_ctx.model.EnableTimesheetProcessing,
                              rules: [_ctx.rules.required],
                              label: "From",
                              modelValue: _ctx.model.CommitmentFrom,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.CommitmentFrom) = $event)),
                              items: _ctx.hoursInDay
                            }, null, 8, ["disabled", "rules", "modelValue", "items"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AutoCompleteField, {
                              disabled: !_ctx.model.EnableTimesheetProcessing,
                              rules: [_ctx.rules.required],
                              label: "To",
                              modelValue: _ctx.model.CommitmentTo,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.CommitmentTo) = $event)),
                              items: _ctx.hoursInDay
                            }, null, 8, ["disabled", "rules", "modelValue", "items"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "3" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TextField, {
                              disabled: !_ctx.model.EnableTimesheetProcessing,
                              label: "Expected work hours per week",
                              rules: [
                      _ctx.model.CommitmentHoursPerWeekRadio ||
                        _ctx.rules.minNumber(1) ||
                        _ctx.rules.required,
                    ],
                              type: "number",
                              modelValue: _ctx.model.CommitmentHoursPerWeek,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.CommitmentHoursPerWeek) = $event)),
                              readonly: _ctx.model.CommitmentHoursPerWeekRadio,
                              min: 1
                            }, null, 8, ["disabled", "rules", "modelValue", "readonly"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, { cols: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_checkbox, {
                              disabled: !_ctx.model.EnableTimesheetProcessing,
                              label: "Casual",
                              "hide-details": "",
                              modelValue: _ctx.model.CommitmentHoursPerWeekRadio,
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.CommitmentHoursPerWeekRadio) = $event)),
                              onChange: _ctx.CasualChange
                            }, null, 8, ["disabled", "modelValue", "onChange"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_PriceCalculator, {
                  commitmentModel: _ctx.model,
                  isTimesheetScreen: true
                }, null, 8, ["commitmentModel"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading", "disabled"])
      ]),
      "item.3": _withCtx(() => [
        _createVNode(_component_v_card, { flat: "" }, {
          default: _withCtx(() => _cache[25] || (_cache[25] = [
            _createTextVNode("...")
          ])),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_v_card_actions, { class: "justify-center" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_v_btn, {
                class: "secondary_btn",
                prependIcon: "mdi-arrow-left",
                disabled: !_ctx.canBack || _ctx.loading,
                loading: _ctx.loading,
                onClick: _ctx.perviousStep
              }, {
                default: _withCtx(() => _cache[26] || (_cache[26] = [
                  _createTextVNode("Pervious")
                ])),
                _: 1
              }, 8, ["disabled", "loading", "onClick"]),
              (_ctx.stepState != 2)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    class: "primary_btn",
                    appendIcon: "mdi-arrow-right",
                    onClick: _ctx.nextStep,
                    loading: _ctx.loading,
                    disabled: _ctx.loading
                  }, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode("Next (Timesheet)")
                    ])),
                    _: 1
                  }, 8, ["onClick", "loading", "disabled"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_btn, {
                class: "primary_btn",
                prependIcon: "mdi-content-save",
                onClick: _ctx.createVm,
                loading: _ctx.loading,
                disabled: _ctx.loading
              }, {
                default: _withCtx(() => _cache[28] || (_cache[28] = [
                  _createTextVNode("Create")
                ])),
                _: 1
              }, 8, ["onClick", "loading", "disabled"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}