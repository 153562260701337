<template>
  <v-row  v-if="avatars && avatars.length > 0" no-gutters class="align-center">
    <v-col :cols="showActionIcon ? 11 : 12">
      <section class="avatars-group pa-3 pt-0 stacked" :style="{'justify-content': justify}">
        <div
          :class="
            avatar.isClicked ? 'selectedAvatar ' + touchClass + '' : touchClass
          "
          class="avatars-group__item"
          v-for="avatar in avatars"
          :key="`avatar-id-${avatar.id}`"
        >
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-avatar
                :class="
                  avatar.isClicked
                    ? 'selectedAvatarIcon ' + getStatus(avatar.status) + ''
                    : getStatus(avatar.status)
                "
                v-if="avatar.id"
                @click="getTeammemberEpic(avatar.id)"
                v-bind="props"
                :color="!avatar.photoUrl ? GetColour(avatar.fullName) : null"
              >
                <slot></slot>
                <img
                  class="pa-1 profile-photo"
                  v-if="avatar.photoUrl"
                  :src="avatar.photoUrl"
                  :alt="avatar.fullName"
                />
                <span v-else class="text_white">
                  {{ GetInitials(avatar.fullName) }}
                </span>
              </v-avatar>
              <v-avatar
                :class="avatar.isClicked ? 'selectedAvatarIcon' : ''"
                v-else
                @click="getTeammemberEpic(avatar.id)"
                v-bind="props"
                :color="GetColour(avatar.fullName)"
              >
                <slot></slot>
                <span class="text_white">
                  {{ avatar.fullName }}
                </span>
              </v-avatar>
            </template>
            <span>{{ avatar.fullName }}</span>
          </v-tooltip>
        </div>

        <div v-if="showMore && hasMore && avatars && avatars.length > 0" class="avatars-group__item">
          <v-avatar>
            <v-menu v-model="menu" :close-on-content-click="false" location="end">
              <template v-slot:activator="{ props }">
                <v-btn color="indigo" v-bind="props" icon="mdi-dots-horizontal">
                </v-btn>
              </template>

              <v-card class="avatarMenu">
                <v-list>
                  <v-list-item
                    v-for="avatar in avatars"
                    :key="`avatar-menu-id-${avatar.id}`"
                  >
                    <v-row
                      :class="avatar.isClicked ? 'selectedAvatarListItem' : ''"
                      class="avatar-moreList"
                      @click.stop="getTeammemberEpic(avatar.id)"
                    >
                      <v-col md="2">
                        <v-list-item-avatar>
                          <v-avatar
                            :class="avatar.id ? getStatus(avatar.status) : ''"
                            :color="
                              !avatar.photoUrl ? GetColour(avatar.fullName) : null
                            "
                          >
                            <slot></slot>
                            <img
                              class="pa-1 profile-photo"
                              v-if="avatar.photoUrl"
                              :src="avatar.photoUrl"
                              :alt="avatar.fullName"
                            />
                            <span v-else class="text_white">
                              {{ GetInitials(avatar.fullName) }}
                            </span>
                          </v-avatar>
                        </v-list-item-avatar>
                      </v-col>
                      <v-col class="moreList ml-0">
                        <v-list-item-content>
                          <v-list-item-title class="pl-2">{{
                            avatar.fullName
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-col>
                      <v-divider></v-divider>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-avatar>
        </div>
      </section>
    </v-col>
    <v-col cols="1" v-if="showActionIcon">
      <v-menu v-model="actionMenu" location="bottom" scroll-strategy="close">
        <template v-slot:activator="{ props }">
          <v-btn variant="text" v-bind="props" icon="mdi-dots-vertical"/>
        </template>
        <v-card min-width="300">
          <v-list>
            <v-list-item link title="Add Position" @click="showNewPosition = true" />
            <v-list-group value="Positions" v-if="project.Positions && project.Positions.length > 0">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  title="Positions"
                ></v-list-item>
              </template>
              <v-list-item v-for="position in project.Positions" link :title="getPositionTitle(position)" @click="showPositionMenu(position.Id)"/>
            </v-list-group>
            <v-list-group value="Teammembers" v-if="users && users.length > 0">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  title="Teammembers"
                ></v-list-item>
              </template>
              <div>
                
              </div>
              <v-list-item class="customPrepend" v-for="user in users" link :title="user.fullName" @click="showCommitmentMenu(user.commitmentId)">
                <template v-slot:prepend>
                  <v-avatar
                    v-if="user.id"
                    :color="!user.photoUrl ? GetColour(user.fullName) : null"
                  >
                  <slot></slot>
                    <img
                      class="pa-1 profile-photo"
                      v-if="user.photoUrl"
                      :src="user.photoUrl"
                      :alt="user.fullName"
                    />
                    <span v-else class="text_white">
                      {{ GetInitials(user.fullName) }}
                    </span>
                  </v-avatar>
                  <v-avatar
                    v-else
                    :color="GetColour(user.fullName)"
                  >
                    <slot></slot>
                    <span class="text_white">
                      {{ user.fullName }}
                    </span>
                  </v-avatar>
                </template>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>
    <v-dialog
      v-model="showNewPosition"
      persistent
      max-width="600px"
      @keydown.esc="showNewPosition = false"
    >
      <AddOrEditPositionDetails
        :isNew="true"
        :addLoading="addLoading"
        @cancel="showNewPosition = false"
        @success="AddNewProjectPosition"
        :details="details"
        :skills="skills"
        v-if="showNewPosition"
      />
    </v-dialog>
  </v-row>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { DashboardTeammember } from "shared-components/src/models/Dahboard";
import Infrastracture from "shared-components/src/utils/Infrastracture";
import AppHelper from "shared-components/src/utils/AppHelper";
import { Profile } from "shared-components/src/definitions/config";
import AddOrEditPositionDetails from "../../views/MyProjects/ProjectPosition/AddOrEditPositionDetails.vue";
import ProjectSearchWizard from "../../views/MyProjects/AddTeammember/ProjectSearchWizard.vue";
import PositionService from "@/services/PositionService";

export interface UiTeammember extends DashboardTeammember {
  isClicked: boolean | false;
}
export default defineComponent({
  props: [
    "users", 
    "projectId", 
    "justify", 
    "hasAll", 
    "hasMore",
    "showActionIcon", 
    "details", 
    "skills", 
    "project", 
  ],
  components: {
    AddOrEditPositionDetails,
    ProjectSearchWizard
  },
  data() {
    return {
      addLoading: false,
      dialogLoading: false,
      showSearchTeammember: false,
      showNewPosition: false,
      showUpdatePosition: false,
      selectedPositionId: "",
      fav: true,
      menu: false,
      actionMenu: false,
      message: false,
      showMore: false,
      hints: true,
      stackedLimit: Profile.Members.ClinetProfileLimit,
      menuMaxHeight: `${60 * 5 + 4}px`,
      stackedMenu: false,
      avatars: [] as UiTeammember[],
      touchClass: "",
    };
  },
  mounted() {
    this.touchClass = this.isTouchScreen === 0 ? "touchAvatar" : "";
    if (this.hasMore) {
      this.avatars =
        this.avatarsSorted && this.avatarsSorted.length > 0
          ? this.avatarsSorted.slice(0, this.stackedLimit)
          : null;
    } else {
      this.avatars = this.avatarsSorted;
    }
    if (this.hasAll && this.users) {
      if(!this.avatars){
        this.avatars = []
      }
      this.avatars.splice(0, 0, { id: "", fullName: "All" } as UiTeammember);
    }
    if (this.avatars && this.avatars.length <= this.stackedLimit) {
      this.showMore = false;
    } else this.showMore = true;
  },
  methods: {
    showPositionMenu(positionId: string){
      this.$emit("showPositionMenu", {positionId: positionId, projectId: this.projectId})
    },
    showCommitmentMenu(commitmentId: string){
      this.$emit("showCommitmentMenu", {commitmentId: commitmentId, projectId: this.projectId})
    },
    async AddNewProjectPosition(event: any) {
      this.addLoading = true;
      event.ProjectId = this.projectId;
      const result = await PositionService.SavePosition(event);
      if (this.project && !this.project?.Positions) {
        this.project.Positions = [];
      }
      this.project?.Positions.push(result);

      setTimeout(() => {
        this.addLoading = false;
        this.showNewPosition = false;
      }, 100);
    },
    showSearchTeammemberPopup(positionId: string){
      this.selectedPositionId = positionId;
      this.showSearchTeammember = true;
    },
    showUpdatePositionPopup(positionId: string){
      this.selectedPositionId = positionId;
      this.showUpdatePosition = true;
    },
    getJobRoleName(id: string){
      return this.details.find((item: any) => item.id == id).Name
    },
    getStatus(status: any) {
      return Infrastracture.getTeammemberCommitmentStatus(status);
    },
    getTeammemberEpic(memberId: string) {
      var item = this.avatars.find((c) => c.id === memberId);
      if (item) {
        item.isClicked = !item.isClicked;
        if (!item.id) {
          this.avatars.forEach((c) => {
            c.isClicked = item ? item.isClicked : false;
          });
        }
      }
      var memberIdArrays = this.avatars
        .filter((c) => c.isClicked)
        .map((c) => c.id);
      if (this.projectId) {
        this.$emit("selectUser", {
          projId: this.projectId,
          teammemberIds: memberIdArrays,
        });
      } else {
        this.$emit("selectUser", { memberIdArrays });
      }
    },
    GetColour(name: any) {
      return AppHelper.GetColour(name);
    },
    GetInitials(name: any) {
      return AppHelper.GetInitials(name);
    },
    getSeatsUnfilled(position: any){
      if(position.NoOfPositions && position.Commitments){
        return position.NoOfPositions - position.Commitments?.length;
      }
      return null;
    },
    getPositionTitle(position: any){
      const seatUnfilled = this.getSeatsUnfilled(position)
      if(seatUnfilled){
        return `${this.getJobRoleName(position.JobRoleId)} (${seatUnfilled} seats unfilled)`
      }
      return this.getJobRoleName(position.JobRoleId)
    }
  },
  computed: {
    isTouchScreen() {
      var supportsTouch = "ontouchstart" in window || navigator.maxTouchPoints;
      return supportsTouch;
    },
    avatarsSorted() {
      return this.users && this.users.length > 0
        ? this.users.filter((x: any) => x.isCommitmentActive == true).sort((a: any, b: any) =>
            a.fullName.localeCompare(b.fullName)
          )
        : null;
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";

.avatarMenu {
  min-width: 300px;
  max-width: 305px;
  text-align: center;
}

.avatars-group.stacked {
  display: flex;
  flex-direction: row;
  direction: ltr;
  max-width: 100%;
  white-space: nowrap;
}
.avatars-group.stacked > * {
  margin-right: -8px;
}

.avatars-group__item {
  transition: all 0.1s ease-out;
  padding-top: 15px;
}

.avatars-group__item.more:hover {
  transform: none;
}
.selectedAvatar {
  //padding-top: 0px;
}
.selectedAvatarListItem {
  color: $racecar;
}
.selectedAvatarIcon {
  box-shadow: 0px 0px 10px 3px $racecar;
}

.touchAvatar:hover {
  //padding-top: 0px;
  z-index: 1;
}
.avatars-group .v-avatar,
.avatar-moreList .v-avatar {
  width: 45px;
  height: 45px;
  cursor: pointer;
  border: 4px solid;
}

.v-avatar.active,
.avatar-moreList .v-avatar.active {
  border-color: $success;
}
.v-avatar.upcomming,
.avatar-moreList .v-avatar.upcomming {
  border-color: $warning;
}
.v-avatar.expire,
.avatar-moreList .v-avatar.expire {
  border-color: $error;
}

.avatars-group .v-avatar span {
  font-size: 110%;
  font-weight: 700;
  letter-spacing: 0.1rem;
}
.avatar-moreList {
  cursor: pointer;
}
.moreList {
  display: flex;
  align-items: center;
}
.profile-photo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 0.2s;
}
.customPrepend :deep(.v-list-item__prepend) {
  width: 50px;
}
</style>
