<template>
  <div class="signin" :class="{ 'secondary-is-open': openSecondaryPanel }">
    <div class="common-container">
      <div v-if="openSecondaryPanel">
        <img class="logo ml-5" src="@/assets/images/code-clan-logo.svg" />
        <div class="tempale-container">
          <component :is="template"></component>
        </div>
      </div>
      <v-card color="accent">
        <div class="signin__header">
          <div class="signin__title">
            <h4 class="headline" v-if="title">{{ title }}</h4>
            <div v-if="description">{{ description }}</div>
          </div>
          <img v-show="!openSecondaryPanel" class="logo ml-5" src="@/assets/images/code-clan-logo.svg" />
        </div>
        <div class="secondary-container"></div>
        <div class="forms pa-5">
          <router-view />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {},
  data() {
    return {
      openSecondaryPanel: false,
      title: "",
      description: "",
      template: null as boolean | null,
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.title = this.$route.meta.title as string;
      this.description = this.$route.meta.description as string;
      this.template = this.$route.meta.template as boolean;
      this.openSecondaryPanel = this.template && this.template !== null;
    },
  },
  watch: {
    $route() {
      this.initPage()
    }
  }
})
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
.signin {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  .common-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $racecar;
  }
  &.secondary-is-open {
    .card--background {
      background-color: $dark_gray;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .logo {
      height: auto;
      width: 80px;
    }
  }
}
.forms {
  display: flex;
  align-items: center;
  background-color: $dark_gray;
}
.login-form{
  width: 100%;
  text-align: center;
  border: none;
  box-shadow: none;
}
@media(max-width: 723px) {
  .register{
    &__card{
      width: 24vw;
    }
  }

}
</style>