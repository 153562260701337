<template>
  <div class="login-form">
    <div v-if="!emailStatus">
      <v-flex xs12 class="text-left">
         <v-form ref="frmMain" v-model="valid" lazy-validation>
          <TextField
            :rules="[rules.required, rules.EmailCheck]"
            prepend-icon="mdi-account"
            v-model="email"
            label="Email"
            />
         </v-form>
      </v-flex>
      <div class="actions">
        <v-btn @click="sendLink()" :loading="isLoading" color="accent"
          >Send me a magic link</v-btn
        >
      </div>
    </div>
    <div v-else>
      <h3 class="headline">We sent an email to {{ email }}</h3>
    </div>
  </div>
</template>
<script lang="ts">
import rules from "shared-components/src/utils/validations";
import router from "@/router";
import { defineComponent } from "vue";
import UserService from "../../services/UserService";
import {
  Paths,
  ProjectMessages,
} from "shared-components/src/definitions/constants";
import store from "@/store";


export default defineComponent({
  mounted() {},
  data: () => ({
    valid: false,
    rules,
    emailStatus: false,
    email: "",
    isLoading: false,
  }),
  methods: {
    showError(error: string) {
      store.dispatch("showErrorMessage", error);
    },
    gotoDashboard() {
      router.push(Paths.Client.Route);
    },
    async sendLink() {
      const isValid = await (this.$refs.frmMain as any).validate();
      if(isValid.valid){
        this.isLoading = true;
        this.$emit("success");
        UserService.sendAuthenticationLink(this.email)
          .then((result) => {
            if (result.status) {
              this.emailStatus = true;
            } else {
              this.showError(ProjectMessages.WrongOrInactiveEmail);
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.showError(ProjectMessages.LostConnection);
            this.isLoading = false;
          });
      }
    },
  },
});
</script>
