import moment from "../Plugins/moment";

export default class Utils {

    public static getEndOfWeek(date: Date = new Date()) : Date {
        return moment(date).endOf("week").toDate()
    }
    public static getStartWeek(): Date {
        return moment().startOf("week").toDate()
    }

    public static moneyFormat(value: number): string {
        return new Intl.NumberFormat("en-AU").format(value);
    }

    public static currencyToNumber(value: string): number {
        const retVal = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        if (isNaN(retVal)) {
            return 0;
        } else {
            return retVal;
        }
    }

    public static onlyUnique(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }

    public static timestampToDate(tms: any): Date {
        if (tms instanceof Date) {
            return tms;
        } else {
            return new Date((tms._seconds || tms.seconds) * 1000);
        }
    }

    public static addTimezoneOffset(date: any) : Date{
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - userTimezoneOffset);
    }

    public static addTimezoneOffsetToStartDate(date: Date | null) : Date | null {
        if(!date) {
            return date;
        }
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return this.addTimezoneOffset(date);
    }

    public static addTimezoneOffsetToEndDate(date: Date | null) : Date | null {
        if(!date) {
            return date;
        }
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(999);
        return this.addTimezoneOffset(date);
    }

    public static removeTimezoneOffset(date: any) {
        let toDate;
        if(date.seconds || date._seconds){
            toDate = this.timestampToDate(date);
        }
        else{
            toDate = date;
        }
        const dateOfsset = toDate.getTimezoneOffset();
        toDate.setMinutes( toDate.getMinutes() + dateOfsset );
        if (date.seconds) {
            date.seconds = toDate.getTime() / 1000;
        }
        else if (date._seconds) {
            date._seconds = toDate.getTime() / 1000;
        }
        else{
            date = toDate;
        }
        return date;
        
    }
    public static getYear(date: any){
        date = this.vsDateToDatetime(date);
        return date.getFullYear();
    }

    public static getWeekNumber(date: any){
        date = this.vsDateToDatetime(date);
        return moment(date).week();
    }

    public static toVsDateFormat(date: Date | string): string {
        try {
            if(typeof date === "string"){
                date = this.vsDateToDatetime(date);
            }
            if (this.isTimstamp(date)) {
                date = this.timestampToDate(date);
            }
        
            const y = date.getFullYear().toString();
            let m = (date.getMonth() + 1).toString();
            let d = date.getDate().toString();
        
            if (m.length < 2) {
                m = "0" + m;
            }
            if (d.length < 2) {
                d = "0" + d;
            }
            return [y, m, d].join("-");
        } catch (error) {
            return "";       
        }
    }

    public static vsDateToDatetime(date: string | Date): Date {
        if(typeof date === "string"){
            const parts = date.split("-").map(p => parseInt(p));
            return new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0, 0);
        }
        else {
            const dateTimeType = date as Date;
            return dateTimeType;
        }
    }

    public static startOfWeek(): Date
    {
        return moment().startOf('week').toDate();
    }



    public static getMonthName(date: Date | number): string {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        if (typeof date === "number") {
            return monthNames[date];
        } else {
            return monthNames[date.getMonth()];
        }
    }

    public static getCurrentUtcDate(): Date {
        const dt = new Date();
        return new Date(Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes()));
    }
    public static getWeekDaysArray(): { [key: string]: any[] }{
        return {
            "1": [],
            "2": [],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
            "0": [],
          } as { [key: string]: any[] }
    }
    private static isTimstamp(date: any){
        return date.seconds ? true : false;
    }
}