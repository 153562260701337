<template>
  <div class="navigation-drp">
    <v-menu location="bottom" transition="scale-transition">
      <template v-slot:activator="{ props }">
        <v-btn variant="text"  v-bind="props" icon="mdi-plus"/>
      </template>
      <v-list>
        <v-list-item to="/client/virtualDesktop">Smart Workbench</v-list-item>
        <v-list-item @click="GoToProject">Project</v-list-item>
        <v-list-item to="/client/projectLead">Project Lead</v-list-item>
        <v-list-item to="/client/portfolioManager">Portfolio Manager</v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
    };
  },
  methods: {
    GoToProject(){
      store.dispatch("setNewProjectPopupStatus", true);
      this.$router.push({ name: 'project'})
    }
  },
});
</script>
<style lang="scss" scoped>
.navigation-drp{
  position: absolute;
}
</style>
