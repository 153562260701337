<template>
        <v-card class="pa-5 transparent" :loading="loading">
        <v-row v-for="project in filteredProjects" :key="project.id" align="center" class="flowContainer">
            <v-col md="6" sm="6" class="project" v-if="project.Epics && project.Epics.length>0">
                <span class="rectCard" :class="project.isClicked ? 'selectedItem' : '' " @click="getDataByProject(project.id)">
                  {{ project.Name }} ({{ getProjectHours(project.id) }})
                </span>
            </v-col>
            <v-col dense md="6" sm="6" :class="project.Epics.length ? 'epic' : ''">
                <div v-for="epic in project.Epics" :key="epic.name" class="epicName">
                    <v-row class="rectCard" :class="epic.isClicked ? 'selectedItem' : ''" @click="getDataByEpic(project.id, epic.name)">
                        <h4 class="pb-0">
                          {{ epic.name }}
                        </h4>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        </v-card>
</template>
<script lang="ts">
import Project from "shared-components/src/models/Project";
import { defineComponent } from "vue";
interface UIEpic{
    name: string;
    isClicked: boolean;
}
interface UIProject{
    id: string;
    Name: string;
    Epics: UIEpic[];
    isClicked: boolean;
}
export default defineComponent({
    data(){
        return{
            filteredProjects: [] as UIProject[],
            selectedProjects: [] as UIProject[],
        }
    },
    props:["projects", "filteredTimesheets", "selectedProjects"],
    methods:{
      getProjectHours(projId: string){
        const totalPrice = this.filteredTimesheets.filter((c: any) => c.ProjectId === projId).reduce((acc: any, current: any) => acc + current.Effort + current.Adjustment, 0);
        return totalPrice;
      },
        getDataByProject(projId: string){
            var selectedProject = this.filteredProjects.find(c => c.id === projId);
            if (selectedProject) {
                selectedProject.isClicked = !selectedProject.isClicked;
                if (selectedProject.isClicked) {
                    selectedProject.Epics.forEach(c => {
                        c.isClicked = true;
                    });
                }
                else{
                    selectedProject.Epics.forEach(c => {c.isClicked = false});
                }
                this.selectedProjects = this.filteredProjects.filter(c => c.isClicked);
                this.$emit('filterSummaries', {projects: this.selectedProjects});
            }
            
        },
        getDataByEpic(projId: string, epic: string){
            var selectedProject = this.filteredProjects.find(c => c.id === projId);
            if (selectedProject) {
                var selectedEpic = selectedProject.Epics.find(c=> c.name === epic);
                if (selectedEpic) {
                    selectedEpic.isClicked = !selectedEpic.isClicked;

                }
                var hasSelectedEpic = selectedProject.Epics.some(c => c.isClicked)
                if (!hasSelectedEpic) {
                    selectedProject.isClicked = false;
                }
                else {
                    selectedProject.isClicked = true;
                }
                this.selectedProjects = this.filteredProjects.filter(c => c.isClicked);
                this.$emit('filterSummaries', {projects: this.selectedProjects});
            }
        }
    },
    watch:{

      projects(newVal: Project[]){
          this.filteredProjects = [] as UIProject[];
          newVal.forEach(c => {
              var item = {
                  id: c.id,
                  Name: c.Name,
                  Epics: c.Epics.map(e => {return {name: e, isClicked: false}}),
                  isClicked: false,
              } as UIProject;
              if (this.selectedProjects && this.selectedProjects.length > 0) {
                var isSelected = this.selectedProjects.some((p: any) => p.id === item.id);
                if(isSelected)
                {
                  item.isClicked = true;
                }
                item.Epics.forEach(e => {
                  e.isClicked = this.selectedProjects.some((p: any) => p.id === item.id && p.Epics.some((f: any) => f.name === e.name && f.isClicked))
                });
              }
              this.filteredProjects.push(item);
          });            
      }
    }
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.transparent{
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.flowContainer {
  margin: 5px 0;

  &:hover {
    .project,
    .epic,
    .epicName {
      &::after,
      &::before {
        background: $racecar;
        box-shadow: 0 0 3px 3px $racecar;
      }
    }
  }
  .project {
    position: relative;
    &::after {
      content: " ";
      width: 100%;
      background: $text_color;
      height: 1px;
      z-index: 1;
      position: absolute;
      top: 50%;
    }
  }

  .epic {
    position: relative;
    background: $dark_gray;
    z-index: 2;
    padding: 0;

    .epicName {
      position: relative;

      &:not(:first-child) {
        padding-top: 10px;
      }

      &::after {
        content: " ";
        position: absolute;
        height: 1px;
        background: $text_color;
        left: 0;
        top: 50%;
        width: 50px;
      }

      &::before {
        content: " ";
        position: absolute;
        background: $text_color;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
      }

      &:first-child {
        &::before {
          top: 50%;
        }
      }

      &:last-child {
        &::before {
          bottom: 50%;
        }
      }

      &:first-child {
        &:last-child {
          &::before {
            display: none;
          }
        }
      }
    }
    .rectCard {
      position: relative;
      background: $pit_crew_uniform;
      border: 1px solid $text_color;
      border-radius: 10px;
      display: inline-block;
      margin: 0 0 0 50px;
      padding: 10px;
      z-index: 2;
    }
  }
  .rectCard {
    &:hover {
      box-shadow: 0 0 3px 3px $racecar;
    }

    position: relative;
    background: $pit_crew_uniform;
    border: 1px solid $text_color;
    border-radius: 10px;
    display: inline-block;
    margin: 0;
    padding: 10px;
    z-index: 2;
    cursor: pointer;
  }
  .selectedItem{
      box-shadow: 0 0 3px 3px $racecar;
  }
}
</style>