import Timesheet from "shared-components/src/models/Timesheet";
import RepositoryTeammemberCommit from "shared-components/src/viewModels/response/repository/RepositoryTeammemberCommit";

export default class TimesheetService {
  public static TimesheetReviewResultForTeammember(
    allTimesheets: Timesheet[],
    commits: RepositoryTeammemberCommit[]
  ): string[] {
    const result = [] as string[];
    if (allTimesheets && allTimesheets.length) {
      const groupeByCommitmentId = allTimesheets.reduce(
        (grouped: any, item: any) => {
          const key = item.CommitmentId;
          if (!grouped[key]) {
            grouped[key] = [];
          }
          grouped[key].push(item);
          return grouped;
        },
        {}
      );

      for (const cmId in groupeByCommitmentId) {
        const timesheets = allTimesheets.filter((x) => x.CommitmentId === cmId);
        if (timesheets && timesheets.length) {
          const selectedCommitment = timesheets[0].Commitment;

          const groupeByIssueNo = timesheets.reduce(
            (grouped: any, item: any) => {
              const key = item.IssueNo;
              if (!grouped[key]) {
                grouped[key] = [];
              }
              grouped[key].push(item);
              return grouped;
            },
            {}
          );

          const groupeByDate = timesheets.reduce((grouped: any, item: any) => {
            const key = item.Date ? item.Date.toISOString().split("T")[0] : "";
            if (!grouped[key]) {
              grouped[key] = [];
            }
            grouped[key].push(item);
            return grouped;
          }, {});

          //- if sum(timesheet) for one task > 20 hours - return
          // Recommendation: Long Task - Description: XX hours spent on one task is not advisable  Suggested Improvement: Tasks should be broken down to smaller pieces
          var effortIssues = "";
          for (const issueNo in groupeByIssueNo) {
            const issueData = groupeByIssueNo[issueNo];
            const totalHour = issueData.reduce(
              (total: any, item: any) => total + item.Effort,
              0
            );
            if (totalHour > 20) {
              effortIssues += `${
                effortIssues.length > 0 ? ", " : ""
              }${issueNo}`;
            }
          }
          if (effortIssues) {
            result.push(
              `Long Task - More than 20 hours spent on one task(s) [${effortIssues}] is not advisable. Suggested Improvement: Tasks should be broken down to smaller pieces`
            );
          }
          //- if daily variance/deviation > 3 -
          // Recommendation: incosistent days - Description: Incosistencies between the hours of work in different days - Suggested Improvement: It's better to allocate around 8 hours a day
          var dateIssues = "";
          if (
            selectedCommitment?.HoursPerWeekMax &&
            selectedCommitment.HoursPerWeekMin
          ) {
            const maxHoursPerDay = selectedCommitment?.HoursPerWeekMax / 5;
            const minHoursPerDay = selectedCommitment?.HoursPerWeekMin / 5;
            for (const date in groupeByDate) {
              const dateData = groupeByDate[date];
              const totalHour = dateData.reduce(
                (total: any, item: any) => total + item.Effort,
                0
              );
              if (totalHour > maxHoursPerDay || totalHour < minHoursPerDay) {
                dateIssues += `${dateIssues.length > 0 ? ", " : ""}${date}`;
              }
            }
            if (dateIssues) {
              result.push(
                `Inconsistent days -  Incosistencies between the hours of work in [${dateIssues}] - Suggested Improvement: It's better to allocate around 8 hours a day`
              );
            }
          }

          //- Average Description length < 20
          let poorDescriptionsCount = 0;
          let noEpicCount = 0;
          timesheets.forEach((timesheet) => {
            if (timesheet.Description.length < 20) {
              poorDescriptionsCount++;
            }

            if (timesheet.Epic?.toLocaleLowerCase() == "n/a") {
              noEpicCount++;
            }
          });
          if (poorDescriptionsCount > 3) {
            result.push(
              `Description of ${poorDescriptionsCount} tasks are poor, Please ask teammember to use better descriptions`
            );
          }
          //- No Epic
          if (noEpicCount > 3) {
            result.push(
              `${noEpicCount} task(s) have no epics, Please ask teammember to learn about epics and use them on Timesheet insertion`
            );
          }

          if (commits && commits.length > 0) {
            //- if line of code changed < time logged for coding * 10 -
            // Recommendation: Time/Code ratio - Description: Lines of codes changed is proportionally too little for the time logged - Suggestion: Coding efficiency must be improved, or better allocation of tasks leveraged to improve on the number of lines changed

            //- Number of commits that cannot be linked to tickets
            let noneLinkedCommits = 0;
            commits.forEach((commit) => {
              const has = commit.hash;
              const existCommit = (timesheets as any[]).find((timesheet) => {
                const timsheetCommits = timesheet.Commits;
                if (timsheetCommits) {
                  return timsheetCommits.find((x: any) => x.hash == has);
                } else {
                  return false;
                }
              });
              if (!existCommit) {
                noneLinkedCommits++;
              }
            });
            if (noneLinkedCommits) {
              result.push(
                `${noneLinkedCommits} commits cannot be linked to the tasks`
              );
            }
          } else {
            result.push(
              `There hasn't been a commit fetched for this team member. Please check for any issues.`
            );
          }
        }
      }
    }
    return result;
  }
}
