import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "course-details" }
const _hoisted_2 = {
  key: 0,
  class: "tag-section"
}
const _hoisted_3 = {
  key: 1,
  class: "tag-section"
}
const _hoisted_4 = { class: "favorite-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "course-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "5",
            lg: "4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_img, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCourseDetail(_ctx.course.Id))),
                src: _ctx.course.ImageDownloadUrl,
                "aspect-ratio": 1,
                cover: "",
                class: "course_image"
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "7",
            lg: "8"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", {
                  class: "title",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCourseDetail(_ctx.course.Id)))
                }, _toDisplayString(_ctx.course.Title), 1),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("span", _mergeProps({ class: "description" }, props), _toDisplayString(_ctx.course.Summary), 17)
                    ]),
                    default: _withCtx(() => [
                      _cache[4] || (_cache[4] = _createTextVNode(" Summary "))
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_tooltip, { location: "right" }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("mdi-human-male-board")
                          ])),
                          _: 1
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.course.Author), 1)
                      ], 16)
                    ]),
                    default: _withCtx(() => [
                      _cache[6] || (_cache[6] = _createTextVNode(" Author "))
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.course.Skills.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("mdi-shape")
                              ])),
                              _: 1
                            })
                          ], 16)
                        ]),
                        default: _withCtx(() => [
                          _cache[8] || (_cache[8] = _createTextVNode(" Skill "))
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.Skills, (skill, index) => {
                        return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(_ctx.getSkillName(skill)) + _toDisplayString(index != _ctx.course.Skills.length - 1 ? "," : ""), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode("mdi-timer-sand")
                          ])),
                          _: 1
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.formatDuration(_ctx.course.Duration)), 1)
                      ], 16)
                    ]),
                    default: _withCtx(() => [
                      _cache[10] || (_cache[10] = _createTextVNode(" Duration "))
                    ]),
                    _: 1
                  }),
                  _cache[12] || (_cache[12] = _createTextVNode(" - ")),
                  _createVNode(_component_v_tooltip, { location: "bottom" }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("mdi-bulletin-board")
                          ])),
                          _: 1
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.course.Steps.length) + " Step ", 1)
                      ], 16)
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.course.Steps.filter((item) => item.Type == "Lesson").length) + " Lesson and " + _toDisplayString(_ctx.course.Steps.filter((item) => item.Type == "Assessment")
                  .length) + " Assessment ", 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_tooltip, { location: "right" }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLevelStarsCount(_ctx.course.Level), (i) => {
                          return (_openBlock(), _createBlock(_component_v_icon, null, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode("mdi-star")
                            ])),
                            _: 1
                          }))
                        }), 256)),
                        _createTextVNode(" " + _toDisplayString(_ctx.course.Level), 1)
                      ], 16)
                    ]),
                    default: _withCtx(() => [
                      _cache[14] || (_cache[14] = _createTextVNode(" Level "))
                    ]),
                    _: 1
                  })
                ]),
                (_ctx.course.Tags.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_v_tooltip, { location: "bottom" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                _createTextVNode("mdi-tag")
                              ])),
                              _: 1
                            })
                          ], 16)
                        ]),
                        default: _withCtx(() => [
                          _cache[16] || (_cache[16] = _createTextVNode(" Tags "))
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.Tags, (tag, index) => {
                        return (_openBlock(), _createBlock(_component_v_chip, {
                          link: "",
                          key: index
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tag.Title), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  (_ctx.showAddToRoadMap)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addToRoadMap(_ctx.course))),
                        disabled: _ctx.stepIds.includes(_ctx.course.Id)
                      }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode(" Add to roadmap ")
                        ])),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.course.IsFavorite != true)
                    ? (_openBlock(), _createBlock(_component_v_tooltip, {
                        key: 0,
                        location: "bottom"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps(props, {
                            style: {"min-width":"0"},
                            variant: "text",
                            onClick: _ctx.addToFavorite
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[18] || (_cache[18] = [
                                  _createTextVNode(" mdi-heart ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _cache[19] || (_cache[19] = _createTextVNode(" Add to Favorites "))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.course.IsFavorite == true)
                    ? (_openBlock(), _createBlock(_component_v_tooltip, {
                        key: 1,
                        location: "bottom"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps(props, {
                            style: {"min-width":"0"},
                            variant: "text",
                            onClick: _ctx.addToFavorite
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, { class: "heart_icon" }, {
                                default: _withCtx(() => _cache[20] || (_cache[20] = [
                                  _createTextVNode(" mdi-heart ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _cache[21] || (_cache[21] = _createTextVNode(" Remove from Favorites "))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.showAddToRoadMap)
                    ? (_openBlock(), _createBlock(_component_v_tooltip, {
                        key: 2,
                        location: "bottom"
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps(props, {
                            style: {"min-width":"0"},
                            variant: "text",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editCourse(_ctx.course.Id)))
                          }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[22] || (_cache[22] = [
                                  _createTextVNode(" mdi-pen ")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[23] || (_cache[23] = _createTextVNode(" Edit "))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}