import { TeammemberWithSummaryDetails } from "shared-components/src/models/Teammember";
import ApiService from "./ApiService";
import { ProjectLeadTeammember } from "shared-components/src/models/ProjectLeadTeammember";
import {
  ClientGetTeammemberResponseModel,
  ClientInviteTeammemberRequest,
  ClientSearchTeammemberResponseModel,
  SearchTeammemberRequestModel,
} from "shared-components/src/services/openApi/api";

export default class TeammemberService {
  public static async getActiveTeammembers(): Promise<ProjectLeadTeammember[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/client/teammembers`)
        .then((result) => {
          if (!result.data) {
            resolve([] as ProjectLeadTeammember[]);
          } else {
            var teammembers = result.data as ProjectLeadTeammember[];
            resolve(teammembers);
          }
        })
        .catch((error) => reject(error));
    });
  }

  public static getListWithSummaryDetails(): Promise<
    TeammemberWithSummaryDetails[]
  > {
    return new Promise((resolve, reject) => {
      ApiService.get(`/client/teammembers/SummaryDetails`)
        .then((tmResult) => {
          if (!tmResult.data) {
            resolve([]);
          } else {
            const retVal = tmResult.data.map((d: any) => {
              const tm = { ...d } as TeammemberWithSummaryDetails;
              tm.FullName = `${tm.FirstName || ""} ${tm.LastName || ""}`;
              return tm;
            });
            resolve(retVal);
          }
        })
        .catch((tmError) => reject("Team member error => " + tmError));
    });
  }

  public static searchTeammember(
    criteria: SearchTeammemberRequestModel
  ): Promise<ClientSearchTeammemberResponseModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/client/teammembers/search`, criteria)
        .then((teammemberResult: any) => {
          if (!teammemberResult.data) {
            resolve([]);
          } else {
            const items = teammemberResult.data.map((doc: any) => {
              const teammemberData = doc;
              const retVal = {
                id: doc.id,
                ...teammemberData,
              } as ClientSearchTeammemberResponseModel;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((error: any) => reject(error));
    });
  }

  public static getTeammember(
    teammemeberId: string
  ): Promise<ClientGetTeammemberResponseModel> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/client/teammembers/get/${teammemeberId}`, "")
        .then((teammemberResult: any) => {
          if (!teammemberResult.data) {
            resolve({} as ClientGetTeammemberResponseModel);
          } else {
            resolve(teammemberResult.data as ClientGetTeammemberResponseModel);
          }
        })
        .catch((error: any) => reject(error));
    });
  }

  public static inviteTeammember(
    request: ClientInviteTeammemberRequest
  ): Promise<ClientSearchTeammemberResponseModel> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/client/teammembers/register`, request)
        .then((teammemberResult: any) => {
          if (!teammemberResult.data) {
            resolve({} as ClientSearchTeammemberResponseModel);
          } else {
            resolve(
              teammemberResult.data as ClientSearchTeammemberResponseModel
            );
          }
        })
        .catch((error: any) => reject(error));
    });
  }
}
