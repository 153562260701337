import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosRequestTransformer,
  AxiosResponse,
} from "axios";
import router from "../router/index";
import { baseAzureApiUrl } from "shared-components/src/plugins/firebase";
import { SystemErrors } from "shared-components/src/definitions/systemErrors";
import { Paths } from "shared-components/src/definitions/constants";
import AppHelper from "shared-components/src/utils/AppHelper";
import moment from "moment";

if (AppHelper.IsAzureEnv()) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response ? error.response.status : null;
      if (status === SystemErrors.Authentication.TokenExpired) {
        router.push(Paths.Client.Logout);
      }
      return Promise.reject(error);
    }
  );
}

const v1Prefix = "v1";

class Cache {
  public static RefreshingCall: any = null;
}

const dateTransformer = (data: any): any => {
  if (data instanceof Date) {
    return moment(data).format("YYYY-MM-DDTHH:mm:ss");
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, dateTransformer(value)])
    );
  }
  return data;
};

const ApiService = {
  async refreshToken() {
    if (Cache.RefreshingCall) {
      return Cache.RefreshingCall;
    }
    const config = await this.getAzureConfig();
    const refreshingCall = axios
      .get(`${v1Prefix}/authentication/refreshToken`, config)
      .then(({ data: { id_token, refresh_token } }) => {
        localStorage.idToken = id_token;
        localStorage.refreshToken = refresh_token;
        Cache.RefreshingCall = null;
        return Promise.resolve(true);
      })
      .catch((error) => {
        Cache.RefreshingCall = null;
      });
    Cache.RefreshingCall = refreshingCall;
    return refreshingCall;
  },

  getAzureConfig(transformDate: boolean = true): Promise<AxiosRequestConfig> {
    return new Promise((resolve, reject) => {
      let token = "";
      let refreshToken = "";
      if (localStorage.idToken) {
        token = localStorage.idToken;
        refreshToken = localStorage.refreshToken || "";
      }
      const config = {
        baseURL: baseAzureApiUrl,
        headers: {
          Authorization: token,
          RefreshToken: refreshToken,
        } as unknown as AxiosRequestHeaders,
      } as AxiosRequestConfig;
      if (transformDate) {
        config.transformRequest = [
          dateTransformer,
          ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
        ];
      }
      resolve(config);
    });
  },

  query(resource: any, params: any): Promise<AxiosResponse<any>> {
    return new Promise((resolve, reject) => {
      axios
        .get(resource, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async get(
    resource: any,
    slug = "",
    dateTransform: boolean = true
  ): Promise<AxiosResponse<any>> {
    const config = await this.getAzureConfig(dateTransform);
    let url = `${v1Prefix}${resource}`;
    if (slug) {
      url += `/${slug}`;
    }
    return axios.get(`${url}`, config).catch((error) => {
      const errorText = String(error);
      if (errorText.includes(`${SystemErrors.Authentication.TokenExpired}`)) {
        router.push(Paths.Client.Logout);
      }
      throw new Error(`ApiService : ${error}`);
    });
  },

  async post(
    resource: any,
    params: any,
    dateTransform: boolean = true
  ): Promise<AxiosResponse<any>> {
    const config = await this.getAzureConfig(dateTransform);
    return axios.post(`${v1Prefix}${resource}`, params, config);
  },

  async patch(
    resource: any,
    params: any,
    dateTransform: boolean = true
  ): Promise<AxiosResponse<any>> {
    const config = await this.getAzureConfig(dateTransform);
    return axios.patch(`${v1Prefix}${resource}`, params, config);
  },

  async update(
    resource: any,
    slug: any,
    params: any,
    dateTransform: boolean = true
  ) {
    let url = `${v1Prefix}${resource}`;
    if (slug) {
      url += `/${slug}`;
    }
    return axios.put(
      `${url}`,
      params,
      await this.getAzureConfig(dateTransform)
    );
  },

  async put(
    resource: any,
    params: any,
    dateTransform: boolean = true
  ): Promise<AxiosResponse<any>> {
    const config = await this.getAzureConfig(dateTransform);
    return axios.put(`${v1Prefix}${resource}`, params, config);
  },

  async delete(
    resource: any,
    dateTransform: boolean = true
  ): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      const config = await this.getAzureConfig(dateTransform);
      axios
        .delete(`${v1Prefix}${resource}`, config)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default ApiService;
