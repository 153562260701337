<template>
  <div class="main-page-container">
    <v-progress-circular
      :width="3"
      :size="70"
      color="red"
      indeterminate
      v-if="isDetailsLoading"
      class="ma-auto"
    ></v-progress-circular>
    <div class="main-search-container" v-else>
      <div>
        <SelectedChips
          :details="details"
          :skills="skills"
          :criteria="criteria"
          @criteriaChanged="criteriaChanged"
        />
      </div>
      <div class="result-box">
        <v-row class="h-100">
          <v-progress-circular
            :width="3"
            :size="70"
            color="red"
            indeterminate
            v-if="isResultLoading"
            class="ma-auto"
          ></v-progress-circular>
          <v-col xs="12" md="8" v-else>
            <div>
              <v-row class="title-row">
                <v-col cols="12" sm="12" md="8" class="align-self-center">
                  <div>Results - {{ employees.length }} cases</div>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <div>
                    <SelectField
                      :items="sortByItems"
                      item-title="text"
                      item-value="value"
                      label="Sort By"
                      v-model="criteria.sortBy"
                      hide-details
                      dense
                      outlined
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="4"
                  v-for="(item, index) of employees"
                  :key="index"
                >
                  <EmployeeItem
                    :employee="item"
                    :skills="skills"
                    :details="details"
                    :showInScene="showInScene"
                    @ViewTmDetails="ViewTmDetails"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col xs="12" md="4">
            <Filter
              :details="details"
              :skills="skills"
              :criteria="criteria"
              :positionJD="positionJD"
              @doSearch="doSearch"
            ></Filter>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import store from "../../store";
import CoreSkillService from "@/services/CoreSkillService";
import DetailsService from "@/services/DetailsService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import Details from "shared-components/src/models/Details";
import EmployeeItem from "./EmployeeItem.vue";
import Filter from "./Filter.vue";
import SelectedChips from "./SelectedChips.vue";
import { ClientSearchTeammemberResponseModel, SearchTeammemberRequestModel, AzureAISearchApi } from "shared-components/src/services/openApi/api";


export default defineComponent({
  props: {
    positionJD: {
      type: String,
      required: false,
    },
    showInScene: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.isDetailsLoading = true;
    await this.loadSkillsList();
    await this.loadDetailsList();
    this.isDetailsLoading = false;

    if (store.getters.searchEmployeeCriteria) {
      this.criteria = {
        ...store.getters.searchEmployeeCriteria,
      } as SearchTeammemberRequestModel;
    }
    this.criteria.sortBy = "Experience";
    this.search();
    store.dispatch("setCriteria", {
      searchEmployeeCriteria: null,
    });
  },
  components: { EmployeeItem, Filter, SelectedChips },
  data() {
    return {
      criteria: {} as SearchTeammemberRequestModel,
      skills: [] as CoreSkill[],
      details: [] as Details[],
      employees: [] as ClientSearchTeammemberResponseModel[],
      isDetailsLoading: true,
      isResultLoading: false,
      searchRegNumber: 0,
      sortByItems: [{ text: "Total Experience", value: "Experience" }],
    };
  },
  methods: {
    ViewTmDetails(event: any) {
      this.$emit("ViewTmDetails", event);
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails();
    },
    async doSearch(criteria: any) {
      this.criteria = criteria;
      await this.search();
    },
    async criteriaChanged(criteria: any) {
      this.criteria = criteria;
    },
    async search() {
      this.isResultLoading = true;
      this.searchRegNumber += 1;
      const searchNumber = this.searchRegNumber;
      if (this.criteria) {
        await new AzureAISearchApi().searchTeammemberByClient({
          jobRole: (this.criteria as any).jobRole ?? null,
          skills: this.criteria.skills
            ? [...this.criteria.skills]
            : undefined,
          age: this.criteria.age ?? undefined,
          totalExperience: this.criteria.totalExperience ?? undefined,
          locations: (this.criteria as any).locations
            ? [...(this.criteria as any).locations]
            : undefined,
          sortBy: this.criteria.sortBy ?? undefined,
          description: this.criteria.description ?? undefined
        }).then((items: any) => this.employees = items.data);
      }
      if (searchNumber == this.searchRegNumber) {
        this.isResultLoading = false;
      }
    },
  },
  computed: {},
});
</script>
<style scoped lang="scss">
.main-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  .main-search-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .result-box {
      height: 100%;

      .title-row {
        color: #fff;
      }
    }
  }
}
</style>
