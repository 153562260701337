<template>
  <v-card class="card" :disabled="isLoading" :loading="isLoading">
    <v-card-text class="form-inputs">
      <v-form ref="frmMain" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="6">
            <TextField
              clearable
              label="Title"
              prepend-icon="mdi-rename"
              v-model="courseModel.Title"
              :rules="[rules.required]"
            />
            <TextAreaField
              clearable
              label="Summary"
              prepend-icon="mdi-pencil"
              rows="5"
              v-model="courseModel.Summary"
              :rules="[rules.required]"
            />
            <ComboBoxField
              clearable
              chips
              multiple
              label="Dependecy"
              :items="getDependencies"
              item-title="Title"
              item-value="Id"
              closable-chips
              prepend-icon="mdi-link"
              v-model="SelectedDependecy"
              return-object
            />
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col
                cols="12"
                v-if="
                  (selectedImagePreview != '' &&
                    selectedImagePreview != null) ||
                  (courseModel.ImageDownloadUrl != null &&
                    courseModel.ImageDownloadUrl != undefined)
                "
              >
                <label class="input-label" :style="{ paddingLeft: '42px' }">Image</label>
                <div class="edit-image-container">
                  <v-img
                    :src="
                      selectedImagePreview
                        ? selectedImagePreview
                        : courseModel.ImageDownloadUrl
                    "
                    class="course-image"
                    :aspect-ratio="1"
                    cover
                  ></v-img>
                  <v-file-input
                    v-model="selectedImage"
                    @change="onImageSelect"
                    hide-input
                    prepend-icon="mdi-file-replace"
                    class="course-image-replace"
                    accept=".jpg,.jpeg"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col v-else cols="12">
                <label class="input-label" :style="{ paddingLeft: '42px' }">Image</label>
                <v-file-input
                  v-model="selectedImage"
                  @change="onImageSelect"
                  prepend-icon="mdi-camera"
                  :rules="[rules.Required]"
                  accept=".jpg,.jpeg"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <TextField
                  clearable
                  label="Author"
                  prepend-icon="mdi-clock-edit-outline"
                  v-model="courseModel.Author"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="4">
                <TextField
                  label="Duration/mins"
                  prepend-icon="mdi-clock-edit-outline"
                  v-model="courseModel.Duration"
                  type="number"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="4">
                <SelectField
                  label="Level"
                  :items="levels"
                  v-model="courseModel.Level"
                  prepend-icon="mdi-clock-edit-outline"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0 mb-0">
              <v-col cols="6">
                <AutoCompleteField
                  clearable
                  chips
                  label="Skill"
                  :items="skills"
                  item-title="Text"
                  item-value="id"
                  multiple
                  closable-chips
                  prepend-icon="mdi-clock-edit-outline"
                  v-model="courseModel.Skills"
                  :rules="[rules.required, rules.arrayMinLength(1)]"
                />
              </v-col>
              <v-col cols="6">
                <ComboBoxField
                  clearable
                  chips
                  multiple
                  label="Tag"
                  :items="tags"
                  item-title="Title"
                  item-value="Title"
                  closable-chips
                  prepend-icon="mdi-clock-edit-outline"
                  v-model="SelectedTags"
                  :return-object="false"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <TextAreaField
              clearable
              label="Description"
              prepend-icon="mdi-pencil"
              v-model="courseModel.Description"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row class="divider">
          <div>
            <v-btn
              class="secondary_btn"
              prepend-icon="mdi-plus"
              @click="AddLesson"
              >Add Lesson</v-btn
            >
            <v-btn
              class="secondary_btn ml-3"
              prepend-icon="mdi-plus"
              @click="AddAssessment"
              >Add Assessment</v-btn
            >
          </div>
          <v-divider class="divider-line" :thickness="3"></v-divider>
        </v-row>
        <draggable
          :list="steps"
          item-key="Id"
          handle=".handle-btn"
          @start="dragging = true"
          @end="dragging = false"
          v-if="steps.length > 0"
        >
          <template #item="{ element, index }">
            <div class="repeater">
              <div class="order-btns">
                <v-icon
                  @click="RepeaterMoveUp(index)"
                  v-if="index != 0 && !dragging"
                  >mdi-arrow-up</v-icon
                >
                <v-icon
                  v-if="steps.length > 1 && !dragging"
                  @click="RepeaterDelete(index)"
                  >mdi-delete</v-icon
                >
                <v-icon v-if="steps.length > 1 && !dragging" class="handle-btn"
                  >mdi-drag</v-icon
                >
                <v-icon
                  @click="RepeaterMoveDown(index)"
                  v-if="index != steps.length - 1 && !dragging"
                  >mdi-arrow-down</v-icon
                >
              </div>
              <v-card :disabled="dragging" variant="tonal" class="lesson-card">
                <v-card-text>
                  <v-row v-if="element.Type == 'Lesson'" class="align-center">
                    <v-col cols="6">
                      <TextField
                        label="Title"
                        prepend-icon="mdi-book-open-variant"
                        v-model="element.Title"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="4">
                      <TextField
                        label="Duraton/mins"
                        prepend-icon="mdi-clock-edit-outline"
                        type="number"
                        single-line
                        v-model="element.Duration"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-checkbox
                        label="Show Editor"
                        v-model="element.ShowEditor"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <editor v-model="element.Content" />
                      <v-divider :thickness="2"></v-divider>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="element.Type == 'Assessment'"
                    class="align-center"
                  >
                    <v-col cols="6">
                      <AutoCompleteField
                        v-model="element.AssessmentDetails.Id"
                        :items="assessments"
                        :loading="isLoading"
                        item-title="Title"
                        item-value="Id"
                        label="Select an Assessment"
                        placeholder="Start typing to Search"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn @click="AddNewAssessment"
                        >Add New Assessment</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </template>
        </draggable>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-row>
        <v-col cols="12" class="action-btns">
          <v-btn class="secondary_btn" @click="cancel">Cancel</v-btn>
          <v-btn class="primary_btn_v2" @click="saveCourse">Save</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-dialog v-model="showDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="action-btns">
              <v-btn class="secondary_btn" @click="cancelDeletePopup"
                >Cancel</v-btn
              >
              <v-btn class="primary_btn_v2" @click="deleteStepItem"
                >Delete</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddAssessmentDialog" persistent fullscreen>
      <AddOrEditAssessment
        @assessmentSaved="assessmentSaved"
        @assessmentClosed="assessmentClosed"
        :isPopup="true"
      ></AddOrEditAssessment>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import draggable from "vuedraggable";
import { defineComponent } from "vue";
import CourseModel, {
  CourseStep,
} from "../../../../../ProjectPortal-SharedComponents/src/models/CourseModel";
import CourseService from "../../../services/CourseService";
import CoreSkill from "shared-components/src/models/CoreSkill";
import CoreSkillService from "../../../services/CoreSkillService";
import TagModel, { TagType } from "shared-components/src/models/Tag";
import Editor from "../../../components/Editor.vue";
import TagService from "../../../services/TagService";
import rules from "shared-components/src/utils/validations";
import { Profile } from "shared-components/src/definitions/config";
import { mapMutations } from "vuex";
import { REMOVE_SNAKBAR, SET_SNAKBAR_MODEL } from "../../../store/types";
import UploadService from "../../../services/UploadService";
import AssessmentModel from "shared-components/src/models/AssessmentModel";
import AssessmentService from "@/services/AssessmentService";
import AddOrEditAssessment from "../Assessment/AddOrEditAssessment.vue";

export default defineComponent({
  components: {
    Editor,
    draggable,
    AddOrEditAssessment,
  },
  props: {
    courseId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      courseModel: {} as CourseModel,
      skills: [] as CoreSkill[],
      tags: [] as TagModel[],
      dependencies: [] as CourseModel[],
      SelectedTags: [] as string[],
      SelectedDependecy: [] as string[],
      levels: ["Junior", "MidLevel", "Senior"],
      steps: [] as CourseStep[],
      dragging: false,
      rules,
      valid: false,
      selectedImage: [] as File[],
      selectedImagePreview: "",
      assessments: [] as AssessmentModel[],
      showDeleteDialog: false,
      selectedIndexToDelete: null as number | null,
      showAddAssessmentDialog: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.loadSkillsList();
    await this.loadAssessments();
    await this.loadTagsList();
    await this.loadDependencies();
    if (this.courseId) {
      this.courseModel = await CourseService.GetById(this.courseId);
      this.courseModel.Tags.forEach((tag: any) => {
        this.SelectedTags.push(tag.Title);
      });
      this.courseModel.Dependencies.forEach((dependency) => {
        if (dependency.DependencyId) {
          (this.SelectedDependecy as any).push(
            this.getDependencies.find(
              (item) => item.Id == dependency.DependencyId
            )
          );
        } else {
          this.SelectedDependecy.push(dependency.Text ?? "");
        }
      });
      this.steps = this.courseModel.Steps;
    } else {
      this.steps.push({ Type: "Lesson" } as CourseStep);
    }
    this.isLoading = false;
  },
  methods: {
    ...mapMutations([SET_SNAKBAR_MODEL, REMOVE_SNAKBAR]),
    AddNewAssessment() {
      this.showAddAssessmentDialog = true;
    },
    async assessmentSaved() {
      this.assessmentClosed();
      this.isLoading = true;
      await this.loadAssessments();
      this.isLoading = false;
    },
    assessmentClosed() {
      this.showAddAssessmentDialog = false;
    },
    onImageSelect(e: any) {
      if (!this.selectedImage[0]) {
        return;
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        (this.selectedImagePreview as any) = e.target.result;
      };
      reader.readAsDataURL(this.selectedImage[0]);
    },
    RepeaterMoveUp(index: number) {
      if (index === 0) {
        return;
      }
      let previousIndex = index - 1;
      let item = { ...this.steps[index] };
      let previousItem = { ...this.steps[previousIndex] };
      this.steps[previousIndex] = item;
      this.steps[index] = previousItem;
    },
    RepeaterMoveDown(index: number) {
      if (index === this.steps.length - 1) {
        return;
      }
      let nextIndex = index + 1;
      let item = { ...this.steps[index] };
      let nextItemCopy = { ...this.steps[nextIndex] };
      this.steps[nextIndex] = item;
      this.steps[index] = nextItemCopy;
    },
    RepeaterDelete(index: number) {
      this.selectedIndexToDelete = index;
      this.showDeleteDialog = true;
    },
    cancelDeletePopup() {
      this.selectedIndexToDelete = null;
      this.showDeleteDialog = false;
    },
    deleteStepItem() {
      if (this.selectedIndexToDelete != null) {
        this.steps.splice(this.selectedIndexToDelete, 1);
        this.cancelDeletePopup();
      }
    },
    async loadAssessments() {
      this.assessments = await AssessmentService.GetList();
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    async loadDependencies() {
      this.dependencies = await CourseService.GetCoursesName();
    },
    async loadTagsList() {
      this.tags = await TagService.GetTagsByType("Course");
    },
    cancel() {
      this.$router.push("/client/training/courses");
    },
    async saveCourse() {
      const isValid = await (this.$refs.frmMain as any).validate();
      if (isValid.valid) {
        this.isLoading = true;
        try {
          this.courseModel.Tags = [];
          this.courseModel.Dependencies = [];
          this.steps.forEach((step, index) => {
            step.Order = index;
          });
          this.courseModel.Steps = this.steps;
          this.SelectedTags.forEach((tag) => {
            this.courseModel.Tags.push({ Title: tag, Type: TagType.Course });
          });

          this.SelectedDependecy.forEach((dependency) => {
            if (typeof dependency == "string") {
              this.courseModel.Dependencies.push({ Text: dependency });
            } else {
              this.courseModel.Dependencies.push({
                DependencyId: (dependency as any).Id,
              });
            }
          });
          const response = await CourseService.CreateOrEdit(this.courseModel);
          const selectedImageFile = this.selectedImage[0];
          if (
            response.ImageUploadUrl != null &&
            response.ImageUploadUrl != undefined &&
            selectedImageFile != null
          ) {
            await UploadService.uploadToContainer(
              selectedImageFile,
              response.ImageUploadUrl.Url
            );
            this.courseModel.Image = response.ImageUploadUrl?.FileName ?? "";
            this.courseModel.Id = response.Id;
            await CourseService.CreateOrEdit(this.courseModel);
            this.SET_SNAKBAR_MODEL({
              body: this.courseId
                ? "Course Updated Successfuly"
                : "Course Created Successfuly",
              status: "success",
              button: "ok",
              timeout: Profile.ErrorSetting.SnackbarTimeout,
              show: true,
              handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
            });
          }
          this.isLoading = false;
          this.$router.push("/client/training/courses");
        } catch (error) {
          this.isLoading = false;
          this.SET_SNAKBAR_MODEL({
            body: "There is an Issue on creating the course",
            status: "error",
            button: "ok",
            timeout: Profile.ErrorSetting.SnackbarTimeout,
            show: true,
            handler: (snakbarId: any) => this.REMOVE_SNAKBAR(snakbarId),
          });
        }
      }
    },
    AddLesson() {
      this.steps.push({
        Type: "Lesson",
      } as CourseStep);
    },
    AddAssessment() {
      this.steps.push({
        AssessmentDetails: {} as AssessmentModel,
        Type: "Assessment",
      } as CourseStep);
    },
  },
  computed: {
    getDependencies() {
      let list = this.dependencies.map((item: any) => {
        return { Id: item.Id, Title: item.Title };
      });
      if (this.courseId) {
        const currentCourseIndex = list.findIndex(
          (item: any) => item.Id == this.courseId
        );
        if (currentCourseIndex != -1) {
          list.splice(currentCourseIndex, 1);
        }
      }

      return list;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/color.scss";

.card {
  border-radius: 8px;

  .repeater {
    display: flex;
    margin-bottom: 10px;

    .order-btns {
      padding: 5px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      * {
        cursor: pointer;
      }
    }


  }

  .divider {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 18px;
  }

  .divider-line {
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .course-image {
    max-height: 230px;
  }

  .edit-image-container {
    position: relative;
    .course-image-replace {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $c_white;
      color: $c_battleship_grey1;
      height: 35px;
      width: 35px;
      display: flex;
      padding: 5px;
      ::v-deep {
        .v-input__control {
          display: none;
        }
      }
    }
  }

  .lesson-card {
    width: 100%;
  }
}
</style>
