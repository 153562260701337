<template>
  <v-form ref="frmEpicMain" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title> Add an epic to {{ projectName }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <TextField 
              label="Epic" 
              density="compact"
              v-model="epic"
              :rules="[rules.required]"/>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="v-card-actions">
        <v-col cols="12" md="12">
          <v-btn class="right" color="secondary" @click="save" :loading="loading" :disabled="loading"><v-icon>mdi-check</v-icon>Add</v-btn>
          <v-btn variant="text" color="accent" class="right mr-5" @click="cancel" :disabled="loading">cancel</v-btn>
        </v-col>
      </div>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import rules from "shared-components/src/utils/validations";
import { defineComponent } from "vue";

export default defineComponent({
    props:["projectName", "projectId", "epicLoading"],
    data(){
      return {
        rules,
        valid: false,
        epic: "",
        loading: false,
      }
    },
    methods: {
    cancel() {
      this.$emit("cancel");
    },
    async save() {
      this.loading = true;
      const isValid = await (this.$refs.frmEpicMain as any).validate()
      if(isValid.valid){
        this.$emit("success", {epic: this.epic, projectId: this.projectId});
      }
      this.loading = false;
    }
  },
    watch:{
    epicLoading(newVal){
      this.loading = newVal;
    }
  }
})

</script>