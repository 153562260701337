<template>
  <div>
    <v-card :disabled="loading">
      <LastTwelveMonth @filterDataByDate="filterDataByDate" />
    </v-card>

    <v-card :loading="loading">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="5" sm="12" xs="12">
            <ProjectTreeReport
              :projects="filteredProjectsToShowInTree"
              :filteredTimesheets="filteredTimesheetsByDates"
              @filterSummaries="filterSummaries"
            />
          </v-col>
          <v-col
            class="emptyResult d-flex justify-center mb-6"
            cols="12"
            md="7"
            sm="12"
            xs="12"
            v-if="
              !loading &&
              !projectMessage &&
              (!activityReport || activityReport.length === 0) &&
              (!technologyReport || technologyReport.length === 0)
            "
          >
            <h1 class="pa-md-16 ma-16">{{ chartMessage }}</h1>
          </v-col>
          <v-col cols="12" md="7" sm="12" xs="12">
            <TimesheetChartReport
              :activities="activityReport"
              :technologies="technologyReport"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <div
          v-if="
            !loading &&
            (!this.filteredProjects || this.filteredProjects.length === 0)
          "
          class="emptyResult d-flex justify-center mb-6"
        >
          <h1 class="pa-md-16 ma-16">{{ projectMessage }}</h1>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ProjectTreeReport from "@/components/Report/ProjectTreeReport.vue";
import Project from "shared-components/src/models/Project";
import Timesheet from "shared-components/src/models/Timesheet";
import {
  ActivityReport,
  TechnologyReport,
} from "shared-components/src/models/ProjectLeadReport";
import TimesheetChartReport from "@/components/Report/TimesheetChartReport.vue";
import LastTwelveMonth from "@/components/Range/LastTwelveMonth.vue";
import PortfolioManagerService from "../../services/PortfolioManagerService";
import { ProjectMessages } from "shared-components/src/definitions/constants";
interface Month {
  startDate: Date;
  endDate: Date;
}
interface ProjectFilter {
  id: string;
  epics: string[];
}
export default defineComponent({
  data() {
    return {
      projects: [] as Project[],
      filteredProjects: [] as Project[],
      filteredProjectsToShowInTree: [] as Project[],
      activityReport: [] as ActivityReport[],
      technologyReport: [] as TechnologyReport[],
      timesheets: [] as Timesheet[],
      filteredTimesheetsByDates: [] as Timesheet[],
      loading: false,
      selectedDates: [] as Month[],
      selectedProjects: [] as ProjectFilter[],
      projectMessage: "",
      chartMessage: ProjectMessages.NoChartDetailInDates,
    };
  },
  components: {
    ProjectTreeReport,
    TimesheetChartReport,
    LastTwelveMonth,
  },
  methods: {
    showNoData() {
      this.projectMessage = "";
      if (!this.filteredProjects || this.filteredProjects.length === 0) {
        this.projectMessage = ProjectMessages.NoProjectsInDates;
      }
    },
    calculateActivities() {
      let activities = this.filteredTimesheetsByDates
        .filter((c) => c.ActivityId && c.Activity)
        .map((c) => {
          return {
            id: c.ActivityId,
            name: c.Activity,
            netEffort: c.Effort + c.Adjustment,
          } as ActivityReport;
        });
      this.activityReport = activities.reduce((ac: ActivityReport[], a) => {
        let ind = ac.findIndex((x) => x.id === a.id);
        ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
        return ac;
      }, []);
    },
    calculateTechnologies() {
      let technologies = this.filteredTimesheetsByDates
        .filter((c) => c.TechnologyId && c.Technology)
        .map((c) => {
          return {
            id: c.TechnologyId,
            name: c.Technology,
            netEffort: c.Effort + c.Adjustment,
          } as TechnologyReport;
        });
      this.technologyReport = technologies.reduce(
        (ac: TechnologyReport[], a) => {
          let ind = ac.findIndex((x) => x.id === a.id);
          ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
          return ac;
        },
        []
      );
    },
    filterSummaries(selectedProjects: any) {
      if (!selectedProjects || selectedProjects.length === 0) {
        this.selectedProjects = [] as ProjectFilter[];
      } else {
        var projects = [] as ProjectFilter[];
        selectedProjects.projects.forEach((element: any) => {
          var selectedEpics = element.Epics.filter((c: any) => c.isClicked).map(
            (c: any) => c.name
          );
          projects.push({ id: element.id, epics: selectedEpics });
        });
        this.selectedProjects = projects;
      }
    },
    async filterDataByDate(selectedFilters: any) {
      this.loading = true;
      this.activityReport = [] as ActivityReport[];
      this.technologyReport = [] as TechnologyReport[];
      this.filteredTimesheetsByDates = [] as Timesheet[];
      this.filteredProjects = [] as Project[];
      this.filteredProjectsToShowInTree = [] as Project[];
      if (selectedFilters.startDate) {
        var report = await PortfolioManagerService.getList(
          selectedFilters.startDate,
          selectedFilters.endDate
        );
        this.projects = report.projects;
        this.filteredProjects = report.projects;
        this.filteredProjectsToShowInTree = report.projects;
        this.timesheets = report.timesheets;
        this.filteredTimesheetsByDates = report.timesheets;
        this.activityReport = report.activityReport;
        this.technologyReport = report.technologyReport;
        this.selectedDates = [selectedFilters];
      } else {
        if (!selectedFilters || selectedFilters.length === 0) {
          this.selectedDates = [] as Month[];
        } else {
          this.selectedDates = selectedFilters.map((c: any) => {
            return { startDate: c.startDate, endDate: c.endDate };
          });
        }
      }
      this.loading = false;
      this.showNoData();
    },
    filterTimeSheets(keepProjects = false) {
      this.selectedDates.forEach((c) => {
        c.endDate.setHours(0, 0, 0, 0);
      });
      return this.timesheets.filter((item) => {
        let exist = true;
        if (this.selectedDates && this.selectedDates.length > 0) {
          exist = this.selectedDates.some(
            (c) =>
              item.Date && c.startDate <= item.Date && c.endDate >= item.Date
          );
          if (!exist) {
            return;
          }
        }
        if (
          !keepProjects &&
          this.selectedProjects &&
          this.selectedProjects.length > 0
        ) {
          exist = this.selectedProjects.some(
            (c) =>
              item.Epic &&
              c.id === item.ProjectId &&
              c.epics.includes(item.Epic)
          );
          if (!exist) {
            return;
          }
        }
        if (exist) {
          return item;
        }
      });
    },
    
    getFilterProjects(filteredTimesheets: Timesheet[]) {
      var projectIds = filteredTimesheets.map((c) => c.ProjectId);
      var uniqueProjectIds = [...new Set(projectIds)];
      var projects = this.projects.filter((c) =>
        uniqueProjectIds.includes(c.id)
      );
      projects.forEach((c) => {
        var projectEpics = filteredTimesheets
          .filter((t) => t.ProjectId === c.id)
          .map((e: any) => e.Epic);
        c.Epics = [...new Set(projectEpics)];
      });

      return projects;
    },
  },
  watch: {
    selectedProjects(newVal: ProjectFilter[]) {
      this.filteredTimesheetsByDates = this.filterTimeSheets();
      this.calculateActivities();
      this.calculateTechnologies();
    },
    selectedDates(newVal: Month[]) {
      this.filteredTimesheetsByDates = this.filterTimeSheets();

      // filtered projects to use in calculations
      this.filteredProjects = this.getFilterProjects(
        this.filteredTimesheetsByDates
      );

      // filtered projects to use in calculations
      this.filteredProjectsToShowInTree = this.getFilterProjects(
        this.filterTimeSheets(true)
      );
      this.calculateActivities();
      this.calculateTechnologies();
      this.showNoData();
    },
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/sizes.scss";
.transparent {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.emptyResult h1 {
  text-align: center;
  line-height: $normal;
}
</style>
