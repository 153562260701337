<template>
  <div>
    <v-card :loading="loading" :disabled="loading">
      <v-row>
        <v-col md="4" sm="12" xs="12">
          <DateRange :offset="dayOffset" @fetchData="fetchData" />
        </v-col>
        <v-col md="8" sm="12" xs="12" cols="12" v-if="filterItems.length">
          <div class="filters">
            <span>
              Filters:
              <div
                v-for="tag in filterItems"
                :key="tag.title"
                class="filter-item"
              >
                {{ tag.title }}
                <v-icon class="clearChartFilter" @click="tag.removeFunction"
                  >mdi-close-circle</v-icon
                >
              </div>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pt-0 transparent" :loading="tableLoading">
      <div v-if="!tableLoading">
        <v-card-text
          v-if="!tableLoading && teammembers && teammembers.length > 0"
        >
          <v-row class="teammember-section">
            <v-col
              class="teammember-section-item pl-3 pr-3 mt-3"
              cols="12"
              md="3"
              sm="6"
              xs="12"
              v-for="(teammember, teammemberIndex) in filteredTeammembers"
              :key="`tmIndex_${teammemberIndex}_${teammemberViewVersion}`"
            >
              <div class="rounded-lg dataBody auto-height pl-3">
                <v-row>
                  <v-col md="3"
                    ><ProfileAvatar
                      :key="`tmAvatar_${teammemberIndex}_${teammemberViewVersion}`"
                      :users="[teammember]"
                      :justify="'left'"
                      class="profile-avatar"
                      @selectUser="selectUser"
                  /></v-col>
                  <v-col md="9"
                    ><span
                      ><strong>{{ teammember.fullName }}</strong></span
                    ><br /><span v-tooltip="'Timesheet Total'"
                      >{{
                        calculateBillableForTeammember(teammember.id)
                      }}
                      Hour(s)</span
                    ><br />
                    <span v-tooltip="'Total of Commits'"
                      >{{
                        getTeammemberCommitsTotal(teammember.id)
                      }}
                      Line(s)</span>
                    <br />
                    <span v-if="getVmUsage(teammember.id)" v-tooltip="'Virtual Desktop Usage Total'"
                      >{{
                        getVmUsage(teammember.id)
                      }}
                      Hour(s)</span
                    >
                    </v-col
                  >
                </v-row>
              </div>
              <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="ml1 ma-0 pa-0" md="3" sm="6" xs="12">
              <v-card-text>
                <div class="pl-3 pr-3 rounded-lg dataBody">
                  <v-row>
                    <v-col md="10"><h3 class="ml-3">Projects</h3></v-col>
                    <v-col md="2" class="text-center"> </v-col
                  ></v-row>

                  <v-col md="12">
                    <Pie
                      :data="projectChartData"
                      :options="getChartOptions(`project`)"
                    />
                  </v-col>
                </div>
              </v-card-text>
            </v-col>
            <v-col class="ml1 ma-0 pa-0" md="3" sm="6" xs="12">
              <v-card-text>
                <div class="pl-3 pr-3 rounded-lg dataBody">
                  <v-row>
                    <v-col md="10"><h3 class="ml-3">Epics</h3></v-col>
                    <v-col md="2" class="text-center"> </v-col
                  ></v-row>

                  <v-col md="12">
                    <Pie
                      :data="epicChartData"
                      :options="getChartOptions(`epic`)"
                    />
                  </v-col>
                </div>
              </v-card-text>
            </v-col>
            <v-col class="ml1 ma-0 pa-0" md="3" sm="6" xs="12">
              <v-card-text>
                <div class="pl-3 pr-3 rounded-lg dataBody">
                  <v-row>
                    <v-col md="10"><h3 class="ml-3">Activities</h3></v-col>
                    <v-col md="2" class="text-center"> </v-col
                  ></v-row>

                  <v-col md="12">
                    <Pie
                      :data="acChartData"
                      :options="getChartOptions(`activity`)"
                    />
                  </v-col>
                </div>
              </v-card-text>
            </v-col>
            <v-col class="ml1 ma-0 pa-0 pr-2" md="3" sm="6" xs="12">
              <v-card-text>
                <div class="pl-3 pr-3 rounded-lg dataBody">
                  <v-row
                    ><v-col md="10"><h3 class="ml-3">Technologies</h3></v-col>
                    <v-col md="2" class="text-center"> </v-col
                  ></v-row>

                  <v-col md="12">
                    <Pie
                      :data="techChartData"
                      :options="getChartOptions(`technology`)"
                  /></v-col>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
          <div style="clear: both" />
          <v-row v-if="clickedTeamMember">
            <v-col md="12" sm="12" cols="12" class="pl-6 pr-6 mt-5">
              <v-row>
                <v-col class="box-header" md="12" sm="12" cols="12">
                  <v-row>
                    <v-col md="4" sm="4" cols="12" class="text-center">
                      <strong>
                        GRAND TOTAL HOURS:
                        <span class="totalcalc">{{
                          removeDecimals(this.grandTotalHour)
                        }}</span>
                      </strong>
                    </v-col>
                    <v-col md="4" sm="4" cols="12" class="text-center">
                      <strong>
                        GRAND TOTAL ADJUSTMENT:
                        <span class="totalcalc">{{
                          removeDecimals(this.grandTotalAdjustment)
                        }}</span>
                      </strong>
                    </v-col>
                    <v-col md="4" sm="4" cols="12" class="text-center">
                      <strong>
                        GRAND TOTAL BILLABLE:
                        <span class="totalcalc">{{
                          removeDecimals(this.grandTotalBillable)
                        }}</span>
                      </strong>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="12" sm="12" cols="12" class="pl-6 pr-6 mt-5">
              <v-row>
                <v-col class="box-header" md="12" sm="12" cols="12">
                  <strong>Warnings</strong>
                </v-col>
                <v-col md="12" sm="12" cols="12">
                  <div
                    v-for="(item, index) in getTeammemberTimesheetIssues(
                      clickedTeamMember.id
                    )"
                    :key="`git-err-${index}`"
                  >
                    <v-alert
                      border="right"
                      colored-border
                      type="warning"
                      elevation="2"
                    >
                      {{ item }}</v-alert
                    >
                    <br />
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="12" sm="12" cols="12" class="pl-6 pr-6 mt-5">
              <v-row>
                <v-col class="box-header" md="12" sm="12" cols="12">
                  <strong>Weekly Performance Report</strong>
                </v-col>
                <v-col md="12" sm="12" cols="12">
                  <weekly-performance-report
                    :key="`wpr_${weeklyPerformanceReportVersion}`"
                    :teammember="clickedTeamMember"
                    :timesheets="
                      getTeammemberFilteredTimesheets(clickedTeamMember.id)
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col md="12" sm="12" xs="12">
              <v-col
                class="mt-2"
                md="12"
                sm="12"
                cols="12"
                v-for="(listItem, index) in teammemberReport"
                :key="'row_' + index"
              >
                <v-row
                  class="mb-10 timesheetreport-body"
                  v-for="(weekItems, weekIndex) in listItem.weeklyReport"
                  :key="'week_' + index + weekIndex"
                >
                  <v-col
                    class="header mb-1 timesheetreport-item"
                    md="12"
                    sm="12"
                    cols="12"
                  >
                    <v-row>
                      <v-col md="9" sm="9" cols="9">
                        <h3>Timesheet Report Week {{ weekItems.week }}</h3>
                      </v-col>
                      <v-col md="3" sm="3" cols="3" class="text-right">
                        <h3>
                          {{ listItem.teammemberName }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12" sm="12" cols="12" class="timesheetreport-item">
                    <v-row>
                      <v-col
                        class="rowItem"
                        md="12"
                        sm="12"
                        cols="12"
                        v-for="(
                          timesheetItem, timesheetIndex
                        ) in weekItems.timesheets"
                        :key="'timesheet_' + index + weekIndex + timesheetIndex"
                      >
                        <v-row
                          v-bind:style="[
                            timesheetIndex === 0
                              ? { 'margin-top': '-10px' }
                              : { 'margin-top': '-15px' },
                          ]"
                          v-if="
                            timesheetIndex === 0 ||
                            formatDate(
                              weekItems.timesheets[timesheetIndex].Date
                            ) !=
                              formatDate(
                                weekItems.timesheets[timesheetIndex - 1].Date
                              )
                          "
                        >
                          <v-col
                            md="2"
                            sm="2"
                            cols="2"
                            class="text-center titles"
                          >
                            <span class="text-subtitle-1"
                              ><strong>{{
                                timesheetItem.Date.toDateString()
                              }}</strong></span
                            >
                          </v-col>
                        </v-row>
                        <v-row class="timesheet-item">
                          <v-col
                            md="2"
                            sm="2"
                            cols="2"
                            class="text-center datas text-flex-container"
                          >
                            <span class="content">
                              <span class="text-h6">
                                {{ timesheetItem.IssueNo }}</span
                              ><br />
                              <span class="text-subtitle-1"
                                >{{
                                  removeDecimals(timesheetItem.Effort)
                                }}H</span
                              ><br />
                              <span
                                class="text-subtitle-1"
                                v-bind:style="[
                                  timesheetItem.Adjustment > 0
                                    ? { color: '#4caf50' }
                                    : { color: 'red' },
                                ]"
                                v-if="timesheetItem.Adjustment"
                                >(<span v-if="timesheetItem.Adjustment > 0"
                                  >+</span
                                >{{
                                  removeDecimals(timesheetItem.Adjustment)
                                }}H)</span
                              ></span
                            >
                          </v-col>
                          <v-col md="4" sm="4" cols="4" class="text-left datas">
                            <span
                              ><strong
                                ><v-icon title="Project">mdi-briefcase </v-icon
                                >:</strong
                              >
                              {{ timesheetItem.Project.Name }}</span
                            ><br />
                            <span
                              ><strong
                                ><v-icon title="Epic">mdi-cone</v-icon>:</strong
                              >
                              {{ timesheetItem.Epic }}</span
                            ><br />
                            <span
                              ><strong
                                ><v-icon title="Activity"
                                  >mdi-cog-outline</v-icon
                                >:</strong
                              >
                              {{ timesheetItem.Activity }}</span
                            ><br />
                            <span
                              ><strong
                                ><v-icon title="Technology">mdi-lan</v-icon
                                >:</strong
                              >
                              {{ timesheetItem.Technology }}</span
                            ><br />
                          </v-col>
                          <v-col md="6" sm="6" cols="6" class="datas">
                            <span>{{ timesheetItem.Description }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
          <div
            v-if="
              !tableLoading &&
              (!teammemberReport || teammemberReport.length === 0)
            "
            class="emptyResult d-flex justify-center mb-6"
          >
            <h1 class="pa-md-16 ma-16">{{ message }}</h1>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
  <SighteFormsScript />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Utils from "@/Helpers/Utils";
import {
  DarkThemeColors,
  ProjectMessages,
} from "shared-components/src/definitions/constants";
import { ProjectLeadTeammember } from "shared-components/src/models/ProjectLeadTeammember";
import DateRange from "@/components/Range/DateRange.vue";
import Timesheet from "shared-components/src/models/Timesheet";
import ProfileAvatar from "@/components/Profile/ProfileAvatar.vue";
import TimesheetChartReport from "@/components/Report/TimesheetChartReport.vue";
import ProjectLeadReportService from "@/services/ProjectLeadReportService";
import TimesheetService from "@/services/TimesheetService";
import TeammemberService from "@/services/TeammemberService";
import ProjectLeadReport, {
  ActivityReport,
  TechnologyReport,
  EpicReport,
  ProjectReport,
  TeammemberVMReport,
} from "shared-components/src/models/ProjectLeadReport";
import AppHelper from "shared-components/src/utils/AppHelper";
import RepositoryTeammemberCommit from "shared-components/src/viewModels/response/repository/RepositoryTeammemberCommit";
import RepositoryGitInsightRequest from "shared-components/src/viewModels/request/Repository/RepositoryGitInsightRequest";
import SighteFormsScript from "@/components/Misc/SighteFormsScript.vue";
import { Pie } from "vue-chartjs";
import { PieChart } from "shared-components/src/models/PieChart";
import WeeklyPerformanceReport from "./WeeklyPerformanceReport.vue";

interface FilterItem {
  title: string;
  removeFunction: Function;
}

interface TeammemberReport {
  teammemberName: string | null;
  teammemberId: string | null;
  weeklyReport: WeeklyReport[];
}
interface WeeklyReport {
  week: string;
  timesheets: Timesheet[];
}

export interface UiTeammember extends ProjectLeadTeammember {
  isClicked: boolean | false;
}
export default defineComponent({
  async mounted() {
    this.loading = true;
    var teammemberList = await TeammemberService.getActiveTeammembers();
    this.allTeammembers = teammemberList as UiTeammember[];
  },
  components: {
    ProfileAvatar,
    TimesheetChartReport,
    DateRange,
    SighteFormsScript,
    Pie,
    WeeklyPerformanceReport,
  },
  data() {
    return {
      weeklyPerformanceReportVersion: 0,
      startDate: {} as Date,
      endDate: {} as Date,
      clickedTeammembers: [] as string[],
      teammemberSelected: false,
      dataFilled: false,
      teammemberVMReports: [] as TeammemberVMReport[],
      activityReport: [] as ActivityReport[],
      technologyReport: [] as TechnologyReport[],
      projectLeadReport: {} as ProjectLeadReport,
      epicReport: [] as EpicReport[],
      projectReport: [] as ProjectReport[],
      allTeammembers: [] as UiTeammember[],
      teammembers: [] as UiTeammember[],
      filteredTeammembers: [] as UiTeammember[],
      teammemberViewVersion: 0,
      timesheetReport: [] as Timesheet[],
      filteredTimesheetReport: [] as Timesheet[],
      teammemberReport: [] as TeammemberReport[],
      weeklyReport: [] as WeeklyReport[],
      loading: false,
      grandTotalHour: 0,
      grandTotalAdjustment: 0,
      grandTotalBillable: 0,
      tableLoading: false,
      dayOffset: -1,
      message: "",
      acChartData: {} as PieChart,
      techChartData: {} as PieChart,
      epicChartData: {} as PieChart,
      projectChartData: {} as PieChart,
      selectedActivity: "",
      selectedTechnology: "",
      selectedEpic: "",
      selectedProject: "",
      commit: {
        list: [] as RepositoryTeammemberCommit[],
        totalAdded: 0,
        totalModified: 0,
        totalDeleted: 0,
      },
    };
  },
  watch: {
    clickedTeammembers() {
      this.filterReport();
    },
    selectedEpic(newVal) {
      this.filterReport();
    },
    selectedProject(newVal) {
      this.filterReport();
    },
    selectedTechnology(newVal) {
      this.filterReport();
    },
    selectedActivity(newVal) {
      this.filterReport();
    },
  },
  methods: {
    renderActivityReportChart(newVal: any) {
      var newData = newVal.filter((c: any) => c.name);
      this.activityReport = newData;
      var labels = newData.map((c: any) => c.name);
      var data = newData.map((c: any) => c.netEffort);
      var backgroundColor = newData.map((c: any) => {
        return AppHelper.GetColour(c.name);
      });
      this.acChartData = this.renderChart(labels, data, backgroundColor);
    },
    renderTechnologyReportChart(newVal: any) {
      var newData = newVal.filter((c: any) => c.name);
      this.technologyReport = newData;
      var labels = newData.map((c: any) => c.name);
      var data = newData.map((c: any) => c.netEffort);
      var backgroundColor = newData.map((c: any) => {
        return AppHelper.GetColour(c.name);
      });
      this.techChartData = this.renderChart(labels, data, backgroundColor);
    },
    renderEpicReportChart(newVal: any) {
      var newData = newVal.filter((c: any) => c.name);
      this.epicReport = newData;
      var labels = newData.map((c: any) => c.name);
      var data = newData.map((c: any) => c.netEffort);
      var backgroundColor = newData.map((c: any) => {
        return AppHelper.GetColour(c.name);
      });
      this.epicChartData = this.renderChart(labels, data, backgroundColor);
    },
    renderProjectReportChart(newVal: any) {
      var newData = newVal.filter((c: any) => c.name);
      this.projectReport = newData;
      var labels = newData.map((c: any) => c.name);
      var data = newData.map((c: any) => c.netEffort);
      var backgroundColor = newData.map((c: any) => {
        return AppHelper.GetColour(c.name);
      });
      this.projectChartData = this.renderChart(labels, data, backgroundColor);
    },
    getTeammemberTimesheetIssues(teammemberId: string) {
      return TimesheetService.TimesheetReviewResultForTeammember(
        this.getTeammemberTimesheets(teammemberId),
        this.getTeammemberCommits(teammemberId)
      );
    },
    getTeammemberCommitsTotal(teammemberId: string) {
      var commits = this.getTeammemberCommits(teammemberId);
      return commits
        .map((item) => item.linesChanged)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
    getVmUsage(teammemberId: string) {
      return this.teammemberVMReports?.find(x => x.tmId == teammemberId)?.netEffort ?? null;
    },
    getTeammemberCommits(teammemberId: string) {
      return this.commit.list.filter((cm) => cm.TeamMemberId === teammemberId);
    },
    getTeammemberNameById(teammemberId: string): UiTeammember | null {
      const tm = this.teammembers.find((tm) => tm.id === teammemberId);
      return tm as UiTeammember | null;
    },
    getTeammemberFilteredTimesheets(teammemberId: string) {
      return this.filteredTimesheetReport.filter(
        (tr) => tr.TeamMemberId === teammemberId
      );
    },
    getTeammemberTimesheets(teammemberId: string) {
      return this.timesheetReport.filter(
        (tr) => tr.TeamMemberId === teammemberId
      );
    },
    async setCommitListByProjectLead() {
      try {
        this.commit.list = [];
        const model = {
          EndDate: this.endDate,
          StartDate: this.startDate,
          TeammemberIds: this.teammembers.map((x) => x.id),
        } as RepositoryGitInsightRequest;
        this.commit.list =
          await ProjectLeadReportService.GetCommitListByProjectLead(model);
        let totalAdded = 0;
        let totalModified = 0;
        let totalDeleted = 0;
        if (this.commit.list) {
          this.commit.list.forEach((commit) => {
            totalAdded += commit.linesAdded;
            totalModified += commit.linesChanged;
            totalDeleted += commit.linesDeleted;
          });
        }

        this.commit.totalAdded = totalAdded;
        this.commit.totalModified = totalModified;
        this.commit.totalDeleted = totalDeleted;
      } catch (error: any) {
        const errorMessage = error && error.response && error.response.data;
        console.log(errorMessage);
      }
    },
    getChartOptions(chartType: string) {
      let onClick = this.handleEpicChartClick;
      switch (chartType) {
        case "epic":
          onClick = this.handleEpicChartClick;
          break;
        case "activity":
          onClick = this.handleActivityChartClick;
          break;
        case "technology":
          onClick = this.handleTechnologyChartClick;
          break;
        case "project":
          onClick = this.handleProjectChartClick;
          break;
      }
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick,
        plugins: {
          legend: {
            display: false,
            position: "bottom",
            align: "start",
            labels: {
              color: DarkThemeColors.Primary,
            },
          },
        },
      };
    },
    handleEpicChartClick(event: any, chartElements: any) {
      this.selectedEpic = this.handleChartClick(
        event,
        chartElements,
        this.epicChartData
      );
    },
    handleProjectChartClick(event: any, chartElements: any) {
      var selectedLabel = this.handleChartClick(
        event,
        chartElements,
        this.projectChartData
      );
      this.selectedProject = this.getProjectIdByName(selectedLabel);
    },
    getTechnologyIdByName(technologyName: string) {
      const technology = this.technologyReport.find(
        (x) => technologyName && x.name === technologyName
      );
      return technology ? technology!.id : "";
    },
    getTechnologyNameById(technologyId: string) {
      const technology = this.technologyReport.find(
        (x) => technologyId && x.id === technologyId
      );
      return technology ? technology!.name : "";
    },
    getProjectIdByName(ProjectName: string) {
      const project = this.projectReport.find(
        (x) => ProjectName && x.name === ProjectName
      );
      return project ? project!.id : "";
    },
    getProjectNameById(ProjectId: string) {
      const project = this.projectReport.find(
        (x) => ProjectId && x.id === ProjectId
      );
      return project ? project!.name : "";
    },
    handleTechnologyChartClick(event: any, chartElements: any) {
      var selectedLabel = this.handleChartClick(
        event,
        chartElements,
        this.techChartData
      );
      this.selectedTechnology = this.getTechnologyIdByName(selectedLabel);
    },
    getActivityIdByName(activityName: string) {
      const activity = this.activityReport.find(
        (x) => activityName && x.name === activityName
      );
      return activity ? activity!.id : "";
    },
    getActivityNameById(activityId: string) {
      const activity = this.activityReport.find(
        (x) => activityId && x.id === activityId
      );
      return activity ? activity!.name : "";
    },
    handleActivityChartClick(event: any, chartElements: any) {
      var selectedLabel = this.handleChartClick(
        event,
        chartElements,
        this.acChartData
      );
      this.selectedActivity = this.getActivityIdByName(selectedLabel);
    },
    handleChartClick(event: any, chartElements: any, chartData: PieChart) {
      if (chartElements.length != 0) {
        var position = chartElements[0].index;
        return chartData.labels[position];
      } else {
        return "";
      }
    },
    clearSelectedTeammemberAndFillAll(selectedTeammemberId: string = "") {
      this.clickedTeammembers = this.teammembers
        .filter((tm) => !selectedTeammemberId || tm.id == selectedTeammemberId)
        .map((c) => c.id);

      if (this.filteredTeammembers) {
        this.filteredTeammembers.forEach((tm) => {
          tm.isClicked = false;
        });
      }
    },
    clearSelectedEpic() {
      this.selectedEpic = "";
    },
    clearSelectedActivity() {
      this.selectedActivity = "";
    },
    clearSelectedTechnology() {
      this.selectedTechnology = "";
    },
    clearSelectedProject() {
      this.selectedProject = "";
    },
    renderChart(labels: string[], datas: string[], backgroundcolors: string[]) {
      var newConfigs = {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundcolors,
            data: datas,
            hoverOffset: 4,
          },
        ],
      };
      return newConfigs as PieChart;
    },
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    calculateTotalHours(list: any) {
      const timesheets = list
        .filter((c: any) => c.timesheets)
        .map((item: any) => item.timesheets);
      list = [];
      timesheets.forEach((c: any) => {
        if (c) {
          list = list.concat(c);
        }
      });
      return this.calculateHours(list);
    },
    calculateTotalAdjustment(list: any) {
      const timesheets = list
        .filter((c: any) => c.timesheets)
        .map((item: any) => item.timesheets);
      list = [];
      timesheets.forEach((c: any) => {
        if (c) {
          list = list.concat(c);
        }
      });
      return this.calculateAdjustment(list);
    },
    calculateTotalBillable(list: any) {
      const timesheets = list
        .filter((c: any) => c.timesheets)
        .map((item: any) => item.timesheets);
      list = [];
      timesheets.forEach((c: any) => {
        if (c) {
          list = list.concat(c);
        }
      });
      return this.calculateBillable(list);
    },

    calculateHours(list: Timesheet[]) {
      if (list && list.length > 0) {
        const timesheetList = list.filter(
          (c) => c.ProjectId && (c.Activity || c.ActivityId) && c.Epic
        );
        if (timesheetList && timesheetList.length > 0) {
          let totalHours = timesheetList
            .map((item) => item.Effort)
            .reduce((prev, next) => prev + next);
          totalHours = this.removeDecimals(totalHours);
          return totalHours;
        }
      }
      return 0;
    },
    calculateAdjustment(list: Timesheet[]) {
      if (list && list.length > 0) {
        const timesheetList = list.filter(
          (c) => c.ProjectId && (c.Activity || c.ActivityId) && c.Epic
        );
        if (timesheetList && timesheetList.length > 0) {
          let totalAdjustment = timesheetList
            .map((item) => item.Adjustment)
            .reduce((prev, next) => prev + next);
          totalAdjustment = this.removeDecimals(totalAdjustment);
          return totalAdjustment;
        }
      }
      return 0;
    },
    calculateBillableForTeammember(teammemberId: string): number {
      if (!this.teammemberReport) return 0;
      var tmListItem = this.teammemberReport.find(
        (x) => x.teammemberId == teammemberId
      );
      if (!tmListItem || !tmListItem.weeklyReport) return 0;
      return this.calculateTotalBillable(tmListItem.weeklyReport);
    },
    calculateBillable(list: Timesheet[]) {
      if (list && list.length > 0) {
        const timesheetList = list.filter(
          (c) => c.ProjectId && (c.Activity || c.ActivityId) && c.Epic
        );
        if (timesheetList && timesheetList.length > 0) {
          let totalHours = timesheetList
            .map((item) => item.Effort)
            .reduce((prev, next) => prev + next);
          let totalAdjustment = timesheetList
            .map((item) => item.Adjustment)
            .reduce((prev, next) => prev + next);
          totalHours = this.removeDecimals(totalHours);
          totalAdjustment = this.removeDecimals(totalAdjustment);
          let totalBillable = totalHours + totalAdjustment;
          totalBillable = this.removeDecimals(totalBillable);
          return totalBillable;
        }
      }
      return 0;
    },

    removeDecimals(value: any) {
      return AppHelper.removeDecimals(value);
    },
    getDatesBetweenDates() {
      let dates = [] as Date[];
      const theDate = new Date(this.startDate);
      while (theDate < this.endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
      }
      dates = [...dates, this.endDate];
      return dates;
    },
    getStartandEndOfWeek() {
      const firstday = Utils.toVsDateFormat(this.startDate);
      const lastday = Utils.toVsDateFormat(this.endDate);
      return `${firstday} ~ ${lastday}`;
    },
    getTeammemberTimesheetInWeek(teammemberId: any) {
      const timesheetsInWeek = [] as WeeklyReport[];

      this.weeklyReport.forEach((wr) => {
        const timesheetList = this.filteredTimesheetReport.filter(
          (tr) =>
            tr.TeamMemberId === teammemberId &&
            tr.Date &&
            tr.Date >= this.startDate &&
            tr.Date <= this.endDate
        );
        timesheetList.forEach((c) => {
          c.Effort = this.removeDecimals(c.Effort);
          if (c.ProjectId && (c.Activity || c.ActivityId) && c.Epic) {
            this.grandTotalHour += this.removeDecimals(c.Effort);
            this.grandTotalAdjustment += this.removeDecimals(c.Adjustment);
          }
        });
        timesheetsInWeek.push({ week: wr.week, timesheets: timesheetList });
      });
      return timesheetsInWeek;
    },
    async fetchTimesheetReport(selectedTeammemberId: string = "") {
      if (!selectedTeammemberId) {
        this.teammemberSelected = false;
      }
      this.activityReport = [] as ActivityReport[];
      this.technologyReport = [] as TechnologyReport[];
      this.epicReport = [] as EpicReport[];
      this.projectReport = [] as ProjectReport[];
      this.loading = true;
      this.tableLoading = true;
      this.weeklyReport = [];
      this.teammemberReport = [];
      this.projectLeadReport = await ProjectLeadReportService.getList(
        this.startDate,
        this.endDate
      );

      this.teammemberVMReports = this.projectLeadReport.teammemberVMReport;
      await this.setCommitListByProjectLead();
      this.activityReport = this.projectLeadReport.activityReport;
      this.technologyReport = this.projectLeadReport.technologyReport;
      this.epicReport = this.projectLeadReport.epicReport;
      this.projectReport = this.projectLeadReport.projectReport;
      this.dataFilled = true;

      this.timesheetReport = this.projectLeadReport.timesheetReport;
      this.filteredTimesheetReport = this.projectLeadReport.timesheetReport;
      this.selectedActivity = "";
      this.selectedEpic = "";
      this.selectedTechnology = "";
      if (
        this.timesheetReport &&
        this.timesheetReport.length > 0 &&
        this.allTeammembers &&
        this.allTeammembers.length > 0
      ) {
        // filter team members that logged timesheet
        const uniqueTeammemberIds = [
          ...new Set(this.timesheetReport.map((item) => item.TeamMemberId)),
        ];
        this.teammembers = this.allTeammembers.filter((x) =>
          uniqueTeammemberIds.includes(x.id)
        );
      } else {
        this.teammembers = [];
      }

      // set teammembers as clicked
      this.clearSelectedTeammemberAndFillAll(selectedTeammemberId);

      this.loading = false;
      this.tableLoading = false;
    },
    calculateActivities() {
      let activities = this.filteredTimesheetReport.map((c) => {
        return {
          id: c.ActivityId,
          name: c.Activity,
          netEffort: c.Effort + c.Adjustment,
        } as ActivityReport;
      });
      this.activityReport = activities.reduce((ac: ActivityReport[], a) => {
        let ind = ac.findIndex((x) => x.id === a.id);
        ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
        return ac;
      }, []);

      this.renderActivityReportChart(this.activityReport);
    },
    calculateTechnologies() {
      let technologies = this.filteredTimesheetReport.map((c) => {
        return {
          id: c.TechnologyId,
          name: c.Technology,
          netEffort: c.Effort + c.Adjustment,
        } as TechnologyReport;
      });
      this.technologyReport = technologies.reduce(
        (ac: TechnologyReport[], a) => {
          let ind = ac.findIndex((x) => x.id === a.id);
          ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
          return ac;
        },
        []
      );

      this.renderTechnologyReportChart(this.technologyReport);
    },
    calculateProjects() {
      let projects = this.filteredTimesheetReport.map((c) => {
        return {
          id: c.ProjectId,
          name: c.Project?.Name,
          netEffort: c.Effort + c.Adjustment,
        } as ProjectReport;
      });
      this.projectReport = projects.reduce((ac: ProjectReport[], a) => {
        let ind = ac.findIndex((x) => x.id === a.id);
        ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
        return ac;
      }, []);

      this.renderProjectReportChart(this.projectReport);
    },
    calculateEpics() {
      let epics = this.filteredTimesheetReport.map((c) => {
        return {
          name: c.Epic,
          netEffort: c.Effort + c.Adjustment,
        } as EpicReport;
      });
      this.epicReport = epics.reduce((ac: EpicReport[], a) => {
        let ind = ac.findIndex((x) => x.name === a.name);
        ind === -1 ? ac.push(a) : (ac[ind].netEffort += a.netEffort);
        return ac;
      }, []);

      this.renderEpicReportChart(this.epicReport);
    },
    filterReport() {
      this.message = "";
      this.grandTotalAdjustment = 0;
      this.grandTotalHour = 0;
      this.grandTotalBillable = 0;
      this.teammemberReport = [] as TeammemberReport[];
      if (this.timesheetReport && this.timesheetReport.length > 0) {
        if (this.clickedTeammembers && this.clickedTeammembers.length) {
          this.filteredTimesheetReport = this.timesheetReport.filter(
            (c) =>
              c.TeamMemberId && this.clickedTeammembers.includes(c.TeamMemberId)
          );
        } else {
          this.filteredTimesheetReport = this.timesheetReport;
        }

        if (this.selectedActivity) {
          this.filteredTimesheetReport = this.filteredTimesheetReport.filter(
            (x) => x.ActivityId == this.selectedActivity
          );
        }

        if (this.selectedEpic) {
          this.filteredTimesheetReport = this.filteredTimesheetReport.filter(
            (x) => x.Epic == this.selectedEpic
          );
        }

        if (this.selectedTechnology) {
          this.filteredTimesheetReport = this.filteredTimesheetReport.filter(
            (x) => x.TechnologyId == this.selectedTechnology
          );
        }

        if (this.selectedProject) {
          this.filteredTimesheetReport = this.filteredTimesheetReport.filter(
            (x) => x.ProjectId == this.selectedProject
          );
        }

        const selectedTimesheetTeammembers = this.filteredTimesheetReport.map(
          (tr) => tr.TeamMemberId
        );
        const uniqueTeammemberIds = [...new Set(selectedTimesheetTeammembers)];
        const datesArray = this.getDatesBetweenDates();
        datesArray.forEach((da) => {
          const weekDates = this.getStartandEndOfWeek();
          const exist = this.weeklyReport.some((wr) => wr.week === weekDates);
          if (!exist) {
            this.weeklyReport.push({ week: weekDates, timesheets: [] });
          }
        });

        // remove team members without timesheet on filter
        if (this.filterItems.length) {
          this.filteredTeammembers = this.teammembers.filter((x) =>
            uniqueTeammemberIds.includes(x.id)
          );
          this.teammemberViewVersion++;
        } else {
          this.filteredTeammembers = this.teammembers;
          this.teammemberViewVersion++;
        }

        if (uniqueTeammemberIds) {
          uniqueTeammemberIds.forEach((ut) => {
            const timesheet = this.filteredTimesheetReport.filter(
              (t) => t.TeamMemberId === ut
            )[0];
            this.teammemberReport.push({
              teammemberId: ut,
              teammemberName: timesheet.TeammemberName,
              weeklyReport: this.getTeammemberTimesheetInWeek(ut),
            });
          });
          if (!this.teammemberReport || this.teammemberReport.length === 0) {
            this.message = ProjectMessages.NoTimesheet;
          }

          this.calculateActivities();
          this.calculateTechnologies();
          this.calculateEpics();
          this.calculateProjects();
        }
      } else {
        this.message = ProjectMessages.NoTimesheet;
      }
      this.grandTotalBillable = this.removeDecimals(
        this.grandTotalHour + this.grandTotalAdjustment
      );
    },
    selectUser(clickedAvatar: any) {
      if (
        clickedAvatar &&
        clickedAvatar.memberIdArrays &&
        clickedAvatar.memberIdArrays.length
      ) {
        this.teammemberSelected = true;
      } else {
        this.teammemberSelected = false;
      }
      this.clickedTeammembers = clickedAvatar.memberIdArrays;
      this.teammemberViewVersion++;
    },

    setDate(startDate: Date, endDate: Date) {
      this.startDate = startDate;
      this.endDate = endDate;
    },
    async fetchData(dateModel: { startDate: Date; endDate: Date }) {
      this.setDate(dateModel.startDate, dateModel.endDate);
      console.log("fetchTimesheetReport", this.clickedTeamMember?.id);
      await this.fetchTimesheetReport(this.clickedTeamMember?.id);
    },
  },
  computed: {
    clickedTeamMember(): UiTeammember | null {
      if (
        this.clickedTeammembers &&
        this.clickedTeammembers.length == 1 &&
        this.teammemberSelected
      ) {
        return this.getTeammemberNameById(this.clickedTeammembers[0]);
      }
      return null;
    },
    filterItems(): FilterItem[] {
      const filterItems = [] as FilterItem[];

      // check selected team member
      if (this.clickedTeamMember) {
        filterItems.push({
          title: `Team Member: ${this.clickedTeamMember.fullName}`,
          removeFunction: this.clearSelectedTeammemberAndFillAll,
        } as FilterItem);
      }

      // check selected epic
      if (this.selectedEpic) {
        filterItems.push({
          title: `Epic: ${this.selectedEpic}`,
          removeFunction: this.clearSelectedEpic,
        } as FilterItem);
      }
      // check selected technology
      if (this.selectedTechnology) {
        filterItems.push({
          title: `Technology: ${this.getTechnologyNameById(
            this.selectedTechnology
          )}`,
          removeFunction: this.clearSelectedTechnology,
        } as FilterItem);
      }
      // check selected project
      if (this.selectedProject) {
        filterItems.push({
          title: `Project: ${this.getProjectNameById(this.selectedProject)}`,
          removeFunction: this.clearSelectedProject,
        } as FilterItem);
      }

      // check selected activity
      if (this.selectedActivity) {
        filterItems.push({
          title: `Activity: ${this.getActivityNameById(this.selectedActivity)}`,
          removeFunction: this.clearSelectedActivity,
        } as FilterItem);
      }
      return filterItems;
    },
  },
});
</script>
<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";
.timesheetreport-body {
  overflow-x: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
.timesheetreport-body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.timesheetreport-item {
  min-width: 973px;
}

.timesheet-item {
  background-color: #fff;
  margin-bottom: 0.1px;
}
.avatar {
  height: 60px;
}
.transparent {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}
.emptyResult h1 {
  text-align: center;
  line-height: $size-l;
}
.text-flex-container {
  display: flex;
  align-items: center;
}

.text-flex-container .content {
  flex: 1;
}
.desc {
  background-color: $pink;
}
.totalcalc {
  color: $primary;
}
.box-header {
  background-color: $pit_crew_uniform;
  color: $primary;
}
.datas {
  border: 1px solid $primary;
  color: $text_color;
}
.header {
  background-color: $pit_crew_uniform;
  border: 1px solid $pit_crew_uniform;
  color: $primary;
}
.titles {
  background-color: $racecar;
  border: 1px solid $primary;
  color: $primary;
  font-size: $size-xs;
}
.hours {
  border: 3px solid $text_color;
}
.month {
  border: 3px solid $pit_crew_uniform;
}
.dataBody {
  background-color: $dark_gray;
  height: 100%;
  min-height: 400px;
}

.dataBody.auto-height {
  min-height: auto;
}

.teammember-section {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.teammember-section-item {
  display: inline-block;
  width: 100%;
}

.teammember-section .not-selected {
  opacity: 0.3;
}

.teammember-section .tm-extra {
  margin-bottom: -25px;
  margin-top: -30px;
}

.teammember-section .profile-avatar {
  top: 5px !important;
  position: relative;
}
>>> .teammember-section .tm-extra .v-selection-control__wrapper {
  top: 3px;
}

.teammember-section .tm-extra i {
  left: -5px;
  cursor: pointer;
  bottom: 20px;
}

.clearChartFilter {
  cursor: pointer;
  color: #ad6262;
}

.filters {
  line-height: 4.3rem;
  padding-left: 30px;
}

.filter-item {
  display: inline-block;
  background-color: #151414;
  margin: 0px 0.5px;
  padding: 0px 10px;
}
@media (max-width: 960px) {
  .projItem {
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .week-title {
    font-size: $size-s;
  }
}
@media (max-width: 368px) {
  .box-header {
    font-size: $size-xs;
  }
}
</style>
