import ApiService from "./ApiService";
import CourseModel from "shared-components/src/models/CourseModel";
import FindCoursesRequest from "shared-components/src/models/requests/FindCoursesRequest"

export default class CourseService {
    public static async CreateOrEdit( model: CourseModel): Promise<CourseModel> {
        return new Promise((resolve, reject) => {
            ApiService.post(`/course`,model).then((result) => {
                if (!result.data) {
                    resolve({} as CourseModel);
                } else {
                    var response = result.data as CourseModel;
                    resolve(response);
                }
            }).catch((error) => reject(error));
        });
    }

    public static async GetCoursesName(): Promise<CourseModel[]> {
        return new Promise((resolve, reject) => {
            ApiService.get(`/course/get/coursesName`).then((result) => {
                if (!result.data) {
                    resolve([] as CourseModel[]);
                } else {
                    var getCourses = result.data as CourseModel[];
                    resolve(getCourses);
                }
            }).catch((error) => reject(error));
        });
    }

    public static async GetList(criteria: FindCoursesRequest | null = null): Promise<CourseModel[]> {
        return new Promise((resolve, reject) => {
            ApiService.post(`/course/find`, criteria ? criteria : {} as FindCoursesRequest).then((result) => {
                if (!result.data) {
                    resolve([] as CourseModel[]);
                } else {
                    var getCourses = result.data as CourseModel[];
                    resolve(getCourses);
                }
            }).catch((error) => reject(error));
        });
    }

    public static async GetById(courseId:string): Promise<CourseModel> {
        return new Promise((resolve, reject) => {
            ApiService.get(`/course/${courseId}`).then((result) => {
                if (!result.data) {
                    resolve({} as CourseModel);
                } else {
                    var getCourses = result.data as CourseModel;
                    resolve(getCourses);
                }
            }).catch((error) => reject(error));
        });
    }
}

