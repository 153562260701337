import Project from "shared-components/src/models/Project";
import ApiService from "./ApiService";
import ProjectLead from "shared-components/src/models/ProjectLead";
import { PublicHoliday } from "shared-components/src/models/PublicHoliday";

export default class ProjectService {
  public static async getList(): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/client/project`, "")
        .then((projectResult) => {
          if (!projectResult.data) {
            resolve([]);
          } else {
            const retVal = projectResult.data.map((d: any) => {
              const item = { id: d.id, ...d } as Project;
              return item;
            });
            resolve(retVal);
          }
        })
        .catch((projectError) => {
          reject(projectError);
        });
    });
  }

  public static async getProjectLeadList(): Promise<ProjectLead[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/client/projectLeads`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              const retVal = { ...item } as ProjectLead;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((projectError) => {
          reject(projectError);
        });
    });
  }

  public static async getPublicHolidayZoneList(): Promise<PublicHoliday[]> {
    return new Promise((resolve, reject) => {
      ApiService.get("/client/publicholidays", "")
        .then((publicHolidayResult) => {
          if (!publicHolidayResult.data) {
            resolve([]);
          } else {
            const retVal = publicHolidayResult.data.map((d: any) => {
              const item = { Id: d.id, ...d } as PublicHoliday;
              return item;
            });
            resolve(retVal);
          }
        })
        .catch((publicHolidayError) => reject(publicHolidayError));
    });
  }

  public static async Create(model: Project): Promise<Project> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/client/project/CreateProject`, model)
        .then((projectResult) => {
          resolve(projectResult.data as Project);
        })
        .catch((projectError) => reject(projectError));
    });
  }
}
